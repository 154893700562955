/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Modal,
  TextInput,
  Keyboard,
  ScrollView,
} from 'react-native';

import {colors} from '../classes/Colors';
import DataController from '../classes/DataController';
import LayoutController from '../classes/LayoutController';

import ScreenComponent from '../components/ScreenComponent';
import VideoHeroCard from '../components/VideoHeroCard';
import VideosRail from '../components/VideosRail';
import VideoGridCard from '../components/VideoGridCard';

import MaxWidthContainer from '../components/MaxWidthContainer';
import ResponsiveGrid from '../classes/ResponsiveGrid';
import {containerWidths} from '../constants/containerWidths';

const checkBoxActive = require('../assets/Button_Checkbox_Active.png');
const checkBoxInactive = require('../assets/Button_Checkbox_Inactive.png');

const SectionHeading = ({children}) => {
  return <Text style={styles.sectionHeading}>{children}</Text>;
};

const PaddingSection = ({children}) => {
  return <View style={styles.paddingSection}>{children}</View>;
};

export default class VideoListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false,
      filters: [], // 'seen', 'not seen'
      items: [],
      mostRecentVideo: null,
      searchText: '',
      showSearchInput: false,
    };
  }

  async componentDidMount() {
    this.props.navigation.setParams({
      filterButtonTapped: this.filterButtonTapped,
      searchButtonTapped: this.searchButtonTapped,
    });

    let filters = await DataController.getVideoFilters();
    if (!filters.length) filters = ['seen', 'not seen'];
    this.setState({filters: filters}, () => {
      this.filterItems();
    });
  }

  static navigationOptions = ({navigation}) => {
    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('filterButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Filter.png')}
          />
        </TouchableOpacity>
        <TouchableOpacity onPress={navigation.getParam('searchButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Search.png')}
          />
        </TouchableOpacity>
      </View>
    );

    return {
      title: navigation.getParam('title', ''),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };
  };

  searchButtonTapped = () => {
    this.setState(prev => ({showSearchInput: !prev.showSearchInput}));
  };

  filterButtonTapped = () => {
    Keyboard.dismiss();
    this.setState({showFilterModal: true});
  };

  handleFilterOptionSelection(optionSelected) {
    let newFilters = [];
    if (this.state.filters.includes(optionSelected)) {
      newFilters = this.state.filters.filter(it => it !== optionSelected);
    } else {
      newFilters = [...this.state.filters, optionSelected];
    }

    this.setState({filters: newFilters}, () => {
      this.filterItems();
      DataController.saveVideoFilters(this.state.filters);
    });
  }

  filterItems() {
    let newItems = [];
    const sortedVideos = this.sortByReleaseDate(this.props.screenProps.videos);
    const mostRecentVideo = sortedVideos.slice(0, 1)?.[0];
    if (mostRecentVideo) {
      this.setState({mostRecentVideo: mostRecentVideo});
    }

    // the rest of the videos
    // const allItems = sortedVideos.slice(1);
    const allItems = [...sortedVideos];
    const videoHistory = [...this.props.screenProps.videoHistory];

    // apply filters
    if (
      this.state.filters.includes('seen') &&
      this.state.filters.includes('not seen')
    ) {
      newItems = [...allItems];
    } else if (this.state.filters.includes('seen')) {
      newItems = allItems.filter(it => {
        const found = videoHistory.find(vid => vid.videoId === it.id);
        return found && found.secondsWatched >= 30;
      });
    } else if (this.state.filters.includes('not seen')) {
      newItems = allItems.filter(it => {
        const found = videoHistory.find(vid => vid.videoId === it.id);
        return !found || found.secondsWatched < 30;
      });
    } else {
      // filters are empty, show nothing
      this.setState({items: newItems});
    }

    // apply search
    const trimmedText = this.state.searchText.toLocaleLowerCase().trim();
    if (trimmedText === '') this.setState({items: newItems});
    newItems = newItems.filter(it =>
      it.Title.toLowerCase().includes(trimmedText),
    );
    this.setState({items: newItems});
  }

  videoPressHandler = video => {
    this.props.navigation.navigate(
      'Video',
      {
        video,
        from: 'Video List',
      },
      this.props.screenProps,
    );
  };

  sortByReleaseDate = arr =>
    arr
      .slice()
      .sort((a, b) => new Date(b.ReleaseDate) - new Date(a.ReleaseDate));

  findVideosWithHistory = () => {
    return (
      this.state.items.filter(video =>
        this.props.screenProps.videoHistory.some(
          history => video?.id === history.videoId,
        ),
      ) || []
    );
  };

  render() {
    const hasSearchValueOrFilter =
      this.state.searchText.trim() !== '' || this.state.filters.length === 1;
    const isTablet = LayoutController.isTablet();
    const videosWithHistory = this.findVideosWithHistory();
    const sortedVideos = this.sortByReleaseDate(this.state.items);
    const [firstVideo, ...restVideos] = sortedVideos;
    const firstVideoHistory = this.props.screenProps.videoHistory.find(
      vid => vid.videoId === firstVideo?.id,
    );
    const videoGrid = hasSearchValueOrFilter ? sortedVideos : restVideos;
    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView
          styles={{flex: 1}}
          contentContainerStyle={styles.scrollContainer}>
          {this.state.showSearchInput && (
            <View style={styles.searchInputContainer}>
              <MaxWidthContainer>
                <TextInput
                  autoFocus
                  onSubmitEditing={() => this.filterItems()}
                  value={this.state.searchText}
                  style={styles.searchInput}
                  underlineColorAndroid="transparent"
                  placeholder={'Search'}
                  placeholderTextColor={'#BBB'}
                  autoCapitalize={'none'}
                  autoCorrect={false}
                  inputMode="search"
                  returnKeyType={'search'}
                  onChangeText={text =>
                    this.setState({searchText: text}, () => {
                      this.filterItems();
                    })
                  }
                />
              </MaxWidthContainer>
            </View>
          )}
          <MaxWidthContainer fullWidth={!isTablet}>
            {firstVideo && !hasSearchValueOrFilter && (
              <View
                style={[styles.heroCardContainer, isTablet && {marginTop: 16}]}>
                <VideoHeroCard
                  video={firstVideo}
                  videoHistory={firstVideoHistory}
                  onPress={() => this.videoPressHandler(firstVideo)}
                  isTextPadded={!isTablet}
                  isSeen={firstVideoHistory?.secondsWatched >= 30}
                />
              </View>
            )}
          </MaxWidthContainer>
          <MaxWidthContainer>
            {videosWithHistory.length > 0 && (
              <PaddingSection>
                <VideosRail
                  title={'Continue Watching'}
                  videos={videosWithHistory}
                  videoOnPress={this.videoPressHandler}
                  videoHistory={this.props.screenProps.videoHistory}
                />
              </PaddingSection>
            )}
            {videoGrid.length > 0 && (
              <PaddingSection>
                <SectionHeading>Videos</SectionHeading>
                <ResponsiveGrid
                  maxWidthPercentage={94}
                  items={videoGrid.map((video, index, array) => {
                    const image = video?.Screenshot
                      ? `https://ppp-temp-files.s3.amazonaws.com/VideoPodcasts/${video.Screenshot[0].filename}`
                      : '';
                    const isLastVideo = index === array.length;
                    const historyForVideo =
                      this.props.screenProps.videoHistory.find(
                        vid => vid.videoId === video?.id,
                      );
                    return (
                      <View
                        key={video.id + index}
                        style={[
                          styles.videoGridCardContainer,
                          isLastVideo && {
                            marginBottom: 0,
                          },
                        ]}>
                        <VideoGridCard
                          image={image}
                          onPress={() => this.videoPressHandler(video)}
                          title={video.Title}
                          trust={video.Trust}
                          date={video.ReleaseDate}
                          isSeen={historyForVideo?.secondsWatched >= 30}
                        />
                      </View>
                    );
                  })}
                />
              </PaddingSection>
            )}
            <Modal
              visible={this.state.showFilterModal}
              onRequestClose={() => this.setState({showFilterModal: false})}
              transparent={true}
              animationType="fade">
              <View
                style={{
                  flex: 1,
                  backgroundColor: 'rgba(52, 52, 52, 0.4)',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <View
                  style={{width: LayoutController.isCompact() ? '75%' : 350}}>
                  <View style={styles.modalHeader}>
                    <Text style={styles.modalHeaderText}>Filter</Text>
                    <TouchableOpacity
                      style={styles.modalDoneBtn}
                      onPress={() => this.setState({showFilterModal: false})}>
                      <Text style={styles.modalHeaderText}>Done</Text>
                    </TouchableOpacity>
                  </View>
                  <View style={styles.modalOptionsContainer}>
                    <TouchableOpacity
                      style={styles.modalRowBtn}
                      onPress={() => this.handleFilterOptionSelection('seen')}>
                      <Text style={styles.optionText}>Seen</Text>
                      <Image
                        style={{width: 26, height: 26}}
                        source={
                          this.state.filters.includes('seen')
                            ? checkBoxActive
                            : checkBoxInactive
                        }
                      />
                    </TouchableOpacity>
                    <View style={styles.divider} />
                    <TouchableOpacity
                      style={styles.modalRowBtn}
                      onPress={() =>
                        this.handleFilterOptionSelection('not seen')
                      }>
                      <Text style={styles.optionText}>Not Seen</Text>
                      <Image
                        style={{width: 26, height: 26}}
                        source={
                          this.state.filters.includes('not seen')
                            ? checkBoxActive
                            : checkBoxInactive
                        }
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>
          </MaxWidthContainer>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  scrollContainer: {
    width: '100%',
    alignSelf: 'stretch',
    marginHorizontal: 'auto',
  },
  heroCardContainer: {
    alignSelf: 'center',
    width: '100%',
    marginBottom: 20,
    maxWidth: containerWidths.videoHeroContainer,
  },
  cardContainer: {
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  vidInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginTop: 4,
    fontSize: 16,
    fontWeight: '600',
    color: colors.Grey900,
  },
  description: {
    marginTop: 4,
    fontSize: 14,
    color: colors.Grey600,
  },
  searchInputContainer: {
    backgroundColor: colors.NavBar,
    marginBottom: 10,
  },
  searchInput: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    backgroundColor: colors.SearchBar,
    borderRadius: 5,
    color: colors.white,
  },
  divider: {
    height: 1,
    backgroundColor: colors.Grey100,
  },
  modalRowBtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
  },
  modalOptionsContainer: {
    paddingHorizontal: 12,
    backgroundColor: 'white',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  modalHeader: {
    backgroundColor: colors.NavBar,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    justifyContent: 'center',
    padding: 10,
  },
  modalHeaderText: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
  },
  optionText: {
    color: colors.Grey900,
    fontWeight: 'bold',
  },
  modalDoneBtn: {
    right: 12,
    position: 'absolute',
    padding: 10,
  },
  sectionHeading: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20,
    marginBottom: 16,
    color: colors.Grey900,
  },
  videoContainer: {
    backgroundColor: colors.white,
  },
  videoTabletContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  videoGridCardContainer: {
    width: '100%',
    marginBottom: 16,
  },
  paddingSection: {
    paddingVertical: 16,
  },
});
