import {Platform} from 'react-native';

import {isDateInRange} from '../utils/dates';
import DataController from './DataController';

const PROMOTIONS_FILE = 'pppPromotions.json';
const PROMOTION_CODE_FILE = 'pppPromoCode.json';
const PROMOTION_IMAGE_FOLDER = 'promotions';

export default {
  getPromotionForUser: (userTrusts, promotions) => {
    return promotions?.find(promotion => userTrusts.includes(promotion.trust));
  },

  downloadPromotionImage: async promotion => {
    if (!promotion) {
      return null;
    }

    if (Platform.OS === 'web') {
      return promotion;
    }

    const [bannerImage, resultsImageLandscape, resultsImagePortrait] =
      await Promise.all([
        DataController.downloadImageHandler(
          promotion.bannerImage?.filename,
          promotion.bannerImage?.url,
          promotion.bannerImage?.size,
          PROMOTION_IMAGE_FOLDER,
        ),
        DataController.downloadImageHandler(
          promotion.resultsImageLandscape?.filename,
          promotion.resultsImageLandscape?.url,
          promotion.resultsImageLandscape?.size,
          PROMOTION_IMAGE_FOLDER,
        ),
        DataController.downloadImageHandler(
          promotion.resultsImagePortrait?.filename,
          promotion.resultsImagePortrait?.url,
          promotion.resultsImagePortrait?.size,
          PROMOTION_IMAGE_FOLDER,
        ),
      ]);

    return {
      ...promotion,
      bannerImage: {...promotion.bannerImage, url: bannerImage},
      resultsImageLandscape: {
        ...promotion.resultsImageLandscape,
        url: resultsImageLandscape,
      },
      resultsImagePortrait: {
        ...promotion.resultsImagePortrait,
        url: resultsImagePortrait,
      },
    };
  },

  async getPromotionsFromFile() {
    try {
      const promotions = await DataController.getDataFromFile(PROMOTIONS_FILE);
      return promotions ?? [];
    } catch (error) {
      console.log('Error getting promotions:', error);
      return [];
    }
  },

  async deletePromotionsFile() {
    try {
      await DataController.deleteFile(PROMOTIONS_FILE);
    } catch (error) {
      console.log('Error deleting promotions file:', error);
    }
  },

  findIfPromotionAvailable: (promotion, user, promoCode) => {
    if (!promotion || !user) {
      return false;
    }

    const {status, finalDate, endDate, startDate} = promotion;
    const now = new Date();

    if (status === 'Disabled') {
      return false;
    }

    const {isPPPTester} = user;

    if (status === 'Testing') {
      if (isPPPTester) {
        return true;
      }

      return false;
    }

    if (status === 'Live') {
      const userHasCode = Boolean(promoCode?.promoCode);

      // If the promotion is before the startDate the promotion is not available
      if (now < new Date(startDate)) return false;
      // If the promotion is after the finalDate the promotion is not available
      if (now > new Date(finalDate)) return false;
      // If the user has already applied a promo code, the promotion is available
      if (userHasCode) return true;
      // Otherwise check if the promotion is currently active based on StartDate and EndDate
      return isDateInRange(startDate, endDate, now);
    }

    return false;
  },

  getPromotions: async function (userTrusts, promotions) {
    try {
      if (!promotions?.length) {
        return null;
      }

      const promotionForUser = this.getPromotionForUser(userTrusts, promotions);
      return this.downloadPromotionImage(promotionForUser);
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async deletePromoCodeFile() {
    try {
      await DataController.deleteFile(PROMOTION_CODE_FILE);
    } catch (error) {
      console.log(error);
    }
  },

  async getPromoCodeFromFile() {
    try {
      const promotionCode = await DataController.getDataFromFile(
        PROMOTION_CODE_FILE,
      );
      return promotionCode ?? null;
    } catch (error) {
      console.log(error);
    }
  },
};
