/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  ScrollView,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import QuizCell from '../components/QuizCell';
import ProgressView from '../components/ProgressView';
import DropDown from '../components/DropDown';
import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import {firebase} from '../classes/Firebase';
import MaxWidthContainer from '../components/MaxWidthContainer';
import {containerWidths} from '../constants/containerWidths';

var _ = require('lodash');

type Props = {};

type State = {
  quizSets: [],
  type: string,
  sortOptions: [],
  filterOptions: [],
  selectedSortOption: string,
  selectedFilterOption: string,
};

export default class QuizSetListScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: navigation.getParam('title', 'Quizzes'),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      var type = this.props.navigation.getParam('type', '');

      var sortOptions = [];

      if (type === 'topics') {
        sortOptions.push({
          value: 'Date - Newest',
          label: 'Date - Newest First',
        });
        sortOptions.push({
          value: 'Date - Oldest',
          label: 'Date - Oldest First',
        });
      }

      sortOptions.push({value: 'A-Z', label: 'A-Z'});
      sortOptions.push({value: 'Z-A', label: 'Z-A'});

      if (type === 'guidelines') {
        sortOptions.push({value: 'Section', label: 'Section'});
      }

      var filterOptions = [];

      if (type === 'topics') {
        filterOptions = [
          {value: 'All Quizzes', label: 'All Quizzes'},
          {value: 'JRCALC Quizzes', label: 'JRCALC Quizzes'},
          {value: 'Trust Quizzes', label: 'Trust Quizzes'},
          {value: 'Special Topics', label: 'Special Topics'},
          {value: 'PGD Quizzes', label: 'PGD Quizzes'},
          {value: 'PMA Quizzes', label: 'PMA Quizzes'},
        ];
      }

      var selectedFilterOption = {};

      if (filterOptions.length > 0) {
        selectedFilterOption = filterOptions[0];

        var initialFilter = this.props.navigation.getParam(
          'initialFilter',
          null,
        );

        if (initialFilter != null) {
          var selectedFilter = filterOptions.find(
            x => x.value === initialFilter,
          );
          if (selectedFilter != null) {
            selectedFilterOption = selectedFilter;
          }
        }
      }

      this.state = {
        quizSets: this.props.navigation.getParam('quizSets', []),
        type: type,
        sortOptions: sortOptions,
        selectedSortOption: sortOptions[0],
        filterOptions: filterOptions,
        selectedFilterOption: selectedFilterOption,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      this.sortOptionChanged(this.state.selectedSortOption);
      this.filterOptionChanged(this.state.selectedFilterOption);
    }
  }

  componentDidUpdate(prevProps) {
    // replace state quizSets with those from screenProps if updated.
    if (
      JSON.stringify(this.props.screenProps.quizSets) !==
      JSON.stringify(prevProps.screenProps.quizSets)
    ) {
      let stateQuizSets = this.state.quizSets.slice();
      for (
        let stateQuizSetIndex = 0;
        stateQuizSetIndex < stateQuizSets.length;
        stateQuizSetIndex++
      ) {
        let stateQuizSet = stateQuizSets[stateQuizSetIndex];

        for (
          let propQuizSetIndex = 0;
          propQuizSetIndex < this.props.screenProps.quizSets.length;
          propQuizSetIndex++
        ) {
          let propQuizSet = this.props.screenProps.quizSets[propQuizSetIndex];

          if (stateQuizSet.name === propQuizSet.name) {
            stateQuizSets[stateQuizSetIndex] = propQuizSet;
            break;
          }
        }
      }

      this.setState({quizSets: stateQuizSets});
    }
  }

  sortOptionChanged(selectedOption) {
    var quizSets = this.state.quizSets.slice();

    if (selectedOption.value === 'Date - Newest') {
      quizSets = _.orderBy(this.state.quizSets, ['date'], ['desc']);
    } else if (selectedOption.value === 'Date - Oldest') {
      quizSets = _.orderBy(this.state.quizSets, ['date'], ['asc']);
    } else if (selectedOption.value === 'A-Z') {
      quizSets = _.orderBy(this.state.quizSets, ['title'], ['asc']);
    } else if (selectedOption.value === 'Z-A') {
      quizSets = _.orderBy(this.state.quizSets, ['title'], ['desc']);
    } else if (selectedOption.value === 'Section') {
      quizSets = _.orderBy(this.state.quizSets, ['section'], ['asc']);
    }

    this.setState({quizSets: quizSets, selectedSortOption: selectedOption});

    if (this._scrollView != null && this._scrollView.scrollTo) {
      this._scrollView.scrollTo({x: 0, y: 0, animated: false});
    }

    try {
      firebase.analytics().logEvent('sort_order_changed', {
        page: 'quizList',
        value: selectedOption.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  filterOptionChanged(selectedOption) {
    var originalQuizSets = this.props.navigation.getParam('quizSets', []);
    var filteredQuizSets = this.state.quizSets;

    if (this.state.type === 'topics') {
      filteredQuizSets = [];

      for (
        var quizSetIndex = 0;
        quizSetIndex < originalQuizSets.length;
        quizSetIndex++
      ) {
        var quizSet = originalQuizSets[quizSetIndex];

        for (
          var topicIndex = 0;
          topicIndex < this.props.screenProps.topics.length;
          topicIndex++
        ) {
          var topic = this.props.screenProps.topics[topicIndex];

          if (quizSet.topicName === topic.PackName) {
            if (selectedOption.value === 'All Quizzes') {
              filteredQuizSets.push(quizSet);
            } else if (
              topic.Type === 'General' &&
              selectedOption.value === 'JRCALC Quizzes'
            ) {
              filteredQuizSets.push(quizSet);
            } else if (
              topic.Type === 'Special' &&
              selectedOption.value === 'Special Topics'
            ) {
              filteredQuizSets.push(quizSet);
            } else if (
              quizSet.pgd === true &&
              quizSet.pma !== true &&
              selectedOption.value === 'PGD Quizzes'
            ) {
              filteredQuizSets.push(quizSet);
            } else if (
              quizSet.pma === true &&
              selectedOption.value === 'PMA Quizzes'
            ) {
              filteredQuizSets.push(quizSet);
            } else if (
              topic.Type === 'Topic' &&
              topic.Trust != null &&
              selectedOption.value === 'Trust Quizzes'
            ) {
              filteredQuizSets.push(quizSet);
            }

            break;
          }
        }
      }
    }

    this.setState(
      {selectedFilterOption: selectedOption, quizSets: filteredQuizSets},
      function () {
        this.sortOptionChanged(this.state.selectedSortOption);
      },
    );

    try {
      firebase.analytics().logEvent('filter_changed', {
        page: 'quizList',
        value: selectedOption.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  keyExtractor(item, index) {
    return item.name;
  }

  renderQuizSet({item}) {
    var quizSet = item;

    var inProgress = false;
    if (
      this.props.screenProps.inProgressQuizzes.find(
        aQuizSet => aQuizSet.quizSet === quizSet.name,
      )
    ) {
      inProgress = true;
    }

    var results = this.props.screenProps.latestResultsForQuizSets[quizSet.name];

    return (
      <TouchableOpacity
        onPress={this.goToQuizOverview.bind(this, quizSet)}
        style={{flex: 1}}>
        <QuizCell
          key={quizSet.name}
          quizSet={quizSet}
          results={results}
          inProgress={inProgress}
          fontSize={this.props.screenProps.fontSize}
          user={this.props.screenProps.user}
        />
      </TouchableOpacity>
    );
  }

  goToQuizOverview(quizSet: Object) {
    if (quizSet.type === 'standby') {
      var selectedIssue = null;

      for (
        var issueIndex = 0;
        issueIndex < this.props.screenProps.issues.length;
        issueIndex++
      ) {
        var issue = this.props.screenProps.issues[issueIndex];

        if (issue.Name === quizSet.issueName) {
          selectedIssue = issue;
        }
      }

      this.props.navigation.navigate(
        'StandbyVolume',
        {
          quizSet: quizSet,
          issue: selectedIssue,
        },
        this.state,
      );
    } else if (quizSet.type === 'caseStudy') {
      var selectedCaseStudy = {};

      for (
        var caseStudyIndex = 0;
        caseStudyIndex < this.props.screenProps.caseStudies.length;
        caseStudyIndex++
      ) {
        var caseStudy = this.props.screenProps.caseStudies[caseStudyIndex];

        if (caseStudy.Name === quizSet.caseStudy) {
          selectedCaseStudy = caseStudy;
        }
      }

      this.props.navigation.navigate(
        'CaseStudyOverview',
        {
          quizSet: quizSet,
          caseStudy: selectedCaseStudy,
        },
        this.state,
      );
    } else {
      this.props.navigation.navigate(
        'QuizOverview',
        {
          quizSet: quizSet,
          from: 'Quiz List',
        },
        this.state,
      );
    }
  }

  render() {
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var questionCount = 0;

    for (
      var quizSetIndex = 0;
      quizSetIndex < this.state.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.state.quizSets[quizSetIndex];

      var results =
        this.props.screenProps.latestResultsForQuizSets[quizSet.name];

      questionCount += quizSet.questions.length;

      if (results != null) {
        for (
          var resultIndex = 0;
          resultIndex < results?.length;
          resultIndex++
        ) {
          var result = results[resultIndex];

          if (result.correct) {
            correctCount++;
          } else {
            incorrectCount++;
          }
        }
      }

      unansweredCount = questionCount - correctCount - incorrectCount;
    }

    var correctPercentage = (correctCount / questionCount) * 100;
    var incorrectPercentage = (incorrectCount / questionCount) * 100;
    var unansweredPercentage = (unansweredCount / questionCount) * 100;

    if (isNaN(correctPercentage)) {
      var correctPercentage = 0;
      var incorrectPercentage = 0;
      var unansweredPercentage = 0;
    }

    correctPercentage = correctPercentage.toFixed(1);
    incorrectPercentage = incorrectPercentage.toFixed(1);
    unansweredPercentage = unansweredPercentage.toFixed(1);

    var sortDropdownStyle = {};

    if (Platform.OS === 'web') {
      sortDropdownStyle = {width: 250, marginVertical: 10};
    }

    var filterDropDown = null;

    if (this.state.filterOptions.length > 0) {
      filterDropDown = (
        <DropDown
          options={this.state.filterOptions}
          selectedOption={this.state.selectedFilterOption}
          labelText={'Filter: ' + this.state.selectedFilterOption.value}
          title="Filter quizzes by"
          optionChanged={this.filterOptionChanged.bind(this)}
          style={sortDropdownStyle}
        />
      );
    }

    var emptyLayout = null;

    if (this.state.quizSets.length === 0) {
      emptyLayout = (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <DynamicText
            style={{padding: 10, fontSize: 15, color: colors.Grey700}}>
            There are no quizzes for this category. Try changing the filter to
            see more quizzes.
          </DynamicText>
        </View>
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <View
          style={{
            alignItems: 'stretch',
            paddingHorizontal: 10,
            paddingVertical: 15,
            backgroundColor: colors.white,
            borderBottomWidth: 1,
            borderBottomColor: colors.Grey300,
          }}>
          <MaxWidthContainer>
            <ProgressView
              style={{height: 4, overflow: 'hidden'}}
              correctAnswers={correctCount}
              incorrectAnswers={incorrectCount}
              unansweredQuestions={unansweredCount}
            />

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
              }}>
              <Text style={{fontSize: 12, fontWeight: '600', color: '#079502'}}>
                Correct: {correctPercentage}%{' '}
              </Text>
              <Text
                style={{fontSize: 12, fontWeight: '600', color: colors.Red500}}>
                Incorrect {incorrectPercentage}%
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '600',
                  color: colors.Grey500,
                }}>
                Unanswered {unansweredPercentage}%
              </Text>
            </View>
          </MaxWidthContainer>
        </View>
        <View style={{flex: 1, flexDirection: 'column-reverse'}}>
          {/* using column-reverse here so dropdown lists appear above scrollview */}

          <ScrollView
            style={{flex: 1}}
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center',
              width: '100%',
              paddingBottom: 20,
            }}
            ref={ref => (this._scrollView = ref)}>
            <MaxWidthContainer>
              {this.state.quizSets.map((quizSet, index) => (
                <View key={this.keyExtractor(quizSet, index)}>
                  {this.renderQuizSet({item: quizSet, index})}
                </View>
              ))}
            </MaxWidthContainer>
          </ScrollView>

          {emptyLayout}

          <MaxWidthContainer>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <DropDown
                options={this.state.sortOptions}
                selectedOption={this.state.selectedSortOption}
                labelText={'Sort by: ' + this.state.selectedSortOption.value}
                title="Sort Topics by"
                optionChanged={this.sortOptionChanged.bind(this)}
                style={sortDropdownStyle}
              />
              {filterDropDown}
            </View>
          </MaxWidthContainer>
        </View>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
