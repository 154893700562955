/**
 * PPP App
 * @flow
 */
'use strict';

export default {
  getAppVersion() {
    return 'Web 2.7.0 (4)';
  },

  getModel() {
    return '';
  },

  getBrand() {
    return '';
  },

  getSystemVersion() {
    return '';
  },
};
