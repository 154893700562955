/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import CaseStudyQuizCell from '../components/CaseStudyQuizCell';
import LoadingBar from '../components/LoadingBar';
import DynamicText from '../components/DynamicText';

import PlatformController from '../classes/PlatformController';
import {colors} from '../classes/Colors';
import MaxWidthContainer from '../components/MaxWidthContainer';

var _ = require('lodash');

type Props = {};

type State = {
  quizSets: [],
};

const EmptyLayout = ({screenProps}) => {
  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <DynamicText style={{padding: 10, fontSize: 15, color: colors.Grey700}}>
        Don't have a subscription to ParaPass?{' '}
        <Text
          style={{color: colors.ButtonBlue}}
          onPress={screenProps.openSubscriptionMenu.bind(this)}>
          Subscribe now
        </Text>{' '}
        for access to JRCALC CPD, Standby CPD and Case Scenarios. Contact{' '}
        <Text
          style={{color: colors.ButtonBlue}}
          onPress={() =>
            PlatformController.openURL(
              'mailto:apps@class.co.uk?subject=ParaPass%20Ambulance%20Trust%20PGD%20Query',
            )
          }>
          apps@class.co.uk
        </Text>{' '}
        for more information.
      </DynamicText>
    </View>
  );
};

export default class CaseStudiesDashboardScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('menuButtonTapped')}>
          <Image
            style={{marginLeft: 10, width: 32, height: 32}}
            source={require('../assets/Button_Menu.png')}
          />
        </TouchableOpacity>
      </View>
    );

    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('searchButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Search.png')}
          />
        </TouchableOpacity>
      </View>
    );

    let navOptions = {
      title: '',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };

    let showMenuButton = navigation.getParam('showMenuButton', false);
    if (showMenuButton) {
      navOptions.headerLeft = leftButtonsLayout;
    }

    return navOptions;
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        quizSets: [],
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.props.screenProps.quizSets != null) {
        this.updateQuizSets(this.props.screenProps.quizSets);
      }
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        menuButtonTapped: that.menuButtonTapped.bind(that),
        showMenuButton: !that.props.screenProps.showBooksTab,
        searchButtonTapped: that.searchButtonTapped.bind(that),
      });
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      _.isEqual(
        this.props.screenProps.quizSets,
        prevProps.screenProps.quizSets,
      ) === false
    ) {
      this.updateQuizSets(this.props.screenProps.quizSets);
    }
  }

  updateQuizSets(quizSets) {
    var caseStudyQuizSets = [];

    for (var quizSetIndex = 0; quizSetIndex < quizSets.length; quizSetIndex++) {
      var quizSet = quizSets[quizSetIndex];

      if (quizSet.type === 'caseStudy') {
        caseStudyQuizSets.push(quizSet);
      }
    }

    this.setState({quizSets: caseStudyQuizSets});
  }

  menuButtonTapped() {
    this.props.screenProps.showMenu(true);
  }

  keyExtractor(item, index) {
    return item.name;
  }

  renderCaseStudy = ({item}, isLatestCaseStudy = false) => {
    const quizSetForCaseStudy = this.state.quizSets.find(
      quizSet => quizSet.name === item.Name,
    );

    const results = quizSetForCaseStudy
      ? this.props.screenProps?.latestResultsForQuizSets?.[
          quizSetForCaseStudy.name
        ]
      : [];

    return (
      <TouchableOpacity
        key={item.name}
        onPress={this.goToCaseStudy.bind(this, quizSetForCaseStudy, item)}>
        <CaseStudyQuizCell
          caseStudy={item}
          isLatestCaseStudy={isLatestCaseStudy}
          quizSet={quizSetForCaseStudy}
          results={results}
          fontSize={this.props.screenProps.fontSize}
        />
      </TouchableOpacity>
    );
  };

  goToCaseStudy(quizSet: Object, caseStudy: Object) {
    this.props.navigation.navigate(
      'CaseStudyOverview',
      {
        quizSet: quizSet,
        caseStudy: caseStudy,
      },
      this.state,
    );
  }

  searchButtonTapped() {
    console.log('search');
    this.props.navigation.navigate(
      'Search',
      {
        quizSets: this.props.screenProps.quizSets,
        startingFilter: 'Case Study',
      },
      this.state,
    );
  }

  render() {
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var questionCount = 0;

    const caseStudies = this.props?.screenProps?.caseStudies;

    for (
      var quizSetIndex = 0;
      quizSetIndex < this.state.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.state.quizSets[quizSetIndex];

      var results =
        this.props.screenProps.latestResultsForQuizSets[quizSet.name];

      if (quizSet.questions != null) {
        questionCount += quizSet.questions.length;
      }

      if (results != null) {
        for (
          var resultIndex = 0;
          resultIndex < results?.length;
          resultIndex++
        ) {
          var result = results[resultIndex];

          if (result.correct) {
            correctCount++;
          } else {
            incorrectCount++;
          }
        }
      }

      unansweredCount = questionCount - correctCount - incorrectCount;
    }

    var correctPercentage = (correctCount / questionCount) * 100;
    var incorrectPercentage = (incorrectCount / questionCount) * 100;
    var unansweredPercentage = (unansweredCount / questionCount) * 100;

    if (isNaN(correctPercentage)) {
      var correctPercentage = 0;
      var incorrectPercentage = 0;
      var unansweredPercentage = 0;
    }

    correctPercentage = correctPercentage.toFixed(1);
    incorrectPercentage = incorrectPercentage.toFixed(1);
    unansweredPercentage = unansweredPercentage.toFixed(1);

    var latestQuizSetLayout = null;

    if (caseStudies.length > 1) {
      const latestCaseStudyCell = this.renderCaseStudy(
        {item: caseStudies[0]},
        true,
      );

      latestQuizSetLayout = (
        <View
          style={{
            marginBottom: 10,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 1,
            shadowColor: 'black',
            shadowOpacity: 0.2,
            backgroundColor: colors.white,
          }}>
          <View style={{backgroundColor: colors.CardHeader}}>
            <DynamicText
              style={{
                marginHorizontal: 10,
                marginVertical: 6,
                fontSize: 14,
                fontWeight: 'bold',
                fontStyle: 'normal',
                letterSpacing: -0.2,
                color: colors.white,
              }}>
              LATEST CASE STUDY
            </DynamicText>
          </View>

          {latestCaseStudyCell}
        </View>
      );
    }

    var emptyLayout = null;

    var showSelfRegistrationCard =
      this.props.screenProps.user &&
      this.props.screenProps.user.plusTrusts.includes('SWAST') &&
      !this.props.screenProps.user.paraPassTrusts.includes('SWAST');

    if (caseStudies.length === 0 && !showSelfRegistrationCard) {
      emptyLayout = <EmptyLayout screenProps={this.props.screenProps} />;
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView>
          <LoadingBar screenProps={this.props.screenProps} />

          <View
            style={{
              flexDirection: 'row',
              height: 60,
              backgroundColor: '#57B0AB',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: 42,
                height: 42,
                borderRadius: 21,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: '#F0FAF9',
              }}>
              <Image
                style={{width: 34, height: 34}}
                source={require('../assets/Results_Glyph_CaseStudy.png')}
              />
            </View>
            <Text
              style={{
                marginLeft: 13,
                fontSize: 30,
                fontWeight: 'bold',
                color: colors.white,
              }}>
              Case Studies
            </Text>
          </View>

          {showSelfRegistrationCard && <SelfRegistrationCard />}

          {emptyLayout}

          <View style={{paddingVertical: 10}}>
            <MaxWidthContainer>
              {latestQuizSetLayout}

              <View
                style={{
                  shadowOffset: {width: 0, height: 1},
                  shadowRadius: 1,
                  shadowColor: 'black',
                  shadowOpacity: 0.2,
                  backgroundColor: colors.white,
                }}>
                {caseStudies.length > 2 && (
                  <FlatList
                    data={caseStudies?.slice(1)}
                    keyExtractor={this.keyExtractor.bind(this)}
                    renderItem={this.renderCaseStudy}
                  />
                )}
              </View>
            </MaxWidthContainer>
          </View>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});

const SelfRegistrationCard = () => {
  return (
    <TouchableOpacity
      onPress={() =>
        PlatformController.openURL(
          'https://swastcpd.co.uk/events/para-pass-app/',
        )
      }
      style={{
        backgroundColor: '#dcfbec',
        borderWidth: 1,
        borderColor: '#7cd2b9',
        padding: 12,
        shadowOffset: {width: 0, height: 1},
        shadowRadius: 1,
        shadowColor: 'black',
        shadowOpacity: 0.2,
        margin: 10,
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Image
          style={{
            width: 20,
            height: '100%',
            resizeMode: 'contain',
          }}
          source={require('../assets/Icon_Lock_with_shadow.png')}
        />
        <Text
          style={{
            fontWeight: 'bold',
            marginLeft: 6,
          }}>
          Read the latest Case Studies on pre-hospital practice
        </Text>
      </View>
      <Text style={{marginTop: 6}}>
        Case studies cover a range of topics including overdose, paediatrics,
        pain management, head injury and sepsis.
      </Text>
      <Text style={{marginTop: 6}}>
        Tap here to let SWAST know you'd like to access all of the CPD resources
        ParaPass has to offer.
      </Text>
    </TouchableOpacity>
  );
};
