import React from 'react';

import {FlatList, View, StyleSheet, Text} from 'react-native';

import {colors} from '../classes/Colors';

import VideoRailCard from './VideoRailCard';

/**
 * A component for displaying a horizontal rail of VideoRailCards.
 *
 * @param {string} title - The title to display above the rail.
 * @param {Object[]} videos - The videos to display in the rail.
 * @param {Function} videoOnPress - A function that is called when a video is pressed.
 * @param {Object[]} videoHistory - The history of watched videos.
 */
const VideosRail = ({
  title,
  videos,
  videoOnPress,
  videoHistory,
  paddingStart,
}) => {
  return (
    <View style={{height: 260}}>
      {title && (
        <View style={styles.paddingSection}>
          <Text style={styles.title}>{title}</Text>
        </View>
      )}
      <FlatList
        data={videos}
        horizontal
        keyExtractor={item => item.id}
        contentContainerStyle={{paddingBottom: 16}}
        renderItem={({item, index}) => {
          const image = item?.Screenshot
            ? `https://ppp-temp-files.s3.amazonaws.com/VideoPodcasts/${item.Screenshot[0].filename}`
            : '';
          const isFirstItem = index === 0;
          const isLastItem = index === videos.length;
          const historyForVideo = videoHistory?.find(
            vid => vid.videoId === item?.id,
          );
          return (
            <View
              style={[
                {flex: 1},
                !isLastItem && {marginRight: 16},
                paddingStart && isFirstItem && {marginLeft: 16},
              ]}>
              <VideoRailCard
                image={image}
                onPress={() => videoOnPress(item)}
                title={item.Title}
                trust={item.Trust}
                date={item.ReleaseDate}
                isSeen={historyForVideo?.secondsWatched >= 30}
              />
            </View>
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20,
    marginBottom: 16,
    color: colors.Grey900,
  },
});

export default VideosRail;
