import React from 'react';

import {View, Text, StyleSheet} from 'react-native';

import DataController from '../classes/DataController';
import {colors} from '../classes/Colors';

const TrustTag = ({trust}) => {
  const name = DataController.getTrustDisplayName(trust);
  if (!name) return null;

  // Calculate tag width based on the name because flexbox is crap
  // width: 'min-content' doesn't work on ios
  const fontSize = 12;
  const nameLength = name?.length;
  const tagWidth = nameLength * fontSize;
  return (
    <View
      style={[
        styles.trustContainer,
        {width: tagWidth},
        {backgroundColor: DataController.getColorForTrust(trust)},
      ]}>
      <Text style={styles.trustText}>{name}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  trustContainer: {
    justifyContent: 'center',
    paddingHorizontal: 6,
    height: 16,
    borderRadius: 2,
  },
  trustText: {
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.1,
    color: colors.white,
    textAlign: 'center',
  },
});

export default TrustTag;
