/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  ScrollView,
  Image,
  ActivityIndicator,
  Linking,
  ImageBackground,
} from 'react-native';
import SendIntentAndroid from 'react-native-send-intent';
import moment from 'moment';

import ScreenComponent from '../components/ScreenComponent';
import QuizCell from '../components/QuizCell';
import FeaturedCard from '../components/FeaturedCard';
import LoadingBar from '../components/LoadingBar';
import ContentCard from '../components/ContentCard';
import DynamicText from '../components/DynamicText';
import DashboardNavCard from '../components/DashboardNavCard';
import BooksRail from '../components/BooksRail';
import VideosRail from '../components/VideosRail';
import MaxWidthContainer from '../components/MaxWidthContainer';
import SearchButton from '../components/SearchButton';
import PromoFeaturedCard from '../components/Promotion/PromoFeaturedCard';

import DataController from '../classes/DataController';
import LayoutController from '../classes/LayoutController';
import PlatformController from '../classes/PlatformController';
import {colors} from '../classes/Colors';
import {firebase} from '../classes/Firebase';

var _ = require('lodash');

type Props = {};

type State = {
  guidelineQuizSets: Object[],
  dashboardCardStates: Object[],
};

export default class DashboardScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('menuButtonTapped')}>
          <Image
            style={{width: 32, height: 32}}
            source={require('../assets/Button_Menu.png')}
          />
        </TouchableOpacity>
      </View>
    );

    return {
      headerTitle: (
        <Image
          style={{width: 120, height: 24}}
          resizeMode={'contain'}
          source={require('../assets/Logo_ParaPass_Short.png')}
        />
      ),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerLeft: leftButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        guidelineQuizSets: [],
        dashboardCardStates: props.screenProps.initialDashboardCardStates,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState) || true) {
      if (this.props.screenProps.quizSets != null) {
        this.updateQuizSets(this.props.screenProps.quizSets);
      }

      let dashboardCardStates = await DataController.getDashboardCardStates();
      this.setState({dashboardCardStates});
    }
    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        menuButtonTapped: that.menuButtonTapped.bind(that),
      });
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      _.isEqual(
        this.props.screenProps.quizSets,
        prevProps.screenProps.quizSets,
      ) === false
    ) {
      this.updateQuizSets(this.props.screenProps.quizSets);
    }
  }

  updateQuizSets(quizSets) {
    var guidelineQuizSets = [];

    for (var quizSetIndex = 0; quizSetIndex < quizSets.length; quizSetIndex++) {
      var quizSet = quizSets[quizSetIndex];

      if (quizSet.type === 'guideline') {
        guidelineQuizSets.push(quizSet);
      }
    }

    this.setState({guidelineQuizSets: guidelineQuizSets});
  }

  goToInProgressQuizList() {
    this.props.navigation.navigate(
      'QuizList',
      {
        quizzes: this.props.screenProps.inProgressQuizzes,
        title: 'In Progress',
        type: 'inProgress',
      },
      this.state,
    );
  }

  goToPodcastList() {
    this.props.navigation.navigate(
      'PodcastList',
      {
        title: 'Podcasts',
      },
      this.state,
    );
  }

  goToPodcast(latestPodcast) {
    this.props.navigation.navigate(
      'Podcast',
      {
        title: '',
        podcast: latestPodcast,
        audioHistory: this.props.screenProps.audioHistory,
        updateAudioHistory: this.props.screenProps.updateAudioHistory,
      },
      this.state,
    );
  }

  goToVideoList = () => {
    this.props.navigation.navigate(
      'VideoList',
      {
        title: 'Videos',
      },
      this.state,
    );
  };

  goToVideo = latestVideo => {
    this.props.navigation.navigate(
      'Video',
      {
        title: '',
        video: latestVideo,
        from: 'Dashboard',
      },
      this.state,
    );
  };

  goToPlusSuggestedQuizList() {
    let quizSets = [];

    for (
      let plusQuizSuggestionIndex = 0;
      plusQuizSuggestionIndex <
      this.props.screenProps.plusQuizSuggestions.length;
      plusQuizSuggestionIndex++
    ) {
      let plusQuizSuggestion =
        this.props.screenProps.plusQuizSuggestions[plusQuizSuggestionIndex];
      if (plusQuizSuggestion.quizSet) {
        quizSets.push(plusQuizSuggestion.quizSet);
      }
    }

    this.props.navigation.navigate(
      'PlusSuggestedQuiz',
      {
        title: 'Suggested',
      },
      this.state,
    );
  }

  goToQuizOverview(quizSet: Object, from: string, navigation) {
    if (quizSet.type === 'standby') {
      var selectedIssue = null;

      for (
        var issueIndex = 0;
        issueIndex < this.props.screenProps.issues.length;
        issueIndex++
      ) {
        var issue = this.props.screenProps.issues[issueIndex];

        if (issue.Name === quizSet.issueName) {
          selectedIssue = issue;
        }
      }

      navigation.navigate(
        'StandbyVolume',
        {
          quizSet: quizSet,
          issue: selectedIssue,
        },
        this.state,
      );
    } else if (quizSet.type === 'caseStudy') {
      var selectedCaseStudy = {};

      for (
        var caseStudyIndex = 0;
        caseStudyIndex < this.props.screenProps.caseStudies.length;
        caseStudyIndex++
      ) {
        var caseStudy = this.props.screenProps.caseStudies[caseStudyIndex];

        if (caseStudy.Name === quizSet.caseStudy) {
          selectedCaseStudy = caseStudy;
        }
      }

      navigation.navigate(
        'CaseStudyOverview',
        {
          quizSet: quizSet,
          caseStudy: selectedCaseStudy,
        },
        this.state,
      );
    } else {
      navigation.navigate(
        'QuizOverview',
        {
          quizSet: quizSet,
          from: from,
        },
        this.state,
      );
    }
  }

  goToQuizTab() {
    this.props.navigation.navigate(
      'QuizDashboard',
      {
        menuButtonTapped: () => this.props.screenProps.showMenu(true),
      },
      this.state,
    );
  }

  goToQuiz(quiz: Object, quizSet: Object, results: [], from: string) {
    var questionsForQuiz = [];
    var currentQuestion = null;
    var currentQuestionNumber = 1;
    var questionNumbersAnswered = [];
    var questionNumbersViewed = [];
    var correctCount = 0;
    var incorrectCount = 0;

    for (
      var quizQuestionIndex = 0;
      quizQuestionIndex < quiz.questions.length;
      quizQuestionIndex++
    ) {
      var quizQuestionName = quiz.questions[quizQuestionIndex];
      var questionForQuiz = null;

      // add full question to quiz
      for (
        var questionIndex = 0,
          questionsLength = this.props.screenProps.questions.length;
        questionIndex < questionsLength;
        questionIndex++
      ) {
        var question = this.props.screenProps.questions[questionIndex];

        if (question.Name === quizQuestionName) {
          questionsForQuiz.push(question);
          questionForQuiz = question;
        }
      }

      var resultFound = false;

      for (let resultIndex = 0; resultIndex < results?.length; resultIndex++) {
        var result = results[resultIndex];
        var resultQuestionName = result.packName + '-' + result.questionID;

        if (
          result.quizName === quiz.name &&
          resultQuestionName === quizQuestionName
        ) {
          resultFound = true;

          if (result.correct) {
            correctCount++;
          } else {
            incorrectCount++;
          }

          questionNumbersAnswered.push(quizQuestionIndex + 1);
          questionNumbersViewed.push(quizQuestionIndex + 1);
        }
      }

      if (resultFound === false && currentQuestion == null) {
        currentQuestionNumber = quizQuestionIndex + 1;
        currentQuestion = questionForQuiz;
      }
    }

    this.props.navigation.navigate(
      'Quiz',
      {
        quiz: quiz,
        quizSet: quizSet,
        questions: questionsForQuiz,
        currentQuestion: currentQuestion,
        currentQuestionNumber: currentQuestionNumber,
        questionNumbersAnswered: questionNumbersAnswered,
        questionNumbersViewed: questionNumbersViewed,
        correctCount: correctCount,
        incorrectCount: incorrectCount,
        comingFromOverviewScreen: false,
        from: from,
      },
      this.state,
    );
  }

  goToBook = book => {
    try {
      firebase.analytics().logEvent('book_viewed', {bookViewedName: book.name});
    } catch (error) {
      console.log(error);
    }

    this.props.navigation.navigate(
      'BookContents',
      {
        book,
      },
      this.state,
    );
  };

  goToBooksDashboard = () => {
    this.props.navigation.navigate(
      'BooksDashboard',
      {
        menuButtonTapped: this.menuButtonTapped.bind(this),
      },
      this.state,
    );
  };

  goToPGDs() {
    var quizSets = [];

    for (
      let quizSetIndex = 0;
      quizSetIndex < this.props.screenProps.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.props.screenProps.quizSets[quizSetIndex];

      if (quizSet.type === 'topic') {
        quizSets.push(quizSet);
      }
    }

    this.props.navigation.navigate(
      'QuizSetList',
      {
        quizSets: quizSets,
        title: 'PGDs',
        type: 'topics',
        initialFilter: 'PGD Quizzes',
      },
      this.state,
    );
  }

  goToPMAs() {
    var quizSets = [];

    for (
      let quizSetIndex = 0;
      quizSetIndex < this.props.screenProps.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.props.screenProps.quizSets[quizSetIndex];

      if (quizSet.type === 'topic') {
        quizSets.push(quizSet);
      }
    }

    this.props.navigation.navigate(
      'QuizSetList',
      {
        quizSets: quizSets,
        title: 'PMAs',
        type: 'topics',
        initialFilter: 'PMA Quizzes',
      },
      this.state,
    );
  }

  goToPSIRF() {
    this.props.navigation.navigate(
      'PSIRFDashboard',
      {
        data: this.props.screenProps.psirfs,
        quizOnPressHandler: (quizSet, from) =>
          this.goToQuizOverview(quizSet, from, this.props.navigation),
      },
      this.state,
    );
  }

  goToQuizResult(quiz: Object, quizSet: Object, results: []) {
    var questionsForQuiz = [];

    for (
      var quizQuestionIndex = 0;
      quizQuestionIndex < quiz.questions.length;
      quizQuestionIndex++
    ) {
      var quizQuestionName = quiz.questions[quizQuestionIndex];

      for (
        var questionIndex = 0,
          questionsLength = this.props.screenProps.questions.length;
        questionIndex < questionsLength;
        questionIndex++
      ) {
        var question = this.props.screenProps.questions[questionIndex];

        if (question.Name === quizQuestionName) {
          questionsForQuiz.push(question);
        }
      }
    }

    this.props.navigation.navigate(
      'QuizResults',
      {
        quiz: quiz,
        quizSet: quizSet,
        questions: questionsForQuiz,
        results: results,
      },
      this.state,
    );
  }

  viewAllResults() {
    this.props.navigation.navigate('AllResults', {}, this.state);
  }

  goToSearch() {
    console.log('search');
    this.props.navigation.navigate(
      'Search',
      {
        quizSets: this.props.screenProps.quizSets,
      },
      this.state,
    );
  }

  menuButtonTapped() {
    this.props.screenProps.showMenu(true);
  }

  featuredCardTapped = featuredCard => {
    let featuredCardQuizSet = null;
    if (featuredCard.Quiz != null) {
      for (
        let quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.props.screenProps.quizSets[quizSetIndex];
        if (featuredCard.Quiz === quizSet.name) {
          featuredCardQuizSet = quizSet;
          break;
        }
      }
    }

    if (featuredCardQuizSet != null) {
      this.goToQuizOverview(
        featuredCardQuizSet,
        'Featured Card',
        this.props.navigation,
      );
    }

    if (featuredCard.URL != null && featuredCard.URL !== '') {
      if (featuredCard.URL.includes('cpgcpd://')) {
        this.props.screenProps.handleOpenURL({url: featuredCard.URL});
      } else {
        PlatformController.openURL(featuredCard.URL);
      }
    }

    try {
      firebase
        .analytics()
        .logEvent('featured_card_tapped', {name: featuredCard.Title});
    } catch (error) {
      console.log(error);
    }
  };

  dailyChallengeButtonTapped(dailyChallengeQuizSet) {
    this.props.screenProps.takeQuiz(
      dailyChallengeQuizSet,
      '10 Questions',
      'Daily Challenge',
      this.state,
    );

    try {
      firebase.analytics().logEvent('daily_challenge_tapped', {
        quizSet: dailyChallengeQuizSet.name,
      });
    } catch (error) {
      console.log(error);
    }
  }

  plusQuizSuggestionTapped(plusQuizSuggestion) {
    this.goToQuizOverview(
      plusQuizSuggestion.quizSet,
      'Plus Quiz Suggestion',
      this.props.navigation,
    );

    try {
      firebase.analytics().logEvent('plus_quiz_suggestion_tapped', {
        quizSet: plusQuizSuggestion.quizSet.name,
      });
    } catch (error) {
      console.log(error);
    }
  }

  didYouKnowItemTapped(bookID, chapterNumber, sectionNumber) {
    this.props.screenProps.goToBook(
      bookID,
      chapterNumber,
      sectionNumber,
      this.state,
    );

    try {
      let name = bookID + '-' + chapterNumber + '-' + sectionNumber;

      firebase.analytics().logEvent('did_you_know_tapped', {name: name});
    } catch (error) {
      console.log(error);
    }
  }

  collapseButtonTapped(dashboardCardState) {
    try {
      dashboardCardState.collapsed = !dashboardCardState.collapsed;

      this.setState({dashboardCardStates: this.state.dashboardCardStates});
      DataController.saveDashboardCardStates(this.state.dashboardCardStates);
    } catch (error) {
      console.log(error);
    }
  }

  async resourceClicked(furtherResource) {
    console.log('resource clicked: ', furtherResource);

    let type = furtherResource.type;

    if (type === 'web link' || type === 'pdf') {
      if (furtherResource.link != null && furtherResource.link !== '') {
        Linking.openURL(furtherResource.link);
      }
    }

    if (type === 'app link') {
      if (Platform.OS === 'ios') {
        if (
          furtherResource.iosDeepLink != null &&
          furtherResource.iosDeepLink !== ''
        ) {
          var canOpenApp = await Linking.canOpenURL(
            furtherResource.iosDeepLink, // e.g jrcalcplus://open
          );

          if (canOpenApp) {
            Linking.openURL(furtherResource.iosDeepLink);
          } else {
            if (
              furtherResource.iosAppLink != null &&
              furtherResource.iosAppLink !== ''
            ) {
              Linking.openURL(furtherResource.iosAppLink);
            } else {
              if (furtherResource.link != null && furtherResource.link !== '') {
                Linking.openURL(furtherResource.link);
              }
            }
          }
        } else if (
          furtherResource.iosAppLink != null &&
          furtherResource.iosAppLink !== ''
        ) {
          Linking.openURL(furtherResource.iosAppLink);
        } else {
          if (furtherResource.link != null && furtherResource.link !== '') {
            Linking.openURL(furtherResource.link);
          }
        }
      } else if (Platform.OS === 'android') {
        const installed = await SendIntentAndroid.isAppInstalled(
          furtherResource.androidPackage,
        );

        if (installed) {
          SendIntentAndroid.openApp(furtherResource.androidPackage);
        } else {
          if (
            furtherResource.googlePlayLink != null &&
            furtherResource.googlePlayLink !== ''
          ) {
            Linking.openURL(furtherResource.googlePlayLink);
          } else {
            if (furtherResource.link != null && furtherResource.link !== '') {
              Linking.openURL(furtherResource.link);
            }
          }
        }
      } else {
        if (furtherResource.link != null && furtherResource.link !== '') {
          Linking.openURL(furtherResource.link);
        }
      }
    }
  }

  renderBooks = dashboardCardState => {
    const userTrusts = this.props.screenProps?.user?.plusTrusts || [];
    const booksForUser = this.props.screenProps?.books.filter(book =>
      this.findOne(book.trusts, userTrusts),
    );

    if (booksForUser.length === 0) {
      return null;
    }

    return (
      <BooksRail
        stateName={dashboardCardState.name}
        books={booksForUser}
        booksProgress={this.props.screenProps?.bookReadingProgress || []}
        isCollapsed={dashboardCardState.collapsed}
        onCollapseButtonPress={this.collapseButtonTapped.bind(
          this,
          dashboardCardState,
        )}
        onSeeMoreButtonPress={this.goToBooksDashboard}
        onBookPress={this.goToBook}
        quizSets={this.props.screenProps.quizSets}
      />
    );
  };

  findOne = (haystack, arr) => {
    return arr.some(v => haystack.includes(v));
  };

  getFeaturedCard = (featuredCard, quizSet, onCardPress, isLast) => {
    if (featuredCard.Icon === 'Promotion') {
      return (
        <PromoFeaturedCard
          featuredCard={featuredCard}
          quizSet={quizSet}
          onCardPress={onCardPress}
          isLast={isLast}
          onPress={eventParams => {
            this.props.screenProps.handlePromoModalToShow(
              'qrCode',
              eventParams,
            );
          }}
        />
      );
    }

    return (
      <FeaturedCard
        featuredCard={featuredCard}
        onCardPress={onCardPress}
        isLast={isLast}
      />
    );
  };

  render() {
    var inProgressQuizLayouts = [];

    // add in progress quizzes
    for (
      let quizIndex = 0;
      quizIndex < this.props.screenProps.inProgressQuizzes.length;
      quizIndex++
    ) {
      var quiz = this.props.screenProps.inProgressQuizzes[quizIndex];

      var results = this.props.screenProps.resultsForQuizzes[quiz.name];

      var quizSetForQuiz = null;

      for (
        var quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.props.screenProps.quizSets[quizSetIndex];

        if (quizSet.name === quiz.quizSet) {
          quizSetForQuiz = quizSet;
          break;
        }
      }

      if (quizSetForQuiz != null && results != null) {
        var quizLayout = (
          <TouchableOpacity
            key={quiz.name}
            onPress={this.goToQuiz.bind(
              this,
              quiz,
              quizSetForQuiz,
              results,
              'In Progress Quiz',
            )}>
            <QuizCell
              quiz={quiz}
              quizSet={quizSetForQuiz}
              results={results}
              inProgress={true}
              fontSize={this.props.screenProps.fontSize}
              user={this.props.screenProps.user}
            />
          </TouchableOpacity>
        );

        inProgressQuizLayouts.push(quizLayout);
      }

      if (inProgressQuizLayouts.length === 3) {
        break;
      }
    }

    if (inProgressQuizLayouts.length === 0) {
      inProgressQuizLayouts.push(
        <View
          key={'NoInProgressQuiz'}
          style={{alignItems: 'center', padding: 15}}>
          <View style={{flexDirection: 'row', marginBottom: 12}}>
            <Image
              style={{width: 50, height: 50}}
              source={require('../assets/EmptyState_Quizzes.png')}
            />
            <Image
              style={{width: 50, height: 50, marginHorizontal: 18}}
              source={require('../assets/EmptyState_Standby.png')}
            />
            <Image
              style={{width: 50, height: 50}}
              source={require('../assets/EmptyState_CaseStudies.png')}
            />
          </View>

          <DynamicText
            style={{color: colors.Grey700, textAlign: 'center', fontSize: 15}}>
            Your quizzes that are in progress will appear here. Get started with
            one now to test your knowledge.
          </DynamicText>

          <View style={{flex: 1, alignSelf: 'stretch', alignItems: 'center'}}>
            <View style={{flexDirection: 'row', flex: 1, maxWidth: 320}}>
              <TouchableOpacity
                activeOpacity={0.7}
                style={{
                  flex: 1,
                  marginTop: 15,
                  paddingVertical: 10,
                  paddingHorizontal: 100,
                  backgroundColor: colors.ButtonBlue,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 2,
                  shadowOffset: {width: 0, height: 1},
                  shadowRadius: 1,
                  shadowColor: 'black',
                  shadowOpacity: 0.2,
                }}
                onPress={this.goToQuizTab.bind(this)}>
                <DynamicText
                  style={{
                    fontSize: 16,
                    fontWeight: '700',
                    letterSpacing: -0.39,
                    color: colors.white,
                  }}>
                  Go to quizzes
                </DynamicText>
              </TouchableOpacity>
            </View>
          </View>
        </View>,
      );
    }

    var pgdLayouts = [];
    var pmaLayout = [];

    if (this.props.screenProps.user != null) {
      for (
        let trustIndex = 0;
        trustIndex < this.props.screenProps.user.plusTrusts.length;
        trustIndex++
      ) {
        var trust = this.props.screenProps.user.plusTrusts[trustIndex];

        var pgdQuizSetFound = false;
        var pmaQuizSetFound = false;

        for (
          let quizSetIndex = 0;
          quizSetIndex < this.props.screenProps.quizSets.length;
          quizSetIndex++
        ) {
          var aQuizSet = this.props.screenProps.quizSets[quizSetIndex];
          if (aQuizSet.pgd === true && aQuizSet.pma !== true) {
            pgdQuizSetFound = true;
          }
          if (aQuizSet.pma === true) {
            pmaQuizSetFound = true;
          }
        }

        if (pgdQuizSetFound) {
          pgdLayouts.push(
            <DashboardNavCard
              key={trustIndex}
              trust={trust}
              onPress={this.goToPGDs.bind(this)}
              type={'PGDs'}
            />,
          );
        }

        if (pmaQuizSetFound) {
          pmaLayout.push(
            <DashboardNavCard
              key={trustIndex}
              activeOpacity={0.5}
              onPress={this.goToPMAs.bind(this)}
              type={'PMAs'}
              trust={trust}
            />,
          );
        }
      }
    }

    const featuredCardsLayout = this.props.screenProps.featuredCards
      .map((featuredCard, index, array) => {
        let featuredCardQuizSet =
          this.props.screenProps.quizSets.find(
            quizSet => featuredCard.Quiz === quizSet.name,
          ) || null;

        if (featuredCard.Quiz != null && featuredCardQuizSet == null) {
          return null;
        }

        if (
          featuredCard.URL?.includes('cpgcpd://book') &&
          this.props.screenProps.user != null &&
          this.props.screenProps.user.hasBookAccess === false
        ) {
          return null;
        }

        const isLast = index + 1 === array.length;

        return this.getFeaturedCard(
          featuredCard,
          featuredCardQuizSet,
          () => this.featuredCardTapped(featuredCard),
          isLast,
        );
      })
      .filter(Boolean); // Remove null values

    // plus suggestion card
    let plusQuizSuggestionsLayout = [];
    for (
      let plusQuizSuggestionIndex = 0;
      plusQuizSuggestionIndex <
      this.props.screenProps.plusQuizSuggestions.length;
      plusQuizSuggestionIndex++
    ) {
      let plusQuizSuggestion =
        this.props.screenProps.plusQuizSuggestions[plusQuizSuggestionIndex];
      if (plusQuizSuggestionIndex >= 5) {
        break;
      }

      var sectionColor = colors.SectDefault;
      if (plusQuizSuggestion.quizSet && plusQuizSuggestion.quizSet.section) {
        sectionColor = DataController.getPrimaryColorForSection(
          plusQuizSuggestion.quizSet.section,
        );
      }

      plusQuizSuggestionsLayout.push(
        <TouchableOpacity
          key={plusQuizSuggestion.GLID}
          onPress={this.plusQuizSuggestionTapped.bind(
            this,
            plusQuizSuggestion,
          )}>
          <View
            style={{
              marginRight: 12,
              padding: 12,
              borderWidth: 1,
              borderColor: colors.Grey400,
              borderRadius: 4,
            }}>
            <View style={{flexDirection: 'row'}}>
              {plusQuizSuggestion.quizSet.section ? (
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: '600',
                      color: sectionColor,
                    }}>
                    {plusQuizSuggestion.quizSet.section}
                  </Text>
                  <View
                    style={{
                      marginHorizontal: 6,
                      width: 2,
                      height: 2,
                      backgroundColor: colors.Grey700,
                      borderRadius: 1,
                    }}
                  />
                </View>
              ) : null}
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '600',
                  color: colors.Grey800,
                }}>
                {plusQuizSuggestion.displayDate}
              </Text>
            </View>
            <Text
              style={{
                maxWidth: 280,
                marginTop: 2,
                fontSize: 16,
                fontWeight: '600',
              }}>
              {plusQuizSuggestion.title}
            </Text>
          </View>
        </TouchableOpacity>,
      );
    }

    // did you know cards
    let didYouKnowItemsLayout = [];
    for (
      let didYouKnowItemIndex = 0;
      didYouKnowItemIndex < this.props.screenProps.didYouKnowItems.length;
      didYouKnowItemIndex++
    ) {
      let didYouKnowItem =
        this.props.screenProps.didYouKnowItems[didYouKnowItemIndex];
      if (didYouKnowItemIndex >= 5) {
        break;
      }

      if (didYouKnowItem.Book) {
        let bookParts = didYouKnowItem.Book.split('-');
        if (bookParts.length > 0) {
          let bookID = bookParts[0];

          let book = this.props.screenProps.books.find(
            aBook => aBook.ID === bookID,
          );

          if (book != null) {
            let backgroundPrimaryColor = book.primaryColor
              ? book.primaryColor
              : '#3E9EAE';
            let backgroundSecondaryColor = book.secondaryColor
              ? book.secondaryColor
              : '#B5E0E7';

            let chapterNumber = 0;
            let sectionNumber = 0;

            if (bookParts.length > 1) {
              try {
                chapterNumber = parseInt(bookParts[1]);
              } catch (error) {}
            }
            if (bookParts.length > 2) {
              sectionNumber = parseInt(bookParts[2]);
            }

            didYouKnowItemsLayout.push(
              <TouchableOpacity
                key={didYouKnowItem.ID}
                onPress={this.didYouKnowItemTapped.bind(
                  this,
                  bookID,
                  chapterNumber,
                  sectionNumber,
                )}>
                <View
                  style={{
                    maxWidth: 250,
                    height: 204,
                    justifyContent: 'space-between',
                    marginRight: 12,
                    backgroundColor: backgroundSecondaryColor,
                    borderRadius: 4,
                    overflow: 'hidden',
                  }}>
                  <Text
                    style={{
                      padding: 12,
                      fontSize: 16,
                      fontWeight: '600',
                      lineHeight: 24,
                    }}
                    numberOfLines={5}>
                    {didYouKnowItem.Content}
                  </Text>
                  <View style={{backgroundColor: backgroundPrimaryColor}}>
                    <Text
                      style={{
                        paddingHorizontal: 12,
                        paddingVertical: 12,
                        fontSize: 13,
                        fontWeight: '500',
                        color: colors.white,
                      }}>
                      Read about it in {book.name}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>,
            );
          }
        }
      }
    }

    let dashboardCardsLayout = [];

    if (this.state.dashboardCardStates) {
      for (
        let dashboardCardIndex = 0;
        dashboardCardIndex < this.state.dashboardCardStates.length;
        dashboardCardIndex++
      ) {
        let dashboardCardState =
          this.state.dashboardCardStates[dashboardCardIndex];

        if (dashboardCardState.name === 'Featured') {
          dashboardCardsLayout.push(
            <ScrollView
              style={{marginBottom: 6}}
              key={dashboardCardState.name}
              horizontal={true}
              snapToAlignment="start"
              decelerationRate={'fast'}
              snapToEnd={false}
              disableIntervalMomentum={true}
              snapToInterval={316}>
              {featuredCardsLayout}
            </ScrollView>,
          );
        }

        if (
          dashboardCardState.name === 'Your daily CPD' &&
          this.props.screenProps.features.includes('paraPassDailyChallenge')
        ) {
          if (this.props.screenProps.dailyChallenge != null) {
            let dailyChallengeQuizSet = null;

            for (
              let quizSetIndex = 0;
              quizSetIndex < this.props.screenProps.quizSets.length;
              quizSetIndex++
            ) {
              var quizSet = this.props.screenProps.quizSets[quizSetIndex];
              if (
                this.props.screenProps.dailyChallenge.quizName === quizSet.name
              ) {
                dailyChallengeQuizSet = quizSet;
                break;
              }
            }

            if (dailyChallengeQuizSet) {
              let dailyChallengeBackgroundColor = colors.SectDefault;
              if (dailyChallengeQuizSet.section) {
                dailyChallengeBackgroundColor =
                  DataController.getPrimaryColorForSection(
                    dailyChallengeQuizSet.section,
                  );
              }

              let questionCount = 10;
              if (
                dailyChallengeQuizSet.questions &&
                dailyChallengeQuizSet.questions.length < 10
              ) {
                questionCount = dailyChallengeQuizSet.questions.length;
              }

              let dailyChallengeTitle = `Take today's ${questionCount} question quiz, it all counts as CPD`;
              let dailyChallengeScoreTitle = '';
              let dailyChallengeButtonText = 'Take the quiz';
              let dailyChallengeButtonIsFilled = true;
              if (this.props.screenProps.dailyChallenge.userCount === 1) {
                dailyChallengeTitle = `1 person has taken today's quiz, try it yourself`;
              } else if (this.props.screenProps.dailyChallenge.userCount > 0) {
                dailyChallengeTitle = `${this.props.screenProps.dailyChallenge.userCount} people have taken today's quiz, try it yourself`;
              }

              let quizzes = this.props.screenProps.quizzes.filter(
                aQuiz =>
                  aQuiz.quizSet ===
                  this.props.screenProps.dailyChallenge.quizName,
              );

              if (quizzes.length > 0) {
                let now = moment();

                let highestScore = 0;

                for (let quiz of quizzes) {
                  // check quiz was taken today
                  if (now.isSame(new Date(quiz.time), 'day')) {
                    let resultsForQuiz =
                      this.props.screenProps.resultsForQuizzes[quiz.name];
                    if (resultsForQuiz) {
                      let correctCountForDailyChallengeQuiz = 0;
                      for (let result of resultsForQuiz) {
                        if (result.correct) {
                          correctCountForDailyChallengeQuiz++;
                        }
                      }

                      if (correctCountForDailyChallengeQuiz > highestScore) {
                        highestScore = correctCountForDailyChallengeQuiz;
                      }
                    }
                  }
                }

                if (highestScore > 0) {
                  dailyChallengeButtonText = 'Try again';

                  if (highestScore >= questionCount) {
                    if (this.props.screenProps.dailyChallenge.userCount === 0) {
                      dailyChallengeTitle = `You are the first person to beat the quiz`;
                    } else {
                      dailyChallengeTitle = `You and ${
                        this.props.screenProps.dailyChallenge.userCount
                      } other ${
                        this.props.screenProps.dailyChallenge.userCount === 1
                          ? 'person'
                          : 'people'
                      } have beaten the quiz`;
                    }
                    dailyChallengeScoreTitle = `You scored ${questionCount}/${questionCount}, well done!`;
                    dailyChallengeButtonText = 'Take the challenge again';
                    dailyChallengeButtonIsFilled = false;
                  } else if (highestScore > 0) {
                    dailyChallengeTitle = 'Try to get 100%';
                    dailyChallengeScoreTitle = `You scored ${highestScore}/${questionCount}`;
                  }
                }
              }

              if (LayoutController.isCompact()) {
                dashboardCardsLayout.push(
                  <View
                    key={dashboardCardState.name}
                    style={{
                      backgroundColor: dailyChallengeBackgroundColor,
                      marginBottom: 16,
                      paddingHorizontal: 12,
                      paddingVertical: 16,
                      shadowOffset: {width: 0, height: 1},
                      shadowRadius: 1,
                      shadowColor: 'black',
                      shadowOpacity: 0.2,
                    }}>
                    <DynamicText
                      style={{fontSize: 17, fontWeight: '800', color: 'white'}}>
                      Your daily CPD
                    </DynamicText>
                    <DynamicText
                      style={{
                        marginTop: 8,
                        fontSize: 13,
                        fontWeight: '700',
                        color: 'white',
                      }}>
                      {dailyChallengeTitle}
                    </DynamicText>
                    <DynamicText
                      style={{
                        marginTop: 12,
                        fontSize: 28,
                        fontWeight: '700',
                        color: 'white',
                      }}>
                      {dailyChallengeQuizSet.title}
                    </DynamicText>
                    {dailyChallengeScoreTitle !== '' ? (
                      <DynamicText
                        style={{
                          marginTop: 8,
                          fontSize: 13,
                          fontWeight: '700',
                          color: 'white',
                        }}>
                        {dailyChallengeScoreTitle}
                      </DynamicText>
                    ) : null}
                    <TouchableOpacity
                      onPress={this.dailyChallengeButtonTapped.bind(
                        this,
                        dailyChallengeQuizSet,
                      )}>
                      <View
                        style={{
                          marginTop: 22,
                          paddingVertical: 16,
                          backgroundColor: dailyChallengeButtonIsFilled
                            ? colors.white
                            : dailyChallengeBackgroundColor,
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderWidth: 1,
                          borderColor: colors.white,
                          borderRadius: 8,
                        }}>
                        <Text
                          style={{
                            fontSize: 16,
                            fontWeight: '700',
                            color: dailyChallengeButtonIsFilled
                              ? dailyChallengeBackgroundColor
                              : colors.white,
                          }}>
                          {dailyChallengeButtonText}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>,
                );
              } else {
                dashboardCardsLayout.push(
                  <View
                    key={dashboardCardState.name}
                    style={{
                      backgroundColor: dailyChallengeBackgroundColor,
                      marginBottom: 16,
                      paddingHorizontal: 12,
                      paddingVertical: 16,
                      shadowOffset: {width: 0, height: 1},
                      shadowRadius: 1,
                      shadowColor: 'black',
                      shadowOpacity: 0.2,
                    }}>
                    <DynamicText
                      style={{fontSize: 17, fontWeight: '800', color: 'white'}}>
                      Your daily CPD
                    </DynamicText>
                    <DynamicText
                      style={{
                        marginTop: 8,
                        fontSize: 13,
                        fontWeight: '700',
                        color: 'white',
                      }}>
                      {dailyChallengeTitle}
                    </DynamicText>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}>
                      <View style={{flex: 1}}>
                        <DynamicText
                          style={{
                            marginTop: 12,
                            fontSize: 28,
                            fontWeight: '700',
                            color: 'white',
                          }}>
                          {dailyChallengeQuizSet.title}
                        </DynamicText>
                        {dailyChallengeScoreTitle !== '' ? (
                          <DynamicText
                            style={{
                              marginTop: 8,
                              fontSize: 13,
                              fontWeight: '700',
                              color: 'white',
                            }}>
                            {dailyChallengeScoreTitle}
                          </DynamicText>
                        ) : null}
                      </View>
                      <TouchableOpacity
                        onPress={this.dailyChallengeButtonTapped.bind(
                          this,
                          dailyChallengeQuizSet,
                        )}>
                        <View
                          style={{
                            marginTop: 22,
                            paddingHorizontal: 20,
                            paddingVertical: 16,
                            backgroundColor: dailyChallengeButtonIsFilled
                              ? colors.white
                              : dailyChallengeBackgroundColor,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderColor: colors.white,
                            borderRadius: 8,
                          }}>
                          <Text
                            style={{
                              fontSize: 16,
                              fontWeight: '700',
                              color: dailyChallengeButtonIsFilled
                                ? dailyChallengeBackgroundColor
                                : colors.white,
                            }}>
                            {dailyChallengeButtonText}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>,
                );
              }
            }
          } else {
            dashboardCardsLayout.push(
              <View
                key={dashboardCardState.name}
                style={{
                  backgroundColor: colors.Grey500,
                  marginBottom: 16,
                  paddingHorizontal: 12,
                  paddingVertical: 16,
                  shadowOffset: {width: 0, height: 1},
                  shadowRadius: 1,
                  shadowColor: 'black',
                  shadowOpacity: 0.2,
                }}>
                <DynamicText
                  style={{fontSize: 17, fontWeight: '800', color: 'white'}}>
                  Your daily CPD
                </DynamicText>
                <DynamicText
                  style={{
                    marginTop: 8,
                    fontSize: 13,
                    fontWeight: '700',
                    color: 'white',
                  }}>
                  Take today's quiz, it all counts as CPD
                </DynamicText>
                <View
                  style={{
                    marginTop: 10,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <DynamicText
                    style={{fontSize: 20, fontWeight: '700', color: 'white'}}>
                    Loading quiz
                  </DynamicText>
                  <ActivityIndicator
                    color={'white'}
                    style={{marginLeft: 10}}></ActivityIndicator>
                </View>
              </View>,
            );
          }
        }

        if (
          dashboardCardState.name === 'Suggested from JRCALC Plus' &&
          this.props.screenProps.features.includes(
            'paraPassGuidelineSuggestions',
          ) &&
          plusQuizSuggestionsLayout.length > 0
        ) {
          dashboardCardsLayout.push(
            <ContentCard
              key={dashboardCardState.name}
              name="Suggested from JRCALC Plus"
              seeMoreButton={true}
              seeMoreButtonTapped={this.goToPlusSuggestedQuizList.bind(this)}
              collapsed={dashboardCardState.collapsed}
              collapsible={true}
              collapseButtonTapped={this.collapseButtonTapped.bind(
                this,
                dashboardCardState,
              )}
              style={{
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <Text
                style={{marginBottom: 10, fontSize: 13, color: colors.Grey900}}>
                Practise your knowledge with a quiz on your recently viewed
                guidelines
              </Text>
              <ScrollView horizontal={true}>
                {plusQuizSuggestionsLayout}
              </ScrollView>
            </ContentCard>,
          );
        }

        if (
          dashboardCardState.name === 'Latest Videos' &&
          this.props.screenProps.user &&
          this.props.screenProps.videos?.length
        ) {
          const videoSortedByDate = this.props.screenProps.videos
            .sort((a, b) => {
              return new Date(b.ReleaseDate) - new Date(a.ReleaseDate);
            })
            .slice(0, 6);
          dashboardCardsLayout.push(
            <ContentCard
              key={dashboardCardState.name}
              name="Latest Videos"
              seeMoreButton={true}
              seeMoreButtonTapped={this.goToVideoList}
              collapsed={dashboardCardState.collapsed}
              collapsible={true}
              collapseButtonTapped={() =>
                this.collapseButtonTapped(dashboardCardState)
              }
              padContents={false}
              style={{
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <VideosRail
                videos={videoSortedByDate}
                videoOnPress={this.goToVideo}
                videoHistory={this.props.screenProps.videoHistory}
                paddingStart
              />
            </ContentCard>,
          );
        }

        if (
          dashboardCardState.name === 'Latest Podcast' &&
          this.props.screenProps.user &&
          this.props.screenProps.podcasts?.length
        ) {
          const sortedDates = this.props.screenProps.podcasts.sort((a, b) => {
            return new Date(a) - new Date(b);
          });
          const latestPodcast = sortedDates[sortedDates.length - 1];
          const latestPodcastHistory = this.props.screenProps.audioHistory.find(
            pod => pod.audioId === latestPodcast.Id,
          );
          dashboardCardsLayout.push(
            <ContentCard
              key={dashboardCardState.name}
              name="Latest Podcast"
              seeMoreButton={true}
              seeMoreButtonTapped={this.goToPodcastList.bind(this)}
              collapsed={dashboardCardState.collapsed}
              collapsible={true}
              collapseButtonTapped={this.collapseButtonTapped.bind(
                this,
                dashboardCardState,
              )}
              style={{
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <TouchableOpacity
                style={{flexDirection: 'row'}}
                onPress={this.goToPodcast.bind(this, latestPodcast)}>
                <ImageBackground
                  imageStyle={{
                    width: 70,
                    height: 70,
                    borderColor: colors.Grey100,
                    borderWidth: 1,
                    borderRadius: 2,
                    backgroundColor: colors.Grey100,
                    resizeMode: 'center',
                  }}
                  style={{
                    width: 70,
                    height: 70,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  source={{uri: latestPodcast.imageUrl}}>
                  <View
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      borderRadius: 25,
                      width: 50,
                      height: 50,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Image
                      style={{
                        width: 15,
                        height: 20,
                      }}
                      source={require('../assets/Button_Play_White.png')}
                    />
                  </View>
                </ImageBackground>
                <View style={{marginLeft: 8}}>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Text
                      style={{
                        color: colors.Grey700,
                        fontWeight: '500',
                        fontSize: 12,
                      }}>
                      {moment(latestPodcast.ReleaseDate).format('DD MMM YYYY')}
                    </Text>
                    <View
                      style={{
                        backgroundColor: 'rgb(121, 139, 147)',
                        borderRadius: 1,
                        height: 2,
                        marginRight: 6,
                        marginLeft: 6,
                        width: 2,
                      }}
                    />
                    <View
                      style={{
                        justifyContent: 'center',
                        paddingHorizontal: 6,
                        height: 16,
                        borderRadius: 2,
                        backgroundColor: DataController.getColorForTrust(
                          latestPodcast.Trust,
                        ),
                      }}>
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: '500',
                          letterSpacing: -0.1,
                          color: colors.white,
                        }}>
                        {DataController.getTrustDisplayName(
                          latestPodcast.Trust,
                        )}
                      </Text>
                    </View>
                    {latestPodcastHistory &&
                      latestPodcastHistory.secondsListened >= 60 && (
                        <>
                          <View
                            style={{
                              backgroundColor: 'rgb(121, 139, 147)',
                              borderRadius: 1,
                              height: 2,
                              marginRight: 6,
                              marginLeft: 6,
                              width: 2,
                            }}
                          />
                          <Text
                            style={{color: colors.Grey700, fontWeight: '500'}}>
                            Listened
                          </Text>
                        </>
                      )}
                  </View>
                  <Text
                    style={{
                      fontSize: 17,
                      fontWeight: '600',
                      color: colors.Grey900,
                      marginTop: 4,
                    }}>
                    {latestPodcast.Title}
                  </Text>
                </View>
              </TouchableOpacity>
            </ContentCard>,
          );
        }

        if (dashboardCardState.name === 'Books') {
          dashboardCardsLayout.push(this.renderBooks(dashboardCardState));
        }

        if (
          dashboardCardState.name === 'Did you know' &&
          this.props.screenProps.features.includes('paraPassDidYouKnow') &&
          didYouKnowItemsLayout.length > 0
        ) {
          dashboardCardsLayout.push(
            <ContentCard
              key={dashboardCardState.name}
              name="Did you know...?"
              collapsed={dashboardCardState.collapsed}
              collapsible={true}
              collapseButtonTapped={this.collapseButtonTapped.bind(
                this,
                dashboardCardState,
              )}
              style={{
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <Text
                style={{marginBottom: 10, fontSize: 13, color: colors.Grey900}}>
                Stuff you might have missed
              </Text>
              <ScrollView horizontal={true}>{didYouKnowItemsLayout}</ScrollView>
            </ContentCard>,
          );
        }

        if (dashboardCardState.name === 'In progress') {
          dashboardCardsLayout.push(
            <ContentCard
              key={dashboardCardState.name}
              name="In progress"
              padContents={false}
              seeMoreButton={true}
              seeMoreButtonTapped={this.goToInProgressQuizList.bind(this)}
              collapsed={dashboardCardState.collapsed}
              collapsible={true}
              collapseButtonTapped={this.collapseButtonTapped.bind(
                this,
                dashboardCardState,
              )}
              style={{
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <View style={{borderTopWidth: 1, borderColor: colors.Grey100}}>
                {inProgressQuizLayouts}
              </View>
            </ContentCard>,
          );
        }

        if (
          dashboardCardState.name === 'CPD Resources' &&
          this.props.screenProps.extraResources.length
        ) {
          const resourceItems = this.props.screenProps.extraResources;
          dashboardCardsLayout.push(
            <ContentCard
              key={dashboardCardState.name}
              name="CPD Resources"
              padContents={false}
              seeMoreButton={false}
              collapsed={dashboardCardState.collapsed}
              collapsible={true}
              collapseButtonTapped={this.collapseButtonTapped.bind(
                this,
                dashboardCardState,
              )}
              style={{
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <View style={{borderTopWidth: 1, borderColor: colors.Grey100}}>
                {resourceItems.map((it, index) => (
                  <TouchableOpacity
                    key={index}
                    onPress={this.resourceClicked.bind(this, it)}
                    style={{
                      borderBottomWidth: 1,
                      borderBottomColor: colors.Grey100,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}>
                        <Image
                          source={
                            it.iconUrl === ''
                              ? require('../assets/Icon_Resources_PlaceholderParaPass.png')
                              : {uri: it.iconUrl}
                          }
                          style={{
                            width: 40,
                            height: 40,
                            marginLeft: 10,
                            borderWidth: 1,
                            borderColor: '#cfd4da',
                            borderRadius: 6,
                          }}
                          alt="resource icon"
                        />
                        <DynamicText
                          style={{
                            paddingLeft: 10,
                            marginVertical: 14,
                            fontSize: 17,
                            fontWeight: '600',
                            color: colors.Grey900,
                          }}>
                          {it.name}
                        </DynamicText>
                      </View>
                      <Image
                        source={require('../assets/Button_Link.png')}
                        style={{width: 40, height: 40}}
                      />
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
            </ContentCard>,
          );
        }
      }
    }

    const PSIRFLayouts = [];
    if (this.props.screenProps.user != null) {
      const psirfsForUser = this.props.screenProps.psirfs;
      const trust = psirfsForUser.find(psirf => psirf?.Trust)?.Trust;
      if (psirfsForUser.length > 0) {
        PSIRFLayouts.push(
          <MaxWidthContainer key={'psirfs'} parent="psirf">
            <DashboardNavCard
              trust={trust}
              onPress={() => this.goToPSIRF(psirfsForUser)}
              type={'PSIRFs'}
              title={this.props.screenProps?.psirfNavCard?.Title}
              description={this.props.screenProps?.psirfNavCard?.SubTitle}
            />
          </MaxWidthContainer>,
        );
      }
    }

    const searchLayout = (
      <MaxWidthContainer parent="search">
        <SearchButton
          onPress={this.goToSearch.bind(this)}
          text="Search ParaPass"
        />
      </MaxWidthContainer>
    );

    const swastLink =
      this.props.screenProps?.user?.plusTrusts.includes('SWAST') &&
      !this.props.screenProps?.user?.paraPassTrusts.includes('SWAST') ? (
        <MaxWidthContainer parent="swast link">
          <TouchableOpacity
            onPress={() =>
              PlatformController.openURL(
                'https://swastcpd.co.uk/events/para-pass-app/',
              )
            }
            style={{
              backgroundColor: '#dcfbec',
              borderWidth: 1,
              borderColor: '#7cd2b9',
              padding: 12,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              marginBottom: 16,
            }}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Image
                style={{
                  width: 20,
                  height: '100%',
                  resizeMode: 'contain',
                }}
                source={require('../assets/Icon_Lock_with_shadow.png')}
              />
              <Text
                style={{
                  fontWeight: 'bold',
                  marginLeft: 6,
                }}>
                Upgrade to full access for free
              </Text>
            </View>
            <Text style={{marginTop: 6}}>
              Tap here to let SWAST know you'd like to access all of the CPD
              resources ParaPass has to offer.
            </Text>
          </TouchableOpacity>
        </MaxWidthContainer>
      ) : null;

    return (
      <ScreenComponent style={[styles.container]}>
        <LoadingBar screenProps={this.props.screenProps} />
        <ScrollView styles={{flex: 1}}>
          {searchLayout}
          {swastLink}
          {PSIRFLayouts}
          <MaxWidthContainer parent="pgd">{pgdLayouts}</MaxWidthContainer>
          <MaxWidthContainer parent="pma">{pmaLayout}</MaxWidthContainer>
          <MaxWidthContainer parent="dashboard">
            {dashboardCardsLayout}
          </MaxWidthContainer>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
