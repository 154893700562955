/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
  Alert,
} from 'react-native';

import AMWebView from '../components/AMWebView';

import ScreenComponent from '../components/ScreenComponent';
import ProgressView from '../components/ProgressView';
import DataController from '../classes/DataController';
import GuidelinesController from '../classes/GuidelinesController';
import DynamicText from '../components/DynamicText';

import {colors} from '../classes/Colors';
import MaxWidthContainer from '../components/MaxWidthContainer';

var _ = require('lodash');
const timer = require('react-native-timer');

type Props = {};

type State = {
  quiz: Object,
  quizSet: Object,
  questions: [],
  results: [],
  resultIsCorrect: boolean,
  currentQuestion: Object,
  currentQuestionNumber: number,
  questionAnswers: [],
  questionHasBeenAnswered: boolean,
  isFirstQuestionLeftToAnswer: boolean,
  isLastQuestionLeftToAnswer: boolean,
  selectedAnswers: [],
  questionNumbersViewed: [],
  webviewHeight: number,
  correctCount: number,
  incorrectCount: number,
  secondsRemaining: number,
  extendedAnswerText: string,
  extendedAnswerPointerEvents: string,
  extendedAnswerHeight: number,
  checkingExtendedAnswer: boolean,
};

export default class QuizSingleResultScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var options = {
      title: navigation.getParam('titleText', ''),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerBackTitle: 'Back',
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };

    return options;
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      var isLastQuestionLeftToAnswer = false;
      var questions = this.props.navigation.getParam('questions', []);

      var currentQuestion = questions[0];
      var currentQuestionNumber = 1;

      if (this.props.navigation.getParam('initialQuestionNumber', 0) !== 0) {
        currentQuestionNumber = this.props.navigation.getParam(
          'initialQuestionNumber',
          0,
        );
        currentQuestion = questions[currentQuestionNumber - 1];
      }

      if (questions.length === 1) {
        isLastQuestionLeftToAnswer = true;
      }

      this.state = {
        quiz: this.props.navigation.getParam('quiz', {}),
        quizSet: this.props.navigation.getParam('quizSet', {}),
        questions: questions,
        results: this.props.navigation.getParam('results', []),
        resultIsCorrect: false,
        currentQuestion: currentQuestion,
        currentQuestionNumber: currentQuestionNumber,
        questionAnswers: [],
        questionHasBeenAnswered: true,
        isFirstQuestionLeftToAnswer: true,
        isLastQuestionLeftToAnswer: isLastQuestionLeftToAnswer,
        selectedAnswers: [],
        questionNumbersViewed: [],
        unansweredQuestions: this.props.navigation.getParam(
          'unansweredQuestions',
          [],
        ),
        webviewHeight: 1,
        correctCount: 0,
        incorrectCount: 0,
        secondsRemaining: 15,
        extendedAnswerText: '',
        extendedAnswerPointerEvents: 'none',
        extendedAnswerHeight: 100,
        checkingExtendedAnswer: true,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      this.loadQuestion();
    }

    var that = this;

    setTimeout(() => {
      //   that.props.navigation.setParams({);
    }, 100);
  }

  componentWillUnmount() {
    timer.clearInterval('5minuteTimer');
  }

  updateTimer() {
    let secondsRemaining = this.state.secondsRemaining - 1;

    if (secondsRemaining === 0) {
      timer.clearInterval('5minuteTimer');
    }

    var timeLeftString =
      Math.floor(secondsRemaining / 60) +
      ':' +
      ('0' + Math.floor(secondsRemaining % 60)).slice(-2);

    var titleText =
      'Q ' + this.state.currentQuestionNumber + ' | ' + timeLeftString;
    this.props.navigation.setParams({titleText: titleText});

    this.setState({secondsRemaining: secondsRemaining});
    console.log('seconds secondsRemaining: ' + secondsRemaining);
  }

  extendedAnswerTextDidChange(extendedAnswerText: string) {
    this.setState({extendedAnswerText: extendedAnswerText});
  }

  loadQuestion() {
    var shuffledQuestionAnswers = _.shuffle(this.state.currentQuestion.Answers);
    this.setState({
      questionAnswers: shuffledQuestionAnswers,
      checkingExtendedAnswer: false,
      selectedAnswers: [],
    });

    var selectedAnswers = [];

    for (
      let questionIndex = 0;
      questionIndex < this.state.questions.length;
      questionIndex++
    ) {
      var question = this.state.questions[questionIndex];

      if (question.Name === this.state.currentQuestion.Name) {
        var result = null;

        for (
          let resultIndex = 0;
          resultIndex < this.state.results?.length;
          resultIndex++
        ) {
          var aResult = this.state.results[resultIndex];
          var resultQuestionName = aResult.packName + '-' + aResult.questionID;

          if (resultQuestionName === question.Name) {
            result = aResult;
            break;
          }
        }

        if (result != null) {
          var questionAnswers = question.Answers;
          var resultAnswers = result.answer.split('|');

          for (
            var questionAnswerIndex = 0;
            questionAnswerIndex < question.Answers.length;
            questionAnswerIndex++
          ) {
            var questionAnswer = question.Answers[questionAnswerIndex];

            for (
              var resultAnswerIndex = 0;
              resultAnswerIndex < resultAnswers.length;
              resultAnswerIndex++
            ) {
              var resultAnswer = resultAnswers[resultAnswerIndex];

              if (question.QuestionType === 'Extended Answer') {
                this.setState({extendedAnswerText: result.answer});

                if (result.correct) {
                  questionAnswer.Correct = true;
                  this.setState({resultIsCorrect: true});
                } else {
                  this.setState({resultIsCorrect: false});
                }
              }

              if (resultAnswer === questionAnswer.Answer) {
                selectedAnswers.push(questionAnswer);
              }
            }
          }
        }
      }
    }

    this.setState({selectedAnswers: selectedAnswers});

    if (Platform.OS !== 'web') {
      Keyboard.dismiss();
    }

    var titleText = 'Question ' + this.state.currentQuestionNumber;
    this.props.navigation.setParams({titleText: titleText});

    var questionNumbersViewed = this.state.questionNumbersViewed;

    if (
      questionNumbersViewed.includes(this.state.currentQuestionNumber) === false
    ) {
      questionNumbersViewed.push(this.state.currentQuestionNumber);
      this.setState({questionNumbersViewed: questionNumbersViewed});
    }

    var firstQuestionNumber = 0;
    var lastQuestionNumber = 0;

    for (
      let questionNumber = 1;
      questionNumber <= this.state.questions.length;
      questionNumber++
    ) {
      var question = this.state.questions[questionNumber - 1];

      if (this.state.unansweredQuestions.includes(question.Name) === false) {
        if (firstQuestionNumber === 0) {
          firstQuestionNumber = questionNumber;
        }

        lastQuestionNumber = questionNumber;
      }
    }

    var isFirstQuestionLeftToAnswer =
      this.state.currentQuestionNumber === firstQuestionNumber;
    var isLastQuestionLeftToAnswer =
      this.state.currentQuestionNumber === lastQuestionNumber;

    this.setState({
      isFirstQuestionLeftToAnswer: isFirstQuestionLeftToAnswer,
      isLastQuestionLeftToAnswer: isLastQuestionLeftToAnswer,
    });

    if (this._scrollView != null && this._scrollView.scrollTo) {
      this._scrollView.scrollTo({x: 0, y: 0, animated: false});
    }
  }

  goToPreviousQuestion() {
    if (this.state.currentQuestionNumber > 1) {
      var previousQuestionNumber = 0;

      for (
        let questionNumber = this.state.questions.length;
        questionNumber > 0;
        questionNumber--
      ) {
        var question = this.state.questions[questionNumber - 1];

        if (
          questionNumber < this.state.currentQuestionNumber &&
          this.state.unansweredQuestions.includes(question.Name) === false
        ) {
          if (previousQuestionNumber === 0) {
            previousQuestionNumber = questionNumber;
          }
        }
      }

      if (previousQuestionNumber > 0) {
        var previousQuestion = this.state.questions[previousQuestionNumber - 1];

        this.setState(
          {
            currentQuestionNumber: previousQuestionNumber,
            currentQuestion: previousQuestion,
          },
          function () {
            this.loadQuestion();
          },
        );
      }
    }
  }

  goToNextQuestion() {
    if (this.state.currentQuestionNumber < this.state.questions.length) {
      var nextQuestionNumber = 0;
      var firstQuestionNumber = 0;
      var lastQuestionNumber = 0;

      for (
        let questionNumber = 1;
        questionNumber <= this.state.questions.length;
        questionNumber++
      ) {
        var question = this.state.questions[questionNumber - 1];

        if (
          questionNumber > this.state.currentQuestionNumber &&
          this.state.unansweredQuestions.includes(question.Name) === false
        ) {
          if (nextQuestionNumber === 0) {
            nextQuestionNumber = questionNumber;
          }
        }
      }

      if (nextQuestionNumber > 0) {
        var nextQuestion = this.state.questions[nextQuestionNumber - 1];

        this.setState(
          {
            currentQuestionNumber: nextQuestionNumber,
            currentQuestion: nextQuestion,
          },
          function () {
            this.loadQuestion();
          },
        );
      }
    }
  }

  async referenceButtonTapped() {
    var reference = this.state.currentQuestion.Reference;

    if (reference == null || reference === '') {
      return;
    }

    // var reference = null;
    // var referenceSup = null;
    // reference2019 = 'G0570 - Head Injury - 1. Introduction'

    var referenceParts = reference.split(' - ');

    if (reference.length > 2) {
      var glid = referenceParts[0];
      var section = referenceParts[2];

      console.log(glid);

      var referenceContent = await GuidelinesController.getGuidelineJSONForGLID(
        glid,
      );

      if (referenceContent != null && referenceContent !== 'Old Guideline') {
        this.props.navigation.navigate(
          'Reference',
          {
            reference: reference,
            guidelineContents: referenceContent,
            section: section,
            question: this.state.currentQuestion,
            from: 'Quiz Result',
          },
          this.state,
        );
      } else {
        let guidelineName =
          await GuidelinesController.getGuidelineNameForGuideline(glid);
        if (guidelineName != null && guidelineName !== '') {
          // guideline is in list so it should be downloaded - but might not have finished yet
          Alert.alert(
            "The reference can't be displayed at the moment. It might still be downloading. Please try again shortly.",
          );
        }
      }
    }
  }

  onNavigationChange(event) {
    if (event.title) {
      var htmlHeight = Number(event.title); //convert to number
      htmlHeight = htmlHeight;

      // if (this.state.currentQuestionNumber === 1) {
      //   htmlHeight = htmlHeight + 80;
      // }

      if (htmlHeight > 40) {
        this.setState({webviewHeight: htmlHeight});
      }
    }
  }

  answerStyle(answer: Object) {
    return {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 10,
      borderBottomWidth: 1,
      borderColor: colors.Grey100,
    };
  }

  checkboxImage(answer: Object) {
    var checkboxImage = null;

    if (this.state.questionHasBeenAnswered) {
      if (this.selectedAnswersContainsAnswer(answer)) {
        if (answer.Correct === true) {
          checkboxImage = (
            <Image
              style={{width: 30, height: 30}}
              source={require('../assets/Button_Checkbox_Active_Correct.png')}
            />
          );
        } else {
          checkboxImage = (
            <Image
              style={{width: 30, height: 30}}
              source={require('../assets/Button_Checkbox_Active_Incorrect.png')}
            />
          );
        }
      } else {
        if (answer.Correct === true) {
          checkboxImage = (
            <Image
              style={{width: 30, height: 30}}
              source={require('../assets/Button_Checkbox_Disabled_Correct.png')}
            />
          );
        } else {
          checkboxImage = (
            <Image
              style={{width: 30, height: 30}}
              source={require('../assets/Button_Checkbox_Disabled.png')}
            />
          );
        }
      }
    } else {
      if (this.selectedAnswersContainsAnswer(answer)) {
        checkboxImage = (
          <Image
            style={{width: 30, height: 30}}
            source={require('../assets/Button_Checkbox_Active.png')}
          />
        );
      } else {
        checkboxImage = (
          <Image
            style={{width: 30, height: 30}}
            source={require('../assets/Button_Checkbox_Inactive.png')}
          />
        );
      }
    }

    return checkboxImage;
  }

  answerFeedbackImage(answer: Object) {
    var feedbackImage = <View style={{width: 30, height: 30}} />;

    if (this.selectedAnswersContainsAnswer(answer) && answer.Correct === true) {
      feedbackImage = (
        <Image
          style={{width: 30, height: 30}}
          source={require('../assets/Answer_Tick_Green.png')}
        />
      );
    } else if (this.selectedAnswersContainsAnswer(answer)) {
      feedbackImage = (
        <Image
          style={{width: 30, height: 30}}
          source={require('../assets/Answer_Cross_Red.png')}
        />
      );
    }

    return feedbackImage;
  }

  selectedAnswersContainsAnswer(answer: Object) {
    return _.some(this.state.selectedAnswers, answer); // check to see if answer is in selected answers - checking equality
  }

  render() {
    var questionCount = 0;
    var unansweredCount = 0;

    if (this.state.questions != null) {
      questionCount = this.state.questions.length;
    }

    var results =
      this.props.screenProps.latestResultsForQuizSets[this.state.quizSet.name];

    unansweredCount =
      questionCount - this.state.correctCount - this.state.incorrectCount;

    var sectionLayout = null;

    if (this.state.currentQuestion.Section != null) {
      var subSectionLayout = null;
      if (
        this.state.currentQuestion.SubSection != null &&
        this.state.currentQuestion.DoNotShowSubSection !== true
      ) {
        subSectionLayout = (
          <DynamicText>
            <DynamicText style={{color: colors.Grey700}}> • </DynamicText>
            <DynamicText
              style={{
                flex: 1,
                fontSize: 15,
                letterSpacing: -0.24,
                color: colors.Grey800,
              }}
              numberOfLines={0}>
              {this.state.currentQuestion.SubSection}
            </DynamicText>
          </DynamicText>
        );
      }

      sectionLayout = (
        <View
          style={{
            flexDirection: 'row',
            paddingLeft: 7,
            paddingRight: 10,
            paddingVertical: 8,
            backgroundColor: DataController.getSecondaryColorForSection(
              this.state.currentQuestion.Section,
            ),
            borderLeftWidth: 3,
            borderLeftColor: DataController.getPrimaryColorForSection(
              this.state.currentQuestion.Section,
            ),
            alignItems: 'center',
          }}>
          <DynamicText>
            <DynamicText
              style={{
                opacity: 0.9,
                fontSize: 15,
                fontWeight: '500',
                letterSpacing: -0.24,
                color: colors.Grey900,
              }}>
              {this.state.currentQuestion.Section}
            </DynamicText>
            {subSectionLayout}
          </DynamicText>
        </View>
      );
    }

    var difficultyLayout = null;
    if (this.state.currentQuestion.Difficulty != null) {
      var difficultyText = '';

      if (this.state.currentQuestion.Difficulty === '1') {
        difficultyText = 'Level 1: Introduction';
      } else if (this.state.currentQuestion.Difficulty === '2') {
        difficultyText = 'Level 2: Intermediate';
      } else if (this.state.currentQuestion.Difficulty === '3') {
        difficultyText = 'Level 3: Advanced';
      }

      var difficultyLayout = (
        <DynamicText
          style={{
            marginTop: 10,
            fontSize: 14,
            letterSpacing: -0.44,
            color: colors.Grey800,
          }}>
          {difficultyText}
        </DynamicText>
      );
    }

    var styleCSS =
      `
            <style>
              body {
                
              }
              .quizSceneQuestion {
                margin: 0;
                padding: 0;
                font-family: InterUI-Regular, -apple-system, BlinkMacSystemFont,
                                          "Segoe UI", "Roboto", "Oxygen",
                                          "Ubuntu", "Cantarell", "Fira Sans",
                                          "Droid Sans", "Helvetica Neue", sans-serif;
                font-size: ` +
      this.props.screenProps.fontSize +
      `pt;
                font-weight: 600;
                line-height: 1.4;
                color: #374044;
              }

              body, html, .height-calculator {
                  margin: 0;
                  padding: 0;
              }
              .height-calculator {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
              }
              .hiddenText {
                display: none;
              }
            </style>
            `;

    // set-up question webview
    var rawHtml =
      "<html><head><meta name='viewport' content='width=device-width' initial-scale=1>" +
      styleCSS +
      "</head><body><div><p class='quizSceneQuestion'>" +
      this.state.currentQuestion.Question +
      "<span class='hiddenText'>" +
      1 +
      '</span></p></div></body></html>';

    const script = `
            <script>
            	window.location.hash = 1;
                var calculator = document.createElement("div");
                calculator.className = "height-calculator";
                while (document.body.firstChild) {
                    calculator.appendChild(document.body.firstChild);
                }
            	document.body.appendChild(calculator);
                document.title = calculator.scrollHeight;
            </script>
            `;

    var scalesPageToFit = false;

    if (Platform.OS === 'android') {
      scalesPageToFit = true;
    }

    var webContentLayout = (
      <AMWebView
        style={{flex: 1, height: this.state.webviewHeight}}
        // ref="mainWebView"
        source={{html: rawHtml + script}}
        // style={{height:this.state.Height}}
        useWebKit={true}
        scalesPageToFit={scalesPageToFit}
        javaScriptEnabled={true}
        allowsFullscreenVideo={true}
        onNavigationStateChange={this.onNavigationChange.bind(this)}
      />
    );

    if (Platform.OS === 'web') {
      webContentLayout = (
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: rawHtml + script}}></div>
      );
    }

    let answerLayouts = [];
    var helpTextLayout = null;
    var helpText = '';

    if (this.state.currentQuestion.QuestionType === 'Multi Select') {
      answerLayouts = this.state.questionAnswers.map((answer, i) => {
        return (
          <View key={answer.Answer} style={this.answerStyle(answer, i)}>
            <View style={{marginRight: 5}}>
              {this.checkboxImage(answer, i)}
            </View>
            <DynamicText
              style={{
                flex: 1,
                fontSize: 17,
                letterSpacing: -0.41,
                color: colors.Grey900,
              }}>
              {answer.Answer}
            </DynamicText>
            {this.answerFeedbackImage(answer, i)}
          </View>
        );
      });
    } else if (this.state.currentQuestion.QuestionType === 'Extended Answer') {
      if (this.state.questionHasBeenAnswered) {
        if (
          this.state.questionAnswers !== null &&
          this.state.questionAnswers.length > 0
        ) {
          var questionAnswer = this.state.questionAnswers[0];

          var feedbackImage = (
            <Image
              style={{width: 30, height: 30}}
              source={require('../assets/Answer_Cross_Red.png')}
            />
          );

          if (this.state.resultIsCorrect === true) {
            feedbackImage = (
              <Image
                style={{width: 30, height: 30}}
                source={require('../assets/Answer_Tick_Green.png')}
              />
            );
          }

          answerLayouts.push(
            <View key="extendedAnswer" style={{flex: 1}}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 6,
                  paddingHorizontal: 10,
                  paddingVertical: 11,
                  borderWidth: 1,
                  backgroundColor: colors.Grey000,
                  borderColor: colors.Grey400,
                  borderRadius: 2,
                }}>
                <DynamicText
                  style={{
                    fontSize: 17,
                    letterSpacing: -0.41,
                    color: colors.Grey900,
                  }}>
                  {this.state.extendedAnswerText}
                </DynamicText>
                {feedbackImage}
              </View>
            </View>,
          );

          helpText =
            'Mark your answer based off the correct one provided. Note you cannot change your mark.';
        }
      }
    } else {
      answerLayouts = this.state.questionAnswers.map((answer, i) => {
        return (
          <View key={answer.Answer} style={this.answerStyle(answer, i)}>
            <DynamicText
              style={{
                flex: 1,
                fontSize: 17,
                letterSpacing: -0.41,
                color: colors.Grey900,
              }}>
              {answer.Answer}
            </DynamicText>
            {this.answerFeedbackImage(answer, i)}
          </View>
        );
      });
    }

    var leftButtonType = '';

    if (this.state.isFirstQuestionLeftToAnswer === false) {
      leftButtonType = 'previousButton';
    }

    var bottomLeftButtonLayout = <View style={{flex: 1}} />;

    if (leftButtonType === 'previousButton') {
      bottomLeftButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.5}
          style={{flex: 1}}
          onPress={this.goToPreviousQuestion.bind(this)}>
          <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <Image
              style={{width: 11, height: 18}}
              source={require('../assets/Button_Previous.png')}
            />
            <DynamicText
              style={{
                marginLeft: 5,
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
              }}>
              Previous
            </DynamicText>
          </View>
        </TouchableOpacity>
      );
    }

    var rightButtonType = '';

    if (this.state.isLastQuestionLeftToAnswer === false) {
      rightButtonType = 'nextButton';
    }

    var bottomRightButtonLayout = <View style={{flex: 1}} />;

    if (rightButtonType === 'nextButton') {
      bottomRightButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.5}
          style={{flex: 1}}
          onPress={this.goToNextQuestion.bind(this)}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}>
            <DynamicText
              style={{
                marginRight: 5,
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
              }}>
              Next
            </DynamicText>
            <Image
              style={{width: 11, height: 18}}
              source={require('../assets/Button_Next.png')}
            />
          </View>
        </TouchableOpacity>
      );
    }

    var bottomBarLayout = null;

    if (this.state.questions.length > 1) {
      bottomBarLayout = (
        <View
          style={{
            padding: 20,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderTopWidth: 1,
            borderColor: colors.Grey300,
          }}>
          <MaxWidthContainer
            contentContainerStyle={{
              flexDirection: 'row',
            }}>
            {bottomLeftButtonLayout}

            <View style={{width: 15}} />

            {bottomRightButtonLayout}
          </MaxWidthContainer>
        </View>
      );
    }

    var referenceButtonLayout = <View />;

    // if (this.state.currentQuestion.Reference != null) {
    //   <TouchableOpacity activeOpacity={0.5} style={{flex: 1, marginTop: 10}} onPress={this.referenceButtonTapped.bind(this)}>
    //     <View style={{marginTop: 6, flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.white, borderWidth: 1, borderColor: colors.ButtonBlue, borderRadius: 2}}>
    //       <DynamicText style={{paddingVertical: 14, fontSize: 16, fontWeight: '700', letterSpacing: -0.39, color: colors.ButtonBlue}}>JRCALC Reference</DynamicText>
    //     </View>
    //   </TouchableOpacity>
    // }

    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView
          ref={ref => (this._scrollView = ref)}
          styles={{flex: 1}}
          contentContainerStyle={{padding: 10, paddingBottom: 60}}>
          <MaxWidthContainer>
            <View
              style={{
                marginBottom: 10,
                paddingHorizontal: 10,
                paddingTop: 15,
                paddingBottom: 20,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              {sectionLayout}

              {difficultyLayout}

              <View style={{marginTop: 10, marginBottom: 10}}>
                {webContentLayout}
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderColor: colors.Grey100,
                  paddingBottom: 5,
                }}>
                <DynamicText
                  style={{
                    fontSize: 15,
                    fontWeight: '500',
                    letterSpacing: -0.24,
                    color: colors.Grey600,
                  }}>
                  ANSWER
                </DynamicText>
                <DynamicText
                  style={{
                    fontSize: 15,
                    fontWeight: '500',
                    letterSpacing: -0.24,
                    color: colors.Grey600,
                  }}>
                  MARK
                </DynamicText>
              </View>
              {answerLayouts}

              {referenceButtonLayout}

              {helpTextLayout}
            </View>
          </MaxWidthContainer>
        </ScrollView>

        {bottomBarLayout}
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
