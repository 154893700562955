/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
  ScrollView,
  Image,
  Alert,
  LayoutAnimation,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import DynamicText from '../components/DynamicText';
import BookInfoSection from '../components/BookInfoSection';

import {containerWidths} from '../constants/containerWidths';

import DataController from '../classes/DataController';
import {colors} from '../classes/Colors';

import {
  getBookStatus,
  getBookProgressData,
  doesBookHaveQuiz,
  doesChapterHaveQuiz,
} from '../utils/books';
import MaxWidthContainer from '../components/MaxWidthContainer';

var _ = require('lodash');

const springAnimationProperties = {
  type: 'easeOut',
  springDamping: 1,
  property: 'opacity',
};

const animationConfig = {
  duration: 200,
  create: springAnimationProperties,
  update: springAnimationProperties,
  delete: springAnimationProperties,
};

type Props = {};

type State = {
  book: Object,
  expandedChapters: [],
  showBookmarks: boolean,
  startedReading: boolean,
};

export default class BookContentsScreen extends Component {
  static navigationOptions = ({navigation}) => {
    let showBookmarks = navigation.getParam('showBookmarks', false);

    let bookmarksIcon = (
      <Image
        style={{marginRight: 10, width: 44, height: 44}}
        source={require('../assets/Button_My_Bookmarks_1_Default.png')}
      />
    );

    if (showBookmarks) {
      bookmarksIcon = (
        <Image
          style={{marginRight: 10, width: 44, height: 44}}
          source={require('../assets/Button_My_Bookmarks_2_Active.png')}
        />
      );
    }

    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('searchButtonTapped')}>
          <Image
            style={{marginRight: 10, width: 44, height: 44}}
            source={require('../assets/Button_Search.png')}
          />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={navigation.getParam('bookmarksButtonTapped')}>
          {bookmarksIcon}
        </TouchableOpacity>
      </View>
    );

    return {
      title: 'Contents',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        book: this.props.navigation.getParam('book', {}),
        expandedChapters: [],
        showBookmarks: false,
        startedReading: false,
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
    }

    var that = this;

    if (Platform.OS !== 'web') {
      this.willFocusSubscription = this.props.navigation.addListener(
        'willFocus',
        payload => {
          that.updateReadingPosition();
        },
      );
    } else {
      this.updateReadingPosition();
    }

    setTimeout(() => {
      that.props.navigation.setParams({
        bookmarksButtonTapped: that.bookmarksButtonTapped.bind(that),
        searchButtonTapped: that.searchButtonTapped.bind(that),
      });
    }, 100);
  }

  componentWillUnmount() {
    if (this.willFocusSubscription) {
      this.willFocusSubscription.remove();
    }
  }

  async updateReadingPosition() {
    if (this.state.book) {
      let readingPosition = await DataController.getBookReadingPosition();

      if (readingPosition != null) {
        let bookID = this.state.book.ID;
        if (readingPosition[bookID] != null) {
          this.setState({startedReading: true});
        }
      }
    }
  }

  async readButtonTapped() {
    if (this.state.book) {
      try {
        let readingPosition = await DataController.getBookReadingPosition();

        let bookID = this.state.book.ID;
        let readingPositionForBook = readingPosition[this.state.book.ID];
        if (readingPositionForBook != null) {
          let selectedChapterNumber = readingPositionForBook.chapterNumber;
          let selectedSectionNumber = readingPositionForBook.sectionNumber;

          for (
            let chapterIndex = 0;
            chapterIndex < this.state.book.chapters.length;
            chapterIndex++
          ) {
            let chapter = this.state.book.chapters[chapterIndex];

            for (
              let sectionIndex = 0;
              sectionIndex < chapter.sections.length;
              sectionIndex++
            ) {
              let section = chapter.sections[sectionIndex];

              if (
                chapter.chapter === selectedChapterNumber &&
                section.section === selectedSectionNumber
              ) {
                this.goToSection(chapterIndex, sectionIndex);
              }
            }
          }
        } else {
          this.goToSection(0, 0);
        }
      } catch (error) {
        console.log(error);
        this.goToSection(0, 0);
      }
    }
  }

  bookmarksButtonTapped() {
    this.props.navigation.setParams({showBookmarks: !this.state.showBookmarks});
    this.setState({showBookmarks: !this.state.showBookmarks});
  }

  searchButtonTapped() {
    console.log('search');
    let bookID = null;
    if (this.state.book) {
      bookID = this.state.book.ID;
    }
    this.props.navigation.navigate(
      'Search',
      {
        quizSets: this.props.screenProps.quizSets,
        startingFilter: 'Book',
        bookToSearch: bookID,
      },
      this.state,
    );
  }

  chapterTapped(chapter: object) {
    var expandedChapters = this.state.expandedChapters;

    if (expandedChapters.includes(chapter.chapter)) {
      var index = expandedChapters.indexOf(chapter.chapter);
      if (index > -1) {
        expandedChapters.splice(index, 1);
      }
    } else {
      expandedChapters.push(chapter.chapter);
    }

    LayoutAnimation.configureNext(animationConfig);
    this.setState({expandedChapters: expandedChapters});
  }

  goToSection(chapterIndex: number, sectionIndex: number) {
    this.props.navigation.navigate(
      'BookReading',
      {
        book: this.state.book,
        chapterIndex: chapterIndex,
        sectionIndex: sectionIndex,
      },
      this.state,
    );
  }

  bookReadButtonTapped() {
    if (this.state.book) {
      let isBookRead = this.props.screenProps.bookReadingProgress.includes(
        this.state.book.ID,
      );
      let readText = !isBookRead ? 'read' : 'unread';

      if (Platform.OS === 'web') {
        if (confirm('Mark this book as ' + readText + '?') == true) {
          this.bookReadButtonConfirmed();
        }
      } else {
        Alert.alert(
          'Mark this book as ' + readText + '?',
          undefined,
          [
            {
              text: 'Yes, mark book as ' + readText,
              style: 'default',
              onPress: this.bookReadButtonConfirmed.bind(this),
            },
            {text: 'Cancel', style: 'cancel'},
          ],
          {cancelable: true},
        );
      }
    }
  }

  bookReadButtonConfirmed() {
    if (this.state.book) {
      this.props.screenProps.updateBookReadingProgress(
        this.state.book.ID,
        'book',
      );
    }
  }

  goToBlurbScreen = blurb => {
    this.props.navigation.navigate(
      'BookBlurb',
      {
        blurb,
      },
      this.state,
    );
  };

  render() {
    let book = this.state.book;

    if (book == null) {
      return (
        <View>
          <DynamicText>Book not found</DynamicText>
        </View>
      );
    }

    let chaptersLayout = [];

    // get chapters for each book
    if (book.chapters != null) {
      let previousPart = undefined;

      for (
        let chapterIndex = 0;
        chapterIndex < book.chapters.length;
        chapterIndex++
      ) {
        let chapter = book.chapters[chapterIndex];

        let sectionsForChapterLayout = [];
        let chapterContainsBookmarks = false;

        const chapterHasQuiz = doesChapterHaveQuiz(
          chapter,
          this.props.screenProps.quizSets,
        );

        for (
          let sectionIndex = 0;
          sectionIndex < chapter.sections.length;
          sectionIndex++
        ) {
          const section = chapter.sections[sectionIndex];
          const sectionHasQuiz = this.props.screenProps.quizSets.find(
            quizSet => quizSet.name === section?.relatedQuiz,
          );
          const sectionName = section?.name?.trim();
          const isSectionRead =
            this.props.screenProps.bookReadingProgress.includes(
              book.ID + '-' + chapter.chapter + '-' + section.section,
            );

          const bookmarkName = `${book.ID}-${chapter.chapter}-${section.section}`;
          const isBookmarked =
            this.props.screenProps.bookBookmarks.includes(bookmarkName);

          if (this.state.showBookmarks && isBookmarked === false) {
            continue;
          }

          if (isBookmarked) {
            chapterContainsBookmarks = true;
          }

          const bookmarkIcon = isBookmarked ? (
            <Image
              style={{marginHorizontal: 10, width: 24, height: 24}}
              source={require('../assets/Button_Bookmark_3Small.png')}
            />
          ) : (
            <View style={{marginHorizontal: 10, width: 24, height: 24}} />
          );

          sectionsForChapterLayout.push(
            <TouchableOpacity
              key={`section-${chapterIndex}-${sectionIndex}`}
              style={{flexDirection: 'row', alignItems: 'center'}}
              onPress={this.goToSection.bind(this, chapterIndex, sectionIndex)}>
              {bookmarkIcon}
              <View
                style={{
                  flex: 1,
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderBottomColor: '#dee2e6',
                  borderBottomWidth: 1,
                  paddingRight: 9,
                  paddingVertical: 16,
                }}>
                <View>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    {section?.readTime > 0 ? (
                      <DynamicText
                        style={{
                          fontSize: 12,
                          fontWeight: '600',
                          color: colors.Grey700,
                          marginRight: 6,
                        }}>
                        {section.readTime} min read
                      </DynamicText>
                    ) : null}
                    {Boolean(sectionHasQuiz) && (
                      <Image
                        source={require('../assets/books/quiz_icon.png')}
                        style={{height: 18, width: 18, marginRight: 6}}
                      />
                    )}
                    {isSectionRead ? (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            width: 2,
                            height: 2,
                            backgroundColor: colors.Grey700,
                            borderRadius: 1,
                            marginRight: 6,
                          }}
                        />
                        <DynamicText
                          style={{
                            fontSize: 12,
                            fontWeight: '600',
                            color: colors.Green500,
                          }}>
                          Read
                        </DynamicText>
                      </View>
                    ) : null}
                  </View>
                  <DynamicText
                    style={{
                      flex: 1,
                      fontSize: 16,
                      fontWeight: '500',
                      lineHeight: 24,
                      letterSpacing: -0.25,
                      color: '#212529',
                    }}>
                    {sectionName}
                  </DynamicText>
                </View>
                <Image
                  style={{width: 24, height: 24}}
                  source={require('../assets/Button_Disclosure_Right_Blue.png')}
                />
              </View>
            </TouchableOpacity>,
          );
        }

        let chapterExpandedHeight = 0;
        let expandedIconSource = require('../assets/Button_Disclosure_Blue_Down.png');
        if (
          this.state.expandedChapters.includes(chapter.chapter) ||
          this.state.showBookmarks
        ) {
          chapterExpandedHeight = null;
          expandedIconSource = require('../assets/Button_Disclosure_Up.png');
        }

        if (this.state.showBookmarks && chapterContainsBookmarks === false) {
          continue;
        }

        if (previousPart !== chapter.part && chapter.part) {
          if (chapter.part != undefined) {
            chaptersLayout.push(
              <View>
                <Text
                  style={{
                    fontSize: 17,
                    fontWeight: '700',
                    marginTop: 8,
                    marginBottom: 14,
                  }}>
                  {chapter.part}
                </Text>
              </View>,
            );
          }
        }

        previousPart = chapter.part;

        let isChapterRead = this.props.screenProps.bookReadingProgress.includes(
          book.ID + '-' + chapter.chapter,
        );

        chaptersLayout.push(
          <View
            key={`chapter-${chapterIndex}`}
            style={{
              marginBottom: 10,
              shadowOffset: {width: 0, height: 1},
              shadowRadius: 1,
              shadowColor: 'black',
              shadowOpacity: 0.2,
              backgroundColor: colors.white,
            }}>
            <TouchableOpacity
              style={{paddingLeft: 12, paddingVertical: 16}}
              onPress={this.chapterTapped.bind(this, chapter)}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <DynamicText
                  style={{
                    fontSize: 12,
                    fontWeight: '600',
                    lineHeight: 16,
                    letterSpacing: 0,
                    color: '#212529',
                    marginRight: 6,
                  }}>
                  Chapter {chapter.chapter}
                </DynamicText>
                {chapter.readTime && chapter.readTime > 0 != null ? (
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View
                      style={{
                        marginRight: 6,
                        width: 2,
                        height: 2,
                        backgroundColor: colors.Grey700,
                        borderRadius: 1,
                      }}
                    />
                    <DynamicText
                      style={{
                        fontSize: 12,
                        fontWeight: '600',
                        color: colors.Grey700,
                        marginRight: 6,
                      }}>
                      {chapter.readTime} min read
                    </DynamicText>
                    {Boolean(chapterHasQuiz) && (
                      <Image
                        source={require('../assets/books/quiz_icon.png')}
                        style={{height: 18, width: 18, marginRight: 6}}
                      />
                    )}
                  </View>
                ) : null}
                {isChapterRead ? (
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View
                      style={{
                        marginHorizontal: 6,
                        width: 2,
                        height: 2,
                        backgroundColor: colors.Grey700,
                        borderRadius: 1,
                      }}
                    />
                    <DynamicText
                      style={{
                        fontSize: 12,
                        fontWeight: '600',
                        color: colors.Green500,
                      }}>
                      Read
                    </DynamicText>
                  </View>
                ) : null}
              </View>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <DynamicText
                  style={{
                    flex: 1,
                    fontSize: 16,
                    fontWeight: '500',
                    lineHeight: 24,
                    letterSpacing: -0.25,
                    color: '#212529',
                  }}>
                  {chapter.name}
                </DynamicText>
                <Image
                  style={{width: 44, height: 44}}
                  source={expandedIconSource}
                />
              </View>
            </TouchableOpacity>
            <View style={{height: chapterExpandedHeight, overflow: 'hidden'}}>
              <View style={{borderTopWidth: 1, borderTopColor: '#dee2e6'}}>
                {sectionsForChapterLayout}
              </View>
            </View>
          </View>,
        );
      }
    }

    let readButtonText = this.state.startedReading
      ? 'Continue reading'
      : 'Start reading';

    let isBookRead = this.props.screenProps.bookReadingProgress.includes(
      book.ID,
    );
    let readBookText = 'Finished reading this book?';
    if (isBookRead) {
      readBookText = "You've read this book";
    }

    const bookStatus = getBookStatus(
      this.state.book,
      this.props.screenProps.bookReadingProgress,
    );
    const progressData = getBookProgressData(
      this.state.book,
      this.props.screenProps.bookReadingProgress,
    );
    const bookHasQuiz = doesBookHaveQuiz(
      this.state.book,
      this.props.screenProps.quizSets,
    );

    return (
      <ScreenComponent style={[styles.container]}>
        <ScrollView
          styles={{flex: 1}}
          contentContainerStyle={styles.scrollContainer}>
          <MaxWidthContainer>
            <BookInfoSection
              book={this.state.book}
              bookStatus={bookStatus}
              progressData={progressData}
              readButtonText={readButtonText}
              onReadButtonPress={this.readButtonTapped.bind(this)}
              blurb={this.state.book?.blurb?.blurb}
              onReadMorePress={() =>
                this.goToBlurbScreen(this.state.book?.blurb)
              }
              bookHasQuiz={bookHasQuiz}
            />
            {chaptersLayout}
            <View style={styles.buttonContainer}>
              <DynamicText
                style={{
                  textAlign: 'center',
                  fontSize: 17,
                  fontWeight: '700',
                  color: colors.Grey900,
                }}>
                {readBookText}
              </DynamicText>
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={this.bookReadButtonTapped.bind(this)}>
                <View style={styles.button}>
                  <DynamicText
                    style={{
                      fontSize: 16,
                      fontWeight: '700',
                      letterSpacing: -0.39,
                      color: colors.ButtonBlue,
                    }}>
                    {isBookRead ? 'Mark as unread' : 'Mark as read'}
                  </DynamicText>
                </View>
              </TouchableOpacity>
            </View>
          </MaxWidthContainer>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
  scrollContainer: {
    paddingVertical: 10,
    width: '100%',
    alignSelf: 'center',
  },
  buttonContainer: {
    paddingHorizontal: 16,
    paddingVertical: 20,
    marginBottom: 10,
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
  },
  button: {
    marginTop: 15,
    paddingVertical: 13,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    borderColor: colors.ButtonBlue,
    borderWidth: 1,
  },
});
