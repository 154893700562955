'use strict';

import React, {useState, useEffect} from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import FastImage from './FastImage';

import {colors} from '../classes/Colors';
import DataController from '../classes/DataController';
import {getIconSource} from '../utils/icons';

const FeaturedCard = ({featuredCard, onCardPress, isLast}) => {
  const [imageURL, setImageURL] = useState('');

  useEffect(() => {
    const fetchImageURL = async () => {
      if (!featuredCard?.Image) {
        return;
      }
      const imageURL = await DataController.getFeaturedCardImagePath(
        featuredCard,
        featuredCard.Image[0].filename,
      );
      setImageURL(imageURL);
    };

    fetchImageURL();
  }, [featuredCard?.Image?.[0]?.filename]);

  const iconSource = getIconSource(featuredCard?.Icon);

  const imageLayout = imageURL ? (
    <FastImage
      style={styles.image}
      resizeMode="cover"
      source={{uri: imageURL}}
    />
  ) : (
    <FastImage
      style={styles.placeholderImage}
      resizeMode="contain"
      source={require('../assets/Image_Loading_Placeholder.png')}
    />
  );

  return (
    <TouchableOpacity
      key={featuredCard.id}
      activeOpacity={0.7}
      style={[{width: 300, marginBottom: 20}, !isLast && {marginRight: 16}]}
      onPress={onCardPress}>
      <View style={styles.container}>
        {imageLayout}
        <View style={styles.contentWrapper}>
          <View style={styles.content}>
            {(iconSource || featuredCard.Caption) && (
              <View style={styles.iconContainer}>
                {iconSource && (
                  <FastImage style={styles.icon} source={iconSource} />
                )}
                <Text style={styles.caption}>
                  {featuredCard.Caption.toUpperCase()}
                </Text>
              </View>
            )}
            <Text style={styles.title}>{featuredCard.Title}</Text>
            <Text style={styles.subtitle}>{featuredCard.Subtitle}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
  },
  image: {
    height: 180,
  },
  placeholderImage: {
    width: '100%',
    height: 180,
    backgroundColor: '#EFF4F6',
  },
  contentWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 12,
  },
  content: {
    flex: 1,
    paddingTop: 10,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  icon: {
    marginRight: 6,
    width: 24,
    height: 24,
  },
  caption: {
    fontSize: 11,
    fontWeight: '700',
    color: colors.Grey800,
  },
  title: {
    marginBottom: 8,
    fontSize: 18,
    fontWeight: '700',
    color: colors.darkGrey,
  },
  subtitle: {
    marginBottom: 16,
    fontSize: 13,
    fontWeight: '600',
    color: colors.Grey800,
  },
  buttonWrapper: {
    justifyContent: 'flex-end',
    marginBottom: 16,
  },
  dateText: {
    marginTop: 8,
    color: colors.Grey800,
    fontSize: 11,
    fontWeight: '400',
  },
});

export default FeaturedCard;
