/**
 * PPP
 * @flow
 */
'use strict';
import React, {useEffect, useState} from 'react';

import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Platform,
  Dimensions,
} from 'react-native';
import moment from 'moment';

import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';
import DataController from '../classes/DataController';

import {containerWidths} from '../constants/containerWidths';
import ScreenComponent from '../components/ScreenComponent';
import VimeoPlayer from '../components/VimeoPlayer';
import HTMLRenderer from '../components/HTMLRenderer';
import {AcknowledgmentButton} from '../components/PSIRF/Acknowledgment';
import VideosRail from '../components/VideosRail';
import MaxWidthContainer from '../components/MaxWidthContainer';

const VideoScreen = ({navigation, screenProps}) => {
  const [video, setVideo] = useState(null);
  const isTablet = LayoutController.isTablet();
  const tabletVideoSize =
    Dimensions.get('window').width >= containerWidths.videoHeroContainer
      ? containerWidths.videoHeroContainer
      : '100%';

  useEffect(() => {
    const initialize = async () => {
      try {
        const video = navigation.getParam('video', null);
        const from = navigation.getParam('from', 'unknown');
        firebase.analytics().logEvent('video_started', {
          video: video.title,
          from,
        });
      } catch (error) {
        console.log(error);
      }
    };

    initialize();
  }, [navigation]);

  useEffect(() => {
    const video = navigation.getParam('video', null);
    setVideo(video);
  }, [navigation]);

  if (!video) {
    return null;
  }

  const allVideoHistory = screenProps.videoHistory;
  const currentVideoHistory = allVideoHistory.find(
    vid => vid.videoId === video.id,
  );
  const relatedVideos = screenProps.videos
    .filter(aVideo => video?.RelatedVideos?.includes(aVideo.RecordId))
    // If we dont filter the current video the user can get stuck in a navigation loop
    .filter(aVideo => aVideo.id !== video.id);

  const quizsetForVideo = screenProps.quizSets.find(
    quizset => quizset.name === video.QuizLink,
  );

  const quizOnPressHandler = () => {
    navigation.navigate(
      'QuizOverview',
      {
        quizSet: quizsetForVideo,
        from: 'Video',
        id: video.id,
      },
      screenProps,
    );
  };

  const videoPressHandler = video => {
    setVideo(video);
  };

  return (
    <ScreenComponent style={styles.container}>
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContent}>
        <View style={[styles.videoWrapper, isTablet && {padding: 16}]}>
          <VimeoPlayer
            video={video}
            updateVideoHistory={screenProps.updateVideoHistory}
            marginTop={0}
            width={tabletVideoSize}
          />
        </View>
        <MaxWidthContainer>
          <View style={[styles.videoInfoWrapper]}>
            <View style={styles.vidInfoContainer}>
              <Text style={styles.releaseDate}>
                {moment(video.ReleaseDate).format('DD MMM YYYY')}
              </Text>
              <View style={styles.dotSeparator} />
              <View
                style={[
                  styles.trustBadge,
                  {
                    backgroundColor: DataController.getColorForTrust(
                      video.Trust,
                    ),
                  },
                ]}>
                <Text style={styles.trustText}>
                  {DataController.getTrustDisplayName(video.Trust)}
                </Text>
              </View>
              {currentVideoHistory?.secondsWatched >= 30 && (
                <>
                  <View style={styles.dotSeparator} />
                  <Text style={styles.seenText}>Seen</Text>
                </>
              )}
            </View>
            <Text style={styles.title}>{video.Title}</Text>
            <HTMLRenderer
              html={video.Description}
              tagStyles={{
                p: {
                  fontSize: 17,
                  color: colors.Grey900,
                  lineHeight: 25,
                },
              }}
            />
            {video.SupportsAck && (
              <View style={styles.ackButtonContainer}>
                <AcknowledgmentButton
                  user={screenProps.user}
                  title={video.Title}
                  contentId={video.id}
                  type={'video'}
                />
              </View>
            )}
            {quizsetForVideo && (
              <View>
                <View style={styles.buttonContainer}>
                  <Text style={styles.buttonTitle}>
                    Test your knowledge on what you've learnt
                  </Text>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={quizOnPressHandler}>
                    <Text style={styles.buttonText}>Take Quiz</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )}
          </View>
          {relatedVideos.length > 0 && (
            <View style={styles.relatedVideosContainer}>
              <VideosRail
                title="Related Videos"
                videos={relatedVideos}
                videoOnPress={videoPressHandler}
                videoHistory={allVideoHistory}
              />
            </View>
          )}
        </MaxWidthContainer>
      </ScrollView>
    </ScreenComponent>
  );
};

VideoScreen.navigationOptions = ({navigation}) => ({
  title: navigation.getParam('title', ''),
  headerStyle: {
    backgroundColor: colors.NavBar,
  },
  headerTintColor: colors.white,
  headerTitleStyle: {
    fontFamily: 'InterUI-Regular',
    fontSize: 17,
    fontWeight: '600',
    letterSpacing: -0.41,
    color: colors.white,
  },
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  scrollView: {
    flex: 1,
  },
  scrollViewContent: {
    backgroundColor: colors.white,
  },
  videoWrapper: {
    alignSelf: 'center',
  },
  videoInfoWrapper: {
    flex: 1,
    alignSelf: 'stretch',
    paddingVertical: 16,
  },
  vidInfoContainer: {
    marginTop: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  releaseDate: {
    color: colors.Grey700,
    fontWeight: '500',
    fontSize: 12,
  },
  dotSeparator: {
    backgroundColor: 'rgb(121, 139, 147)',
    borderRadius: 1,
    height: 2,
    marginHorizontal: 6,
    width: 2,
  },
  trustBadge: {
    justifyContent: 'center',
    paddingHorizontal: 6,
    height: 16,
    borderRadius: 2,
  },
  trustText: {
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.1,
    color: colors.white,
  },
  seenText: {
    color: colors.Grey700,
    fontWeight: '500',
  },
  title: {
    marginTop: 12,
    fontSize: 22,
    fontWeight: '600',
    color: colors.Grey900,
  },
  ackButtonContainer: {
    marginTop: 30,
  },
  buttonContainer: {
    marginTop: 40,
    maxWidth: 342,
    alignSelf: 'center',
  },
  buttonTitle: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    marginBottom: 12,
  },
  button: {
    backgroundColor: colors.white,
    padding: 14,
    borderRadius: 7,
    maxWidth: 336,
    borderColor: colors.ButtonBlue,
    borderWidth: 1,
  },
  buttonText: {
    color: colors.ButtonBlue,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
  relatedVideosContainer: {
    alignSelf: 'center',
    width: '100%',
    marginTop: 40,
    marginBottom: 10,
  },
});

export default VideoScreen;
