import React, {useEffect, useState, useRef} from 'react';
import {View, ActivityIndicator, StyleSheet, Dimensions} from 'react-native';
import DataController from '../classes/DataController';

export default function VimeoPlayer(props) {
  const [videoHistory, setVideoHistory] = useState({
    history: [],
    initialized: false,
  });

  const videoId =
    props.video.VideoID.split('/')[0] +
    '?h=' +
    props.video.VideoID.split('/')[1];

  const indexOfVideoInHistory = videoHistory.history.findIndex(
    vid => vid.videoId === props.video.id,
  );

  const secondCounter = useRef(0);
  const intervalFirstRunDone = useRef(false);

  useEffect(() => {
    const getVideoProgress = async () => {
      const history = await DataController.getVideoHistory();
      setVideoHistory({history: history, initialized: true});
    };

    getVideoProgress();
  }, []);

  useEffect(() => {
    if (
      videoHistory.initialized &&
      videoHistory.history.length &&
      indexOfVideoInHistory !== -1
    ) {
      secondCounter.current =
        videoHistory.history[indexOfVideoInHistory].secondsWatched;
    }

    if (videoHistory.initialized) {
      const iframe = document.querySelector('iframe');
      const player = new Vimeo.Player(iframe);

      if (indexOfVideoInHistory !== -1) {
        player.setCurrentTime(
          videoHistory.history[indexOfVideoInHistory].currentTime,
        );
      }

      player.on('timeupdate', data => {
        props.updateVideoHistory(
          props.video.id,
          Math.floor(data.seconds),
          secondCounter.current,
        );
      });

      const intervalId = setInterval(async () => {
        const paused = await player.getPaused();
        if (!paused) {
          if (intervalFirstRunDone.current) {
            secondCounter.current = secondCounter.current + 1;
          }
          intervalFirstRunDone.current = true;
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [videoHistory.initialized, props.video.id]);

  const source = `https://player.vimeo.com/video/${videoId}&?portrait=0&autoplay=0`;

  const videoHtml = `
    <html>
      <head>
        <meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>
        <style>
        .video-frame {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        </style>
      </head>
      <iframe src=${source} class="video-frame" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen />
    </html>`;

  const videoContainerWidth = props.width ? props.width : 700;

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: videoContainerWidth,
        aspectRatio: 16 / 9,
      }}>
      {!videoHistory.initialized ? (
        <ActivityIndicator />
      ) : (
        <div dangerouslySetInnerHTML={{__html: videoHtml}} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({});
