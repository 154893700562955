/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  TextInput,
  KeyboardAvoidingView,
  Keyboard,
  Linking,
  Dimensions,
  Alert,
  Switch,
} from 'react-native';

import Tts from 'react-native-tts';
import Hyperlink from 'react-native-hyperlink';
import AsyncStorage from '@react-native-async-storage/async-storage';

import AMWebView from '../components/AMWebView';
import ScreenComponent from '../components/ScreenComponent';
import ProgressView from '../components/ProgressView';
import Popover from '../components/Popover';
import PromoQuizButton from '../components/Promotion/PromoQuizButton';
import MaxWidthContainer from '../components/MaxWidthContainer';
import DynamicText from '../components/DynamicText';
import AMImageViewer from '../components/AMImageViewer';

import DataController from '../classes/DataController';
import GuidelinesController from '../classes/GuidelinesController';
import CommunicationsController from '../classes/CommunicationsController';
import PlatformController from '../classes/PlatformController';
import LayoutController from '../classes/LayoutController';
import {colors} from '../classes/Colors';
import {firebase} from '../classes/Firebase';

var _ = require('lodash');
const timer = require('react-native-timer');

type Props = {};

type State = {
  quiz: Object,
  quizSet: Object,
  questions: any[],
  currentQuestion: Object,
  currentQuestionNumber: number,
  questionAnswers: [],
  questionImageURL: string,
  questionHasBeenAnswered: boolean,
  isFirstQuestionLeftToAnswer: boolean,
  isLastQuestionLeftToAnswer: boolean,
  selectedAnswers: [],
  questionNumbersAnswered: [],
  questionNumbersViewed: [],
  showProgressBar: boolean,
  showTextToSpeech: boolean,
  confirmAnswers: boolean,
  webviewHeight: number,
  correctCount: number,
  incorrectCount: number,
  secondsRemaining: number,
  extendedAnswerText: string,
  extendedAnswerPointerEvents: string,
  extendedAnswerHeight: number,
  checkingExtendedAnswer: boolean,
  shouldShowReferenceButton: boolean,
  imageViewerVisible: boolean,
  retakingIncorrectQuestions: boolean,
  startDate: Date,
  highlightedAnswerForCurrentQuestion: number,
  questionNumberConfirmAnswersWasEnabled: number,
  audioText: string,
  audioIsPlaying: boolean,
  speechHistory: [],
  lastSpokenWordIndex: number,
};

// TODO: use in the future to replace duplicate buttons
const Button = ({children, onPress, activeOpacity, disabled}) => (
  <TouchableOpacity
    activeOpacity={activeOpacity}
    disabled={disabled}
    style={styles.button}
    onPress={onPress}>
    <View style={styles.buttonBackground}>
      <Text style={styles.buttonText}>{children}</Text>
    </View>
  </TouchableOpacity>
);

export default class QuizScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var questionNumbersAnswered = navigation.getParam(
      'questionNumbersAnswered',
      [],
    );
    var optionsPopoverVisible = navigation.getParam(
      'optionsPopoverVisible',
      false,
    );
    var accessibilityOptionsPopoverVisible = navigation.getParam(
      'accessibilityOptionsPopoverVisible',
      false,
    );

    let audioIsPlaying = navigation.getParam('audioIsPlaying', false);

    var showProgressBar = navigation.getParam('showProgressBar', true);

    var showTextToSpeech = navigation.getParam('showTextToSpeech', true);

    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('endQuizButtonTapped')}>
          <DynamicText style={{color: '#FFF', marginLeft: 10, fontSize: 17}}>
            End Quiz
          </DynamicText>
        </TouchableOpacity>
      </View>
    );

    var progressButtonText = 'Show progress bar';

    if (showProgressBar) {
      progressButtonText = 'Hide progress bar';
    }

    var confirmAnswers = navigation.getParam('confirmAnswers', true);

    var rightButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        {showTextToSpeech && (
          <TouchableOpacity
            onPress={navigation.getParam('audioMenuButtonTapped')}>
            <Image
              style={{
                marginRight: 10,
                width: 16,
                height: 16,
                resizeMode: 'contain',
              }}
              source={
                audioIsPlaying
                  ? require('../assets/Button_Stop_White.png')
                  : require('../assets/Button_Play_White.png')
              }
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={navigation.getParam('accessibilityButtonTapped')}>
          <Popover
            visible={accessibilityOptionsPopoverVisible}
            position={'bottom'}
            onRequestClose={navigation.getParam(
              'closeAccessibilityOptionsPopover',
            )}
            onClose={() => console.log('onClose')}
            content={
              <View
                style={{
                  alignItems: 'stretch',
                  flex: 1,
                  backgroundColor: colors.white,
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.Grey200,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 10,
                  }}
                  onPress={navigation.getParam('toggleTextToSpeech')}>
                  <DynamicText
                    style={{
                      fontSize: 17,
                      fontWeight: '600',
                      textAlign: 'left',
                      letterSpacing: -0.44,
                      color: 'black',
                    }}>
                    Show text-to-speech
                  </DynamicText>
                  <Switch
                    style={{marginLeft: 14}}
                    value={showTextToSpeech}
                    onValueChange={navigation.getParam('toggleTextToSpeech')}
                  />
                </View>
              </View>
            }>
            <Image
              style={{
                marginRight: 10,
                width: 48,
                height: 48,
              }}
              source={require('../assets/Button_Accessibility.png')}
            />
          </Popover>
        </TouchableOpacity>
        <TouchableOpacity onPress={navigation.getParam('quizMenuButtonTapped')}>
          <Popover
            visible={optionsPopoverVisible}
            position={'bottom'}
            onRequestClose={navigation.getParam('closeOptionsPopover')}
            onClose={() => console.log('onClose')}
            content={
              <View
                style={{
                  alignItems: 'stretch',
                  flex: 1,
                  backgroundColor: colors.white,
                  borderWidth: 1,
                  borderRadius: 6,
                  borderColor: colors.Grey200,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 10,
                  }}
                  onPress={navigation.getParam('toggleProgressBar')}>
                  <DynamicText
                    style={{
                      fontSize: 17,
                      fontWeight: '600',
                      textAlign: 'left',
                      letterSpacing: -0.44,
                      color: 'black',
                    }}>
                    Show progress bar
                  </DynamicText>
                  <Switch
                    style={{marginLeft: 14}}
                    value={showProgressBar}
                    onValueChange={navigation.getParam('toggleProgressBar')}
                  />
                </View>
                <View
                  style={{
                    height: 1,
                    backgroundColor: colors.Grey200,
                    width: '100%',
                  }}
                />
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 10,
                  }}
                  onPress={navigation.getParam('toggleConfirmAnswers')}>
                  <DynamicText
                    style={{
                      fontSize: 17,
                      fontWeight: '600',
                      textAlign: 'left',
                      letterSpacing: -0.44,
                      color: 'black',
                    }}>
                    Confirm answers
                  </DynamicText>
                  <Switch
                    style={{marginLeft: 14}}
                    value={confirmAnswers}
                    onValueChange={navigation.getParam('toggleConfirmAnswers')}
                  />
                </View>
                <View
                  style={{
                    height: 1,
                    backgroundColor: colors.Grey200,
                    width: '100%',
                  }}
                />
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: 10,
                  }}
                  onPress={navigation.getParam('sendFeedback')}>
                  <DynamicText
                    style={{
                      marginRight: 12,
                      fontSize: 17,
                      fontWeight: '600',
                      textAlign: 'left',
                      letterSpacing: -0.44,
                      color: 'black',
                    }}>
                    Send feedback on question
                  </DynamicText>
                </TouchableOpacity>
              </View>
            }>
            <Image
              style={{marginRight: 10, width: 32, height: 32}}
              source={require('../assets/Button_More.png')}
            />
          </Popover>
        </TouchableOpacity>
      </View>
    );

    var options = {
      title: navigation.getParam('titleText', ''),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerBackTitle: 'Back',
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerRight: rightButtonsLayout,
    };

    if (questionNumbersAnswered.length > 0) {
      options.headerLeft = leftButtonsLayout;
    }

    return options;
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      var isLastQuestionLeftToAnswer = false;
      var questions = this.props.navigation.getParam('questions', []);

      if (questions.length === 1) {
        isLastQuestionLeftToAnswer = true;
      }

      var currentQuestion = this.props.navigation.getParam(
        'currentQuestion',
        null,
      );

      if (currentQuestion == null) {
        currentQuestion = questions[0];
      }

      this.state = {
        quiz: this.props.navigation.getParam('quiz', {}),
        quizSet: this.props.navigation.getParam('quizSet', {}),
        questions: questions,
        currentQuestion: currentQuestion,
        currentQuestionNumber: this.props.navigation.getParam(
          'currentQuestionNumber',
          1,
        ),
        questionAnswers: [],
        questionImageURL: '',
        questionHasBeenAnswered: false,
        isFirstQuestionLeftToAnswer: true,
        isLastQuestionLeftToAnswer: isLastQuestionLeftToAnswer,
        selectedAnswers: [],
        questionNumbersAnswered: this.props.navigation.getParam(
          'questionNumbersAnswered',
          [],
        ),
        questionNumbersViewed: this.props.navigation.getParam(
          'questionNumbersViewed',
          [],
        ),
        showProgressBar: true,
        showTextToSpeech: true,
        confirmAnswers: true,
        webviewHeight: 1,
        correctCount: this.props.navigation.getParam('correctCount', 0),
        incorrectCount: this.props.navigation.getParam('incorrectCount', 0),
        secondsRemaining: 300,
        extendedAnswerText: '',
        extendedAnswerPointerEvents: 'none',
        extendedAnswerHeight: 100,
        checkingExtendedAnswer: true,
        webviewCounter: 1,
        shouldShowReferenceButton: false,
        imageViewerVisible: false,
        retakingIncorrectQuestions: this.props.navigation.getParam(
          'retakingIncorrectQuestions',
          false,
        ),
        startDate: new Date(),
        highlightedAnswerForCurrentQuestion: -1,
        questionNumberConfirmAnswersWasEnabled: -1,
        audioText: '',
        audioIsPlaying: false,
        speechHistory: [],
        lastSpokenWordIndex: 0,
      };
    } else {
      this.state = this.props.initialState;
    }

    if (Platform.OS === 'web') {
      speechSynthesis.addEventListener('start', this.onStart);
      speechSynthesis.addEventListener('end', this.onEnd);
      speechSynthesis.addEventListener('error', this.onError);
      speechSynthesis.addEventListener('pause', this.onPause);
      speechSynthesis.addEventListener('resume', this.onResume);
    } else {
      Tts.addEventListener('tts-start', this.onTTSStart);
      Tts.addEventListener('tts-finish', this.onTTSFinish);
      Tts.addEventListener('tts-cancel', this.onTTSCancel);
      Tts.addEventListener('tts-progress', this.onTTSProgress);
    }
  }

  async componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.state.quiz.quizMode === '5 Minutes') {
        timer.setInterval('5minuteTimer', this.updateTimer.bind(this), 1000);
      }
      this.loadQuestion();

      try {
        const isPGD = this.state.quiz.quizMode === 'PGD';
        const from = this.props.navigation.getParam('from', 'unknown');
        await firebase.analytics().logEvent('quiz_started', {
          quizSet: this.state.quizSet.name,
          mode: this.state.quiz.quizMode,
          from,
        });

        if (isPGD) {
          await firebase.analytics().logEvent('pgd_started', {
            quizSet: this.state.quizSet.name,
            mode: this.state.quiz.quizMode,
            from,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    var that = this;

    setTimeout(async () => {
      that.props.navigation.setParams({
        endQuizButtonTapped: that.endQuizButtonTapped.bind(that),
        quizMenuButtonTapped: that.quizMenuButtonTapped.bind(that),
        closeOptionsPopover: that.closeOptionsPopover.bind(that),
        accessibilityButtonTapped: that.accessibilityButtonTapped.bind(that),
        audioMenuButtonTapped: that.audioMenuButtonTapped.bind(that),
        closeAccessibilityOptionsPopover:
          that.closeAccessibilityOptionsPopover.bind(that),
        sendFeedback: that.sendFeedback.bind(that),
        toggleProgressBar: that.toggleProgressBar.bind(that),
        toggleTextToSpeech: that.toggleTextToSpeech.bind(that),
        toggleConfirmAnswers: that.toggleConfirmAnswers.bind(that),
      });

      var showQuizScreenProgressBar = await AsyncStorage.getItem(
        'showQuizScreenProgressBar',
      );
      if (showQuizScreenProgressBar != null) {
        var showProgressBar = JSON.parse(showQuizScreenProgressBar);
        that.setState({showProgressBar: showProgressBar});
        that.props.navigation.setParams({showProgressBar: showProgressBar});
      }

      var showTextToSpeechView = await AsyncStorage.getItem('showTextToSpeech');
      if (showTextToSpeechView != null) {
        let showTextToSpeech = JSON.parse(showTextToSpeechView);
        that.setState({showTextToSpeech: showTextToSpeech});
        that.props.navigation.setParams({showTextToSpeech: showTextToSpeech});
      }

      var showConfirmAnswers = await AsyncStorage.getItem('showConfirmAnswers');
      if (showConfirmAnswers != null) {
        var confirmAnswers = JSON.parse(showConfirmAnswers);
        try {
          firebase
            .analytics()
            .setUserProperty(
              'confirmAnswersEnabled',
              confirmAnswers.toString(),
            );
        } catch (error) {
          console.log(
            'Failed to update confirmAnswersEnabled user prop in analytics',
          );
        }
        that.setState({confirmAnswers: confirmAnswers});
        that.props.navigation.setParams({confirmAnswers: confirmAnswers});
      } else {
        try {
          firebase.analytics().setUserProperty('confirmAnswersEnabled', 'true');
        } catch (error) {
          console.log(
            'Failed to update confirmAnswersEnabled user prop in analytics',
          );
        }
      }
    }, 100);
  }

  componentWillUnmount() {
    timer.clearInterval('5minuteTimer');

    if (Platform.OS === 'web') {
      speechSynthesis.removeEventListener('start', this.onStart);
      speechSynthesis.removeEventListener('end', this.onEnd);
      speechSynthesis.removeEventListener('error', this.onError);
      speechSynthesis.removeEventListener('pause', this.onPause);
      speechSynthesis.removeEventListener('resume', this.onResume);
    } else {
      Tts.removeEventListener('tts-start', this.onTTSStart);
      Tts.removeEventListener('tts-progress', this.onTTSProgress);
      Tts.removeEventListener('tts-finish', this.onTTSFinish);
      Tts.removeEventListener('tts-cancel', this.onTTSCancel);
    }
  }

  onStart = () => {
    console.log('Speech started');
  };

  onEnd = () => {
    console.log('Speech ended');
    this.setState({audioIsPlaying: false});
    this.props.navigation.setParams({
      audioIsPlaying: false,
    });
  };

  onError = event => {
    console.error('Speech error:', event.error);
    this.setState({audioIsPlaying: false});
    this.props.navigation.setParams({
      audioIsPlaying: false,
    });
  };

  onPause = () => {
    console.log('Speech paused');
  };

  onResume = () => {
    console.log('Speech resumed');
  };

  onTTSStart = event => {
    console.log('TTS started:', event);
  };

  onTTSProgress = event => {
    console.log('TTS progress:', event);

    this.state.lastSpokenWordIndex = event.start;
  };

  onTTSCancel = event => {
    console.log('TTS canceled:', event);

    // add new text spoken
    const newStr = this.state.speechHistory[
      this.state.speechHistory.length - 1
    ].substring(this.state.lastSpokenWordIndex);
    this.state.speechHistory = [...this.state.speechHistory, newStr];

    this.setState({audioIsPlaying: false});
    this.props.navigation.setParams({
      audioIsPlaying: false,
    });
  };

  onTTSFinish = event => {
    console.log('TTS finished:', event);

    this.state.speechHistory = this.state.speechHistory.slice(0, 1);
    this.setState({audioIsPlaying: false});
    this.props.navigation.setParams({
      audioIsPlaying: false,
    });
  };

  updateTimer() {
    let secondsRemaining = this.state.secondsRemaining - 1;

    if (secondsRemaining === 0) {
      timer.clearInterval('5minuteTimer');
    }

    var timeLeftString =
      Math.floor(secondsRemaining / 60) +
      ':' +
      ('0' + Math.floor(secondsRemaining % 60)).slice(-2);

    var titleText =
      'Q ' + this.state.currentQuestionNumber + ' | ' + timeLeftString;
    this.props.navigation.setParams({titleText: titleText});

    this.setState({secondsRemaining: secondsRemaining});
    // console.log('seconds secondsRemaining: ' + secondsRemaining);
  }

  extendedAnswerTextDidChange(extendedAnswerText: string) {
    this.setState({extendedAnswerText: extendedAnswerText});
  }

  focusEditor() {
    // console.log('focussing');
    this.setState({extendedAnswerPointerEvents: 'auto'});
    this._extendedAnswerTextInput.focus();
    setTimeout(() => {
      if (this._extendedAnswerScrollView != null) {
        this._extendedAnswerScrollView.scrollToEnd({animated: true});
      }
    }, 100);
  }

  blurEditor() {
    this.setState({extendedAnswerPointerEvents: 'none'});
    this._extendedAnswerTextInput.blur();
  }

  endQuizButtonTapped() {
    try {
      var endDate = new Date();
      var seconds = (endDate.getTime() - this.state.startDate.getTime()) / 1000;
      var from = this.props.navigation.getParam('from', 'unknown');

      firebase.analytics().logEvent('quiz_quit', {
        quizSet: this.state.quizSet.name,
        mode: this.state.quiz.quizMode,
        seconds: seconds,
        from: from,
        totalQuestions: this.state.questions.length,
        questionsAnswered: this.state.questionNumbersAnswered.length,
      });
    } catch (error) {
      console.log(error);
    }

    this.finishQuiz();
  }

  finishQuizButtonTapped = quizCallback => {
    try {
      var endDate = new Date();
      var seconds = (endDate.getTime() - this.state.startDate.getTime()) / 1000;
      var from = this.props.navigation.getParam('from', 'unknown');

      firebase.analytics().logEvent('quiz_finished', {
        quizSet: this.state.quizSet.name,
        mode: this.state.quiz.quizMode,
        seconds: seconds,
        from: from,
        totalQuestions: this.state.questions.length,
        questionsAnswered: this.state.questionNumbersAnswered.length,
      });
    } catch (error) {
      console.log(error);
    }

    this.finishQuiz(quizCallback);
  };

  quizMenuButtonTapped() {
    this.props.navigation.setParams({optionsPopoverVisible: true});
  }

  closeOptionsPopover() {
    this.props.navigation.setParams({optionsPopoverVisible: false});
  }

  accessibilityButtonTapped() {
    this.props.navigation.setParams({accessibilityOptionsPopoverVisible: true});
  }

  audioMenuButtonTapped() {
    // this.props.navigation.setParams({accessibilityOptionsPopoverVisible: true});
    this.handleAudioButton();
  }

  closeAccessibilityOptionsPopover() {
    this.props.navigation.setParams({
      accessibilityOptionsPopoverVisible: false,
    });
  }

  sendFeedback() {
    console.log('sending feedback');
    this.closeOptionsPopover();

    var emailAddress = 'appfeedback@class.co.uk';
    var ccAddress = 'cpdfeedback@aimermedia.com';
    var subject =
      'PPP Question Feedback: ' +
      this.state.quizSet.name +
      '-' +
      this.state.currentQuestion.PackName +
      '-' +
      this.state.currentQuestion.QuestionID;

    var headerText =
      '\nIf you have any feedback specific to this question, please include details on the nature of your query below:\n\n';

    var answersString = '';

    for (let answer of this.state.questionAnswers) {
      answersString = answersString + answer.Answer + '\n';
    }

    var body =
      headerText +
      '\n\nQuiz Name: ' +
      this.state.quizSet.title +
      '\n' +
      'QuestionID: ' +
      this.state.currentQuestion.PackName +
      '-' +
      this.state.currentQuestion.QuestionID +
      '\n\n' +
      'Question: ' +
      this.state.currentQuestion.Question +
      '\n\n' +
      'Answers: \n' +
      answersString;

    if (Platform.OS === 'android') {
      body = body.replace(/\n/g, '<br>');
    }

    CommunicationsController.sendEmail(
      [emailAddress],
      [ccAddress],
      [],
      subject,
      body,
      [],
    );
  }

  toggleTextToSpeech() {
    this.setState(
      {showTextToSpeech: !this.state.showTextToSpeech},
      function () {
        this.props.navigation.setParams({
          showTextToSpeech: this.state.showTextToSpeech,
        });
        this.closeAccessibilityOptionsPopover();
        try {
          AsyncStorage.setItem(
            'showTextToSpeech',
            JSON.stringify(this.state.showTextToSpeech),
          );
        } catch (error) {
          // Error saving data
          console.log('error saving: ' + error);
        }
      },
    );
  }

  toggleProgressBar() {
    this.setState({showProgressBar: !this.state.showProgressBar}, function () {
      this.props.navigation.setParams({
        showProgressBar: this.state.showProgressBar,
      });
      this.closeOptionsPopover();
      try {
        AsyncStorage.setItem(
          'showQuizScreenProgressBar',
          JSON.stringify(this.state.showProgressBar),
        );
      } catch (error) {
        // Error saving data
        console.log('error saving: ' + error);
      }
    });
  }

  toggleConfirmAnswers() {
    if (!this.state.confirmAnswers) {
      this.setState({
        questionNumberConfirmAnswersWasEnabled:
          this.state.currentQuestionNumber,
      });
    }
    this.setState({confirmAnswers: !this.state.confirmAnswers}, function () {
      this.props.navigation.setParams({
        confirmAnswers: this.state.confirmAnswers,
      });
      this.closeOptionsPopover();
      try {
        AsyncStorage.setItem(
          'showConfirmAnswers',
          JSON.stringify(this.state.confirmAnswers),
        );
        firebase
          .analytics()
          .setUserProperty(
            'confirmAnswersEnabled',
            this.state.confirmAnswers.toString(),
          );
      } catch (error) {
        // Error saving data
        console.log('error saving: ' + error);
      }
    });
  }

  loadQuestion() {
    var shuffledQuestionAnswers = _.shuffle(this.state.currentQuestion.Answers);

    // build audio text
    const answers = [...shuffledQuestionAnswers]
      .map((item, i) => `Option ${i + 1}, ${item.Answer}`)
      .join('. ');
    let questionText = this.state.currentQuestion.Question;
    if (questionText.endsWith(':')) {
      questionText = questionText.slice(0, -1) + '.';
    }
    const audioText =
      this.state.currentQuestion.QuestionType === 'Extended Answer'
        ? `${questionText}`
        : `${questionText} ${answers}`;

    this.setState({
      questionAnswers: shuffledQuestionAnswers,
      extendedAnswerText: '',
      checkingExtendedAnswer: false,
      selectedAnswers: [],
      audioText: audioText,
      speechHistory: [audioText],
    });

    if (Platform.OS !== 'web') {
      Keyboard.dismiss();
    }

    if (this.state.quiz.quizMode != '5 Minutes') {
      var titleText =
        'Q ' +
        this.state.currentQuestionNumber +
        ' / ' +
        this.state.questions.length;
      this.props.navigation.setParams({titleText: titleText});
    }

    var questionNumbersViewed = this.state.questionNumbersViewed;

    if (
      questionNumbersViewed.includes(this.state.currentQuestionNumber) === false
    ) {
      questionNumbersViewed.push(this.state.currentQuestionNumber);
      this.setState({questionNumbersViewed: questionNumbersViewed});
    }

    var firstQuestionNumber = 0;
    var lastQuestionNumber = 0;

    for (
      let questionNumber = 1;
      questionNumber <= this.state.questions.length;
      questionNumber++
    ) {
      if (
        this.state.questionNumbersAnswered.includes(questionNumber) === false
      ) {
        if (firstQuestionNumber === 0) {
          firstQuestionNumber = questionNumber;
        }

        lastQuestionNumber = questionNumber;
      }
    }

    var isFirstQuestionLeftToAnswer =
      this.state.currentQuestionNumber === firstQuestionNumber;
    var isLastQuestionLeftToAnswer =
      this.state.currentQuestionNumber === lastQuestionNumber;

    this.setState({
      isFirstQuestionLeftToAnswer: isFirstQuestionLeftToAnswer,
      isLastQuestionLeftToAnswer: isLastQuestionLeftToAnswer,
    });

    this.loadQuestionImage();

    this.decideToShowReferenceButton();

    var that = this;
    setTimeout(() => {
      that.setState({webviewCounter: that.state.webviewCounter + 1});
    }, 1000);

    if (this._scrollView != null && this._scrollView.scrollTo) {
      this._scrollView.scrollTo({x: 0, y: 0, animated: false});
    }
  }

  async loadQuestionImage() {
    if (this.state.currentQuestion.ImageAsset != null) {
      var questionImageURL = await DataController.getQuestionImagePath(
        this.state.currentQuestion,
      );
      this.setState({questionImageURL: questionImageURL});
    } else {
      this.setState({questionImageURL: ''});
    }
  }

  goToPreviousQuestion() {
    if (this.state.currentQuestionNumber > 1) {
      var previousQuestionNumber = 0;

      for (
        let questionNumber = this.state.questions.length;
        questionNumber > 0;
        questionNumber--
      ) {
        if (
          questionNumber < this.state.currentQuestionNumber &&
          this.state.questionNumbersAnswered.includes(questionNumber) === false
        ) {
          if (previousQuestionNumber === 0) {
            previousQuestionNumber = questionNumber;
          }
        }
      }

      if (previousQuestionNumber > 0) {
        var previousQuestion = this.state.questions[previousQuestionNumber - 1];

        this.setState(
          {
            currentQuestionNumber: previousQuestionNumber,
            currentQuestion: previousQuestion,
            questionHasBeenAnswered: false,
          },
          function () {
            this.loadQuestion();
          },
        );

        // reset tts state
        if (Platform.OS === 'web') {
          speechSynthesis.cancel();
        } else {
          Tts.stop();
        }
        this.setState({
          speechHistory: [previousQuestion],
          audioIsPlaying: false,
          lastSpokenWordIndex: 0,
        });
        this.props.navigation.setParams({
          audioIsPlaying: false,
        });
      }
    }
  }

  goToNextQuestion() {
    if (this.state.currentQuestionNumber < this.state.questions.length) {
      var nextQuestionNumber = 0;

      for (
        let questionNumber = 1;
        questionNumber <= this.state.questions.length;
        questionNumber++
      ) {
        if (
          questionNumber > this.state.currentQuestionNumber &&
          this.state.questionNumbersAnswered.includes(questionNumber) === false
        ) {
          if (nextQuestionNumber === 0) {
            nextQuestionNumber = questionNumber;
          }
        }
      }

      if (nextQuestionNumber > 0) {
        var nextQuestion = this.state.questions[nextQuestionNumber - 1];

        this.setState(
          {
            currentQuestionNumber: nextQuestionNumber,
            currentQuestion: nextQuestion,
            questionHasBeenAnswered: false,
            highlightedAnswerForCurrentQuestion: -1,
          },
          function () {
            this.loadQuestion();
          },
        );

        // reset tts state
        if (Platform.OS === 'web') {
          speechSynthesis.cancel();
        } else {
          Tts.stop();
        }
        this.setState({
          speechHistory: [nextQuestion],
          audioIsPlaying: false,
          lastSpokenWordIndex: 0,
        });
        this.props.navigation.setParams({
          audioIsPlaying: false,
        });
      }
    }
  }

  async finishQuiz(quizCallback) {
    var resultsForQuiz = [];

    for (
      var resultIndex = 0,
        resultsLength = this.props.screenProps.results?.length;
      resultIndex < resultsLength;
      resultIndex++
    ) {
      var result = this.props.screenProps.results[resultIndex];
      if (result.quizName === this.state.quiz.name) {
        resultsForQuiz.push(result);
      }
    }

    const correctResults = resultsForQuiz.filter(
      result => result.correct === true,
    );
    const percentageCorrect = Math.round(
      (correctResults.length / resultsForQuiz.length) * 100,
    );

    // if 60% or more correct award a promo code
    if (percentageCorrect >= 60) {
      quizCallback?.();
    }

    this.props.screenProps.updateQuizResults();

    var quiz = this.state.quiz;

    this.props.navigation.navigate(
      'QuizResults',
      {
        quiz: quiz,
        quizSet: this.state.quizSet,
        questions: this.state.questions,
        results: resultsForQuiz,
        comingFromQuiz: true,
        comingFromOverviewScreen: this.props.navigation.getParam(
          'comingFromOverviewScreen',
          true,
        ),
      },
      this.state,
    );
  }

  async retakeIncorrectQuestions() {}

  async decideToShowReferenceButton() {
    var shouldShowReferenceButton = false;

    var reference = this.state.currentQuestion.Reference;

    if (reference != null && reference !== '') {
      var referenceParts = reference.split(' - ');

      if (reference.length > 2) {
        var glid = referenceParts[0];
        var section = referenceParts[2];

        console.log(glid);

        var referenceContent =
          await GuidelinesController.getGuidelineJSONForGLID(glid);

        if (referenceContent != null && referenceContent !== 'Old Guideline') {
          shouldShowReferenceButton = true;
        } else {
          let guidelineName =
            await GuidelinesController.getGuidelineNameForGuideline(glid);
          if (guidelineName != null && guidelineName !== '') {
            // guideline is in list so it should be downloaded - but might not have finished yet
            shouldShowReferenceButton = true;
          }
        }
      }
    }

    this.setState({shouldShowReferenceButton: shouldShowReferenceButton});
  }

  async referenceButtonTapped() {
    var reference = this.state.currentQuestion.Reference;

    if (reference == null || reference === '') {
      return;
    }

    // var reference = null;
    // var referenceSup = null;
    // reference2019 = 'G0570 - Head Injury - 1. Introduction'

    var referenceParts = reference.split(' - ');

    if (reference.length > 2) {
      var glid = referenceParts[0];
      var section = referenceParts[2];

      console.log(glid);

      var referenceContent = await GuidelinesController.getGuidelineJSONForGLID(
        glid,
      );

      if (referenceContent != null && referenceContent !== 'Old Guideline') {
        var from = this.props.navigation.getParam('from', 'unknown');
        this.props.navigation.navigate(
          'Reference',
          {
            reference: reference,
            guidelineContents: referenceContent,
            section: section,
            question: this.state.currentQuestion,
            from,
          },
          this.state,
        );
      } else {
        let guidelineName =
          await GuidelinesController.getGuidelineNameForGuideline(glid);
        if (guidelineName != null && guidelineName !== '') {
          // guideline is in list so it should be downloaded - but might not have finished yet
          Alert.alert(
            "The reference can't be displayed at the moment. It might still be downloading. Please try again shortly.",
          );
        }
      }
    }
  }

  async pgdReferenceButtonTapped() {
    this.props.navigation.navigate(
      'PGDViewer',
      {
        glid: this.state.quizSet.pgdInfo.GLID,
      },
      this.state,
    );
  }

  onNavigationChange(event) {
    if (event.title) {
      var htmlHeight = Number(event.title); //convert to number
      htmlHeight = htmlHeight;

      // if (this.state.currentQuestionNumber === 1) {
      //   htmlHeight = htmlHeight + 80;
      // }

      if (htmlHeight > 40) {
        this.setState({webviewHeight: htmlHeight});
      }
    }
  }

  onMessage(m) {
    var message = m.nativeEvent.data;
    if (message.includes('scrollHeight')) {
      var htmlHeight = Number(message.split('-')[1]);

      if (Platform.isPad) {
        htmlHeight = htmlHeight - 50;
      }

      if (htmlHeight > 40) {
        this.setState({webviewHeight: htmlHeight});
      }
    } else if (message.includes('questionImageTapped')) {
      this.openImageModal();
    }
  }

  openImageModal() {
    // if (Platform.OS === 'web') {
    //   window.open('https://ppp-temp-files.s3.amazonaws.com/QuestionImages/reciMPVqjz7k9WNAD.jpg', '_blank');
    // } else {
    console.log('Image was tapped');
    this.setState({imageViewerVisible: true});
    // }
  }

  closeImageModal() {
    this.setState({imageViewerVisible: false});
  }

  answerStyle(answer: Object, i) {
    var buttonBackgroundColor = colors.AnswerField;
    var borderColor = '#6e9ecc';
    var borderWidth = 1;

    if (
      !this.state.questionHasBeenAnswered &&
      this.state.confirmAnswers &&
      this.state.highlightedAnswerForCurrentQuestion === i
    ) {
      borderColor = colors.BlueHighlight;
      borderWidth = 2;
    } else {
      if (this.state.questionHasBeenAnswered) {
        if (this.selectedAnswersContainsAnswer(answer)) {
          if (answer.Correct === true) {
            buttonBackgroundColor = colors.AnswerCorrect;
          } else {
            buttonBackgroundColor = colors.AnswerIncorrect;
          }
        }

        borderColor = buttonBackgroundColor;
      }
    }

    if (
      this.state.currentQuestion.QuestionType === 'Multi Select' &&
      !this.state.questionHasBeenAnswered &&
      this.selectedAnswersContainsAnswer(answer)
    ) {
      borderColor = colors.MultiSelectHighlight;
      borderWidth = 2;
    }

    return {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 14,
      paddingVertical: 14,
      marginVertical: 5,
      backgroundColor: buttonBackgroundColor,
      borderWidth: borderWidth,
      borderColor: borderColor,
      borderRadius: 2,
    };
  }

  checkboxImage(answer: Object) {
    var checkboxImage = null;

    if (this.state.questionHasBeenAnswered) {
      if (this.selectedAnswersContainsAnswer(answer)) {
        checkboxImage = (
          <Image
            style={{width: 20, height: 20}}
            source={require('../assets/Button_Tickbox_Active_Black_Green.png')}
          />
        );
      } else {
        checkboxImage = (
          <Image
            style={{width: 20, height: 20}}
            source={require('../assets/Button_Tickbox_Not_selected.png')}
          />
        );
      }
    } else {
      if (this.selectedAnswersContainsAnswer(answer)) {
        checkboxImage = (
          <Image
            style={{width: 20, height: 20}}
            source={require('../assets/Button_Tickbox_Active.png')}
          />
        );
      } else {
        checkboxImage = (
          <Image
            style={{width: 20, height: 20}}
            source={require('../assets/Button_Tickbox_Inactive.png')}
          />
        );
      }
    }

    return checkboxImage;
  }

  answerFeedbackImage(answer: Object) {
    var feedbackImage = <View style={{width: 30, height: 30}} />;

    if (this.state.questionHasBeenAnswered) {
      if (answer.Correct === true) {
        feedbackImage = (
          <Image
            style={{width: 30, height: 30}}
            source={require('../assets/Answer_Tick_Correct.png')}
          />
        );
      } else if (this.selectedAnswersContainsAnswer(answer)) {
        feedbackImage = (
          <Image
            style={{width: 30, height: 30}}
            source={require('../assets/Answer_Cross_Red_700.png')}
          />
        );
      }
    }

    return feedbackImage;
  }

  singleAnswerButtonTapped(answer: Object) {
    var isCorrect = answer.Correct != null && answer.Correct === true;
    this.answerQuestion([answer], isCorrect);
  }

  multiSelectAnswerButtonTapped(answer: Object) {
    var selectedAnswers = this.state.selectedAnswers.slice();

    if (this.selectedAnswersContainsAnswer(answer)) {
      selectedAnswers = selectedAnswers.filter(
        item => _.isEqual(item, answer) === false,
      );
    } else {
      selectedAnswers.push(answer);
    }

    this.setState({selectedAnswers: selectedAnswers});
  }

  markAsCorrect() {
    var answer = {Answer: this.state.extendedAnswerText, Correct: true};
    this.answerQuestion([answer], true);
  }

  markAsIncorrect() {
    var answer = {Answer: this.state.extendedAnswerText};
    this.answerQuestion([answer], false);
  }

  isAnswerCorrect() {
    var correctAnswerIndexes = [];
    var selectedAnswerIndexes = [];

    for (
      let answerIndex = 0;
      answerIndex < this.state.questionAnswers.length;
      answerIndex++
    ) {
      var anAnswer = this.state.questionAnswers[answerIndex];

      if (anAnswer.Correct === true) {
        correctAnswerIndexes.push(answerIndex);
      }

      if (this.selectedAnswersContainsAnswer(anAnswer)) {
        selectedAnswerIndexes.push(answerIndex);
      }
    }

    var isCorrect = false;

    if (_.isEqual(correctAnswerIndexes, selectedAnswerIndexes)) {
      isCorrect = true;
    }

    return isCorrect;
  }

  confirmAnswer() {
    if (this.state.currentQuestion.QuestionType === 'Extended Answer') {
      this.setState({checkingExtendedAnswer: true});
    } else if (this.state.currentQuestion.QuestionType === 'Multi Select') {
      var isCorrect = this.isAnswerCorrect();

      this.answerQuestion(this.state.selectedAnswers, isCorrect);
    }
  }

  answerQuestion(answers: [], isCorrect: boolean) {
    if (this.state.questionHasBeenAnswered == false) {
      if (this.state.questionNumbersAnswered.length === 0) {
        console.log(
          'this.state.quiz in QuizScreen.answerQuestion(): ',
          this.state.quiz,
        );
        this.props.screenProps.createQuiz(this.state.quiz);
      }

      var questionNumbersAnswered = this.state.questionNumbersAnswered;
      questionNumbersAnswered.push(this.state.currentQuestionNumber);

      this.setState({
        questionHasBeenAnswered: true,
        checkingExtendedAnswer: false,
        selectedAnswers: answers,
        questionNumbersAnswered: questionNumbersAnswered,
      });
      this.props.navigation.setParams({
        questionNumbersAnswered: questionNumbersAnswered,
      });

      if (isCorrect) {
        this.setState({correctCount: this.state.correctCount + 1});
      } else {
        this.setState({incorrectCount: this.state.incorrectCount + 1});
      }

      var answerText = '';

      if (this.state.currentQuestion.QuestionType === 'Multi Select') {
        for (
          let selectedAnswerIndex = 0;
          selectedAnswerIndex < this.state.selectedAnswers.length;
          selectedAnswerIndex++
        ) {
          var selectedAnswer = this.state.selectedAnswers[selectedAnswerIndex];

          if (answerText !== '') {
            answerText += '|';
          }

          answerText += selectedAnswer.Answer;
        }
      } else {
        answerText = answers[0].Answer;
      }

      this.props.screenProps.createResult(
        this.state.currentQuestion.PackName,
        this.state.currentQuestion.QuestionID,
        this.state.quiz,
        answerText,
        isCorrect,
        this.state.retakingIncorrectQuestions,
      );
    }
  }

  selectedAnswersContainsAnswer(answer: Object) {
    return _.some(this.state.selectedAnswers, answer); // check to see if answer is in selected answers - checking equality
  }

  handleAudioButton() {
    if (Platform.OS === 'web') {
      this.handleAudioForWeb();
    } else {
      this.handleAudioForMobile();
    }
  }

  async handleAudioForWeb() {
    const utterance = new SpeechSynthesisUtterance(this.state.audioText);
    utterance.rate = 0.8;

    if (this.state.audioIsPlaying) {
      this.setState({audioIsPlaying: false});
      this.props.navigation.setParams({
        audioIsPlaying: false,
      });
      speechSynthesis.pause();
    } else {
      this.setState({audioIsPlaying: true});
      this.props.navigation.setParams({
        audioIsPlaying: true,
      });
      if (speechSynthesis.paused) {
        speechSynthesis.resume();
      } else {
        speechSynthesis.cancel();
        speechSynthesis.speak(utterance);
      }
    }
  }

  async handleAudioForMobile() {
    try {
      if (this.state.audioIsPlaying) {
        if (Platform.OS === 'ios') {
          const pauseSuccess = await Tts.pause();
          if (!pauseSuccess) Tts.stop();
        } else {
          Tts.stop(); // has effect of 'pausing' for Android
        }
        this.setState({audioIsPlaying: false});
        this.props.navigation.setParams({
          audioIsPlaying: false,
        });
      } else {
        const remainingTextToSpeak =
          this.state.speechHistory[this.state.speechHistory.length - 1];

        if (Platform.OS === 'ios') {
          const resumeSuccess = await Tts.resume();
          if (!resumeSuccess) Tts.speak(remainingTextToSpeak);
        } else {
          Tts.speak(remainingTextToSpeak);
        }
        this.setState({audioIsPlaying: true});
        this.props.navigation.setParams({
          audioIsPlaying: true,
        });
      }
    } catch (error) {
      console.log('Error with TTS playback: ', error);
    }
  }

  render() {
    var that = this;

    var questionCount = 0;
    var unansweredCount = 0;

    if (this.state.questions != null) {
      questionCount = this.state.questions.length;
    }

    let pgdQuizType = 'PGD';
    if (this.state.quizSet && this.state.quizSet.pma === true) {
      pgdQuizType = 'PMA';
    }

    var results =
      this.props.screenProps.latestResultsForQuizSets[this.state.quizSet.name];

    unansweredCount =
      questionCount - this.state.correctCount - this.state.incorrectCount;

    var sectionLayout = null;

    if (this.state.currentQuestion.Section != null) {
      var subSectionLayout = null;
      if (
        this.state.currentQuestion.SubSection != null &&
        this.state.currentQuestion.DoNotShowSubSection !== true
      ) {
        subSectionLayout = (
          <DynamicText>
            <DynamicText style={{color: colors.Grey700}}> • </DynamicText>
            <DynamicText
              style={{
                flex: 1,
                fontSize: 15,
                letterSpacing: -0.24,
                color: colors.Grey800,
              }}
              numberOfLines={0}>
              {this.state.currentQuestion.SubSection}
            </DynamicText>
          </DynamicText>
        );
      }

      let difficultyText = '';
      if (this.state.currentQuestion.Difficulty != null) {
        if (this.state.currentQuestion.Difficulty === '1') {
          difficultyText = 'Level 1: Introduction';
        } else if (this.state.currentQuestion.Difficulty === '2') {
          difficultyText = 'Level 2: Intermediate';
        } else if (this.state.currentQuestion.Difficulty === '3') {
          difficultyText = 'Level 3: Advanced';
        }
      }

      sectionLayout = (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}>
          <View
            style={{
              justifyContent: 'center',
              paddingHorizontal: 6,
              height: 16,
              borderRadius: 2,
              backgroundColor: DataController.getPrimaryColorForSection(
                this.state.currentQuestion.Section,
              ),
            }}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: '500',
                letterSpacing: -0.1,
                color: colors.white,
              }}>
              {this.state.currentQuestion.Section}
            </Text>
          </View>
          <View
            style={{
              backgroundColor: '#878e95',
              borderRadius: 1,
              height: 2,
              marginRight: 6,
              marginLeft: 6,
              width: 2,
            }}
          />
          <Text
            style={{
              color: '#4a5056',
              fontFamily: 'InterUI-Regular',
              fontWeight: '600',
              marginTop: 8,
            }}>
            {this.state.currentQuestion.SubSection}
          </Text>
          {difficultyText !== '' && (
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <View
                style={{
                  backgroundColor: '#878e95',
                  borderRadius: 1,
                  height: 2,
                  marginRight: 6,
                  marginLeft: 6,
                  width: 2,
                }}
              />
              <Text
                style={{
                  color: '#4a5056',
                  fontFamily: 'InterUI-Regular',
                  fontWeight: '600',
                }}>
                {difficultyText}
              </Text>
            </View>
          )}
        </View>
      );
    }

    var styleCSS =
      `
      <style>
        body {
          
        }
        .quizSceneQuestion {
          margin: 0;
          padding: 0;
          font-family: InterUI-Regular, -apple-system, BlinkMacSystemFont,
                                    "Segoe UI", "Roboto", "Oxygen",
                                    "Ubuntu", "Cantarell", "Fira Sans",
                                    "Droid Sans", "Helvetica Neue", sans-serif;
          font-size: ` +
      this.props.screenProps.fontSize +
      `pt;
          font-weight: 600;
          line-height: 1.4;
          min-height: 46px;
          color: #374044;
        }
        .image-container {
          margin-top: 10px;
        }
        .question-image {
          width: 100%;
          max-height: 40vh;
          object-fit: contain;
        }
        .video-container {
          width: 100%;
          margin-top: 10px;
          position: relative;
        }
        .video-frame {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 290px !important;
        }
        body, html, .height-calculator {
            margin: 0;
            padding: 0;
        }
        .height-calculator {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
        .hiddenText {
          display: none;
        }
      </style>
      `;

    var imageLayout = '';
    var videoLayout = '';

    // imageLayout = `<div class='image-container'><img id='question-image' class='question-image' src='https://ppp-temp-files.s3.amazonaws.com/StandbyVol9No10/fig3.jpg'/></div>`;

    const script = `
    <script>
      window.location.hash = 1;
      var calculator = document.createElement("div");
      calculator.className = "height-calculator";
      while (document.body.firstChild) {
          calculator.appendChild(document.body.firstChild);
      }
      document.body.appendChild(calculator);
      document.title = calculator.scrollHeight;

    </script>
    `;

    var imageTapScript = `
    <script id='imageTapScript'>

        console.log('loaded');

        setTimeout(function() {
          // document.getElementById('question-image').removeEventListener('click', questionImageTapped);
          // document.getElementById('question-image').addEventListener('click', questionImageTapped);
        }, 500);

        function questionImageTapped() {
          console.log('question image tapped');
          if (window.ReactNativeWebView != null && window.ReactNativeWebView.postMessage != null) {
            window.ReactNativeWebView.postMessage("questionImageTapped");
          }
        }

    </script>
    `;

    // set-up question webview
    var rawHtml =
      "<html><head><meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>" +
      styleCSS +
      imageTapScript +
      "</head><body><div><p class='quizSceneQuestion'>" +
      this.state.currentQuestion.Question +
      imageLayout +
      videoLayout +
      "<span class='hiddenText'>" +
      this.state.webviewCounter +
      '</span></p></div></body></html>';
    // var rawHtml="<html><head><meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>" + styleCSS + "</head><body><div><p class='quizSceneQuestion'>" + "The timeframe during which malaria should be considered in a returning traveller from an endemic area:" + "<span class='hiddenText'>" + this.state.webviewCounter + "</span></p></div></body></html>";

    var webContentLayout = null;

    if (Platform.OS === 'ios') {
      webContentLayout = (
        <AMWebView
          style={{flex: 1, height: this.state.webviewHeight}}
          // ref="mainWebView"
          source={{html: rawHtml + script}}
          onMessage={m => this.onMessage(m)}
          // style={{height:this.state.Height}}
          useWebKit={true}
          allowsFullscreenVideo={true}
          // javaScriptEnabled ={true}
          onNavigationStateChange={this.onNavigationChange.bind(this)}
        />
      );
    }

    if (Platform.OS === 'android') {
      webContentLayout = (
        <AMWebView
          style={{
            flex: 1,
            height: this.state.webviewHeight,
            opacity: 0.99,
            overflow: 'hidden',
          }}
          // ref="mainWebView"
          source={{html: rawHtml + script}}
          onMessage={m => this.onMessage(m)}
          // style={{height:this.state.Height}}
          scalesPageToFit={true}
          useWebKit={true}
          allowsFullscreenVideo={true}
          // javaScriptEnabled ={true}
          onNavigationStateChange={this.onNavigationChange.bind(this)}
        />
      );
    }

    if (Platform.OS === 'web') {
      webContentLayout = (
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: rawHtml + script}}></div>
      );
    }

    let answerLayouts = [];
    var helpTextLayout = null;
    var helpText = '';

    if (this.state.currentQuestion.QuestionType === 'Multi Select') {
      answerLayouts = this.state.questionAnswers.map((answer, i) => {
        return (
          <TouchableOpacity
            key={i}
            activeOpacity={0.5}
            onPress={() => this.multiSelectAnswerButtonTapped(answer)}>
            <View style={this.answerStyle(answer, i)}>
              <View style={{marginRight: 10}}>
                {this.checkboxImage(answer, i)}
              </View>
              <DynamicText
                style={{
                  flex: 1,
                  fontSize: 17,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                  lineHeight: 24,
                }}>
                {answer.Answer}
              </DynamicText>
              {this.answerFeedbackImage(answer, i)}
            </View>
          </TouchableOpacity>
        );
      });
    } else if (this.state.currentQuestion.QuestionType === 'Extended Answer') {
      if (
        this.state.checkingExtendedAnswer ||
        this.state.questionHasBeenAnswered
      ) {
        if (
          this.state.questionAnswers !== null &&
          this.state.questionAnswers.length > 0
        ) {
          var questionAnswer = this.state.questionAnswers[0];

          var yourAnswerBackgroundColor = colors.Grey100;

          if (this.state.questionHasBeenAnswered) {
            if (
              this.state.selectedAnswers[0].Correct !== null &&
              this.state.selectedAnswers[0].Correct === true
            ) {
              yourAnswerBackgroundColor = colors.AnswerCorrect;
            } else {
              yourAnswerBackgroundColor = colors.AnswerIncorrect;
            }
          }

          var referenceButtonLayout = <View />;

          if (this.state.shouldShowReferenceButton) {
            var referenceButtonText = 'JRCALC Reference';
            if (this.state.quizSet.pgd) {
              referenceButtonText = `${pgdQuizType} Reference`;
            }

            referenceButtonLayout = (
              <View>
                <DynamicText
                  style={{
                    marginTop: 15,
                    fontSize: 14,
                    fontWeight: '500',
                    letterSpacing: -0.44,
                    color: colors.Grey600,
                  }}>
                  Read the reference
                </DynamicText>

                <TouchableOpacity
                  activeOpacity={0.5}
                  style={{flex: 1}}
                  onPress={this.referenceButtonTapped.bind(this)}>
                  <View
                    style={{
                      marginTop: 6,
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: colors.white,
                      borderWidth: 1,
                      borderColor: colors.ButtonBlue,
                      borderRadius: 2,
                    }}>
                    <DynamicText
                      style={{
                        paddingVertical: 14,
                        fontSize: 16,
                        fontWeight: '700',
                        letterSpacing: -0.39,
                        color: colors.ButtonBlue,
                      }}>
                      {referenceButtonText}
                    </DynamicText>
                  </View>
                </TouchableOpacity>
              </View>
            );
          }

          answerLayouts.push(
            <View style={{flex: 1}}>
              <DynamicText
                style={{
                  fontSize: 14,
                  fontWeight: '500',
                  letterSpacing: -0.44,
                  color: colors.Grey600,
                }}>
                Correct answer
              </DynamicText>

              <View
                style={{
                  marginTop: 6,
                  paddingHorizontal: 10,
                  paddingVertical: 11,
                  backgroundColor: colors.AnswerCorrect,
                  borderRadius: 2,
                }}>
                <Hyperlink
                  linkStyle={{textDecorationLine: 'underline'}}
                  onPress={(url, text) => PlatformController.openURL(url)}>
                  <DynamicText
                    style={{
                      fontSize: 17,
                      letterSpacing: -0.41,
                      color: colors.Grey900,
                    }}>
                    {questionAnswer.Answer}
                  </DynamicText>
                </Hyperlink>
              </View>

              <DynamicText
                style={{
                  marginTop: 15,
                  fontSize: 14,
                  fontWeight: '500',
                  letterSpacing: -0.44,
                  color: colors.Grey600,
                }}>
                Your answer
              </DynamicText>

              <View
                style={{
                  marginTop: 6,
                  paddingHorizontal: 10,
                  paddingVertical: 11,
                  backgroundColor: yourAnswerBackgroundColor,
                  borderRadius: 2,
                }}>
                <DynamicText
                  style={{
                    fontSize: 17,
                    letterSpacing: -0.41,
                    color: colors.Grey900,
                  }}>
                  {this.state.extendedAnswerText}
                </DynamicText>
              </View>

              {referenceButtonLayout}
            </View>,
          );

          helpText =
            'Mark your answer based off the correct one provided. Note you cannot change your mark.';
        }
      } else {
        answerLayouts.push(
          <View
            style={{
              flex: 1,
              backgroundColor: colors.Grey000,
              borderWidth: 1,
              borderColor: colors.Grey300,
              borderRadius: 2,
            }}>
            <ScrollView
              ref={c => (this._extendedAnswerScrollView = c)}
              style={{height: Math.max(100, this.state.extendedAnswerHeight)}}
              contentContainerStyle={{flex: 1}}>
              <TouchableOpacity
                activeOpacity={1}
                style={{flex: 1}}
                onPress={this.focusEditor.bind(this)}>
                <TextInput
                  ref={c => (this._extendedAnswerTextInput = c)}
                  style={{
                    flex: 1,
                    fontSize: 15,
                    marginHorizontal: 8,
                    marginVertical: 8,
                    color: colors.Green900,
                  }}
                  onChangeText={text => this.extendedAnswerTextDidChange(text)}
                  value={this.state.extendedAnswerText}
                  pointerEvents={this.state.extendedAnswerPointerEvents}
                  onBlur={this.blurEditor.bind(this)}
                  placeholder={'Enter your answer here'}
                  placeholderTextColor={colors.Grey400}
                  textAlignVertical={'top'}
                  multiline={true}
                  returnKeyType={'default'}
                  onContentSizeChange={event => {
                    if (Platform.OS !== 'web') {
                      this.setState({
                        extendedAnswerHeight:
                          event.nativeEvent.contentSize.height + 24,
                      });
                    }
                  }}
                />
              </TouchableOpacity>
            </ScrollView>
          </View>,
        );
      }
    } else {
      answerLayouts = this.state.questionAnswers.map((answer, i) => {
        return (
          <TouchableOpacity
            key={i}
            activeOpacity={0.5}
            onPress={() => {
              if (this.state.confirmAnswers) {
                this.setState({highlightedAnswerForCurrentQuestion: i});
              } else {
                this.singleAnswerButtonTapped(answer);
              }
            }}>
            <View style={this.answerStyle(answer, i)}>
              <DynamicText
                style={{
                  flex: 1,
                  fontSize: 17,
                  letterSpacing: -0.41,
                  color: colors.Grey900,
                  lineHeight: 24,
                }}>
                {answer.Answer}
              </DynamicText>
              {this.answerFeedbackImage(answer, i)}
            </View>
          </TouchableOpacity>
        );
      });
    }

    var questionFeedbackLayout = null;
    var feedbackText = '';

    if (this.state.questionHasBeenAnswered) {
      if (this.state.currentQuestion.QuestionFeedback != null) {
        feedbackText = this.state.currentQuestion.QuestionFeedback;
      }

      for (
        let selectedAnswerIndex = 0;
        selectedAnswerIndex < this.state.selectedAnswers.length;
        selectedAnswerIndex++
      ) {
        var selectedAnswer = this.state.selectedAnswers[selectedAnswerIndex];

        if (selectedAnswer.AnswerFeedback != null) {
          if (feedbackText !== '') {
            feedbackText += '. ';
          }
          feedbackText += selectedAnswer.AnswerFeedback;
        }
      }

      if (feedbackText !== '') {
        var isCorrect = this.isAnswerCorrect();
        var backgroundColor = '#F4C4C6';
        var borderColor = colors.Red700;
        var textColor = colors.Red900;

        if (isCorrect) {
          backgroundColor = '#CDEDCB';
          borderColor = colors.Green800;
          textColor = colors.Green900;
        }

        questionFeedbackLayout = (
          <View
            style={{
              marginTop: 15,
              paddingHorizontal: 10,
              paddingVertical: 18,
              backgroundColor: backgroundColor,
              borderWidth: 1,
              borderColor: borderColor,
            }}>
            <DynamicText style={{fontSize: 17, color: textColor}}>
              {feedbackText}
            </DynamicText>
          </View>
        );
      }
    }

    var leftButtonType = '';

    if (this.state.checkingExtendedAnswer) {
      leftButtonType = 'incorrectButton';
    } else if (this.state.questionHasBeenAnswered && this.state.quizSet.pgd) {
      leftButtonType = 'pgdReferenceButton';
    } else if (
      this.state.questionHasBeenAnswered &&
      this.state.currentQuestion.Reference != null &&
      this.state.shouldShowReferenceButton
    ) {
      leftButtonType = 'referenceButton';
    } else if (this.state.isFirstQuestionLeftToAnswer === false) {
      leftButtonType = 'previousButton';
    }

    var bottomLeftButtonLayout = <View style={{flex: 1}} />;

    if (leftButtonType === 'pgdReferenceButton') {
      bottomLeftButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.5}
          style={{flex: 1}}
          onPress={this.pgdReferenceButtonTapped.bind(this)}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: colors.white,
              borderWidth: 1,
              borderColor: colors.ButtonBlue,
              borderRadius: 8,
            }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
              }}>
              {pgdQuizType} Reference
            </Text>
          </View>
        </TouchableOpacity>
      );
    } else if (leftButtonType === 'referenceButton') {
      bottomLeftButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.5}
          style={{flex: 1}}
          onPress={this.referenceButtonTapped.bind(this)}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: colors.white,
              borderWidth: 1,
              borderColor: colors.ButtonBlue,
              borderRadius: 8,
            }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
              }}>
              JRCALC Reference
            </Text>
          </View>
        </TouchableOpacity>
      );
    } else if (leftButtonType === 'previousButton') {
      bottomLeftButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.5}
          style={{flex: 1}}
          onPress={this.goToPreviousQuestion.bind(this)}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Image
              style={{width: 11, height: 18}}
              source={require('../assets/Button_Previous.png')}
            />
            <Text
              style={{
                marginLeft: 5,
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.ButtonBlue,
              }}>
              Previous Question
            </Text>
          </View>
        </TouchableOpacity>
      );
    } else if (leftButtonType === 'incorrectButton') {
      bottomLeftButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.7}
          style={{flex: 1}}
          onPress={this.markAsIncorrect.bind(this)}>
          <View
            style={{
              flex: 1,
              backgroundColor: colors.Red500,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
            }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.white,
              }}>
              Incorrect
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    var rightButtonType = 'nextButton';

    if (this.state.currentQuestion.QuestionType === 'Extended Answer') {
      if (this.state.checkingExtendedAnswer) {
        rightButtonType = 'correctButton';
      } else {
        if (
          this.state.extendedAnswerText !== '' &&
          this.state.questionHasBeenAnswered === false
        ) {
          rightButtonType = 'confirmButton';
        } else {
          if (
            this.state.isLastQuestionLeftToAnswer ||
            this.state.secondsRemaining === 0
          ) {
            rightButtonType = 'finishButton';
          }
        }
      }
    } else if (this.state.currentQuestion.QuestionType === 'Multi Select') {
      if (
        this.state.selectedAnswers.length > 0 &&
        this.state.questionHasBeenAnswered === false
      ) {
        rightButtonType = 'confirmButton';
      } else {
        if (
          this.state.isLastQuestionLeftToAnswer ||
          this.state.secondsRemaining === 0
        ) {
          rightButtonType = 'finishButton';
        }
      }
    } else {
      if (
        this.state.isLastQuestionLeftToAnswer ||
        this.state.secondsRemaining === 0
      ) {
        rightButtonType = 'finishButton';
      }
    }

    var bottomRightButtonLayout = <View style={{flex: 1}} />;

    if (rightButtonType === 'finishButton') {
      bottomRightButtonLayout = (
        <PromoQuizButton onPress={this.finishQuizButtonTapped} />
      );
    }
    if (rightButtonType === 'confirmButton') {
      bottomRightButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.7}
          style={{flex: 1}}
          onPress={this.confirmAnswer.bind(this)}>
          <View
            style={{
              flex: 1,
              backgroundColor: colors.ButtonBlue,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
            }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.white,
              }}>
              Confirm Answer
            </Text>
          </View>
        </TouchableOpacity>
      );
    } else if (rightButtonType === 'nextButton') {
      if (this.state.questionHasBeenAnswered) {
        bottomRightButtonLayout = (
          <TouchableOpacity
            activeOpacity={0.7}
            style={{flex: 1}}
            onPress={this.goToNextQuestion.bind(this)}>
            <View
              style={{
                flex: 1,
                backgroundColor: colors.ButtonBlue,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
              }}>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: '700',
                  letterSpacing: -0.39,
                  color: colors.white,
                }}>
                Next Question
              </Text>
            </View>
          </TouchableOpacity>
        );
      } else {
        bottomRightButtonLayout = (
          <TouchableOpacity
            activeOpacity={0.5}
            style={{flex: 1}}
            onPress={this.goToNextQuestion.bind(this)}>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  marginRight: 5,
                  fontSize: 16,
                  fontWeight: '700',
                  letterSpacing: -0.39,
                  color: colors.ButtonBlue,
                }}>
                Skip
              </Text>
              <Image
                style={{width: 11, height: 18}}
                source={require('../assets/Button_Next.png')}
              />
            </View>
          </TouchableOpacity>
        );
      }
    } else if (rightButtonType === 'correctButton') {
      bottomRightButtonLayout = (
        <TouchableOpacity
          activeOpacity={0.7}
          style={{flex: 1}}
          onPress={this.markAsCorrect.bind(this)}>
          <View
            style={{
              flex: 1,
              backgroundColor: colors.Green500,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
            }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.white,
              }}>
              Correct
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    if (helpText !== '') {
      helpTextLayout = (
        <DynamicText
          style={{
            marginTop: 30,
            fontSize: 14,
            letterSpacing: -0.44,
            color: colors.Grey500,
          }}>
          {helpText}
        </DynamicText>
      );
    }

    var progressBarLayout = null;

    if (this.state.showProgressBar) {
      progressBarLayout = (
        <View
          style={{
            alignItems: 'stretch',
            paddingHorizontal: 20,
            paddingTop: 4,
            paddingBottom: 15,
            backgroundColor: colors.white,
          }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 10,
            }}>
            <DynamicText
              style={{fontSize: 12, fontWeight: '600', color: colors.Grey600}}>
              Question {this.state.currentQuestionNumber} / {questionCount}
            </DynamicText>
            <DynamicText
              style={{fontSize: 12, fontWeight: '600', color: colors.Grey600}}>
              {this.state.correctCount} Correct
            </DynamicText>
          </View>

          <ProgressView
            style={{marginTop: 10, height: 4, overflow: 'hidden'}}
            correctAnswers={this.state.correctCount}
            incorrectAnswers={this.state.incorrectCount}
            unansweredQuestions={unansweredCount}
          />
        </View>
      );
    }

    var keyboardVerticalOffset = -32;

    if (this.state.showProgressBar) {
      keyboardVerticalOffset = 66;
    }

    if (Platform.OS === 'android') {
      keyboardVerticalOffset = -20;

      if (this.state.showProgressBar) {
        keyboardVerticalOffset = 78;
      }
    }

    if (PlatformController.getBottomInset() > 0) {
      keyboardVerticalOffset += PlatformController.getBottomInset() - 10;
    }

    var questionMediaLayout = null;
    var src = [];

    if (
      this.state.currentQuestion != null &&
      this.state.questionImageURL != ''
    ) {
      src = [{url: this.state.questionImageURL, props: {}}];

      if (Platform.OS === 'web') {
        if (document.getElementById('imageTapScript') != null) {
          const scriptTag = document.getElementById('imageTapScript').innerHTML;
          window.eval(scriptTag);
        }
      }

      var windowWidth = Dimensions.get('window').width;
      var questionImageHeight = windowWidth / 3 - 50;

      questionMediaLayout = (
        <TouchableOpacity
          style={{aspectRatio: 1, marginBottom: 10}}
          activeOpacity={0.7}
          onPress={this.openImageModal.bind(this)}>
          <View
            style={{
              flex: 1,
              aspectRatio: 1,
              borderWidth: 1,
              borderColor: colors.Grey100,
            }}>
            <Image
              style={{flex: 1, height: questionImageHeight}}
              source={{uri: this.state.questionImageURL}}
              resizeMode="contain"
            />
          </View>

          <Image
            style={{position: 'absolute', top: 0, right: 0}}
            source={require('../assets/Button_Fullscreen.png')}
            resizeMode="contain"
          />
        </TouchableOpacity>
      );
    }

    var videoID = this.state.currentQuestion.VideoID;
    // var videoID = '485939916';

    if (videoID != null) {
      var videoHtml =
        `
      <html>
        <head>
          <meta name='viewport' content='initial-scale=1.0, maximum-scale=1.0'>
          <style>
          body {
            margin: 0px;
            padding: 0px;
          }
            .video-container {
              width: 100%;
              margin-top: 4px;
              position: relative;
              height: 100%;
            }
            .video-frame {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          </style>
        </head>
        <body>
          <div class='video-container'>
            <iframe src="https://player.vimeo.com/video/` +
        videoID +
        `?title=0&byline=0&autoplay=0" class="video-frame" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>
        </body>
      </html>`;

      if (Platform.OS === 'web') {
        questionMediaLayout = (
          <View
            style={{flex: 1, maxHeight: 290, height: 290, marginBottom: 10}}>
            <div
              className="content"
              dangerouslySetInnerHTML={{__html: videoHtml}}></div>
          </View>
        );
      } else {
        questionMediaLayout = (
          <View style={{minHeight: 240, marginBottom: 10}}>
            <AMWebView
              style={{flex: 1, opacity: 0.99, overflow: 'hidden'}}
              source={{html: videoHtml}}
              allowsFullscreenVideo={true}
              useWebKit={true}
            />
          </View>
        );
      }
    }

    let showQuestionFootnote = false;
    if (
      this.state.currentQuestionNumber === 1 ||
      (this.state.currentQuestionNumber ===
        this.state.questionNumberConfirmAnswersWasEnabled &&
        !this.state.questionHasBeenAnswered)
    ) {
      showQuestionFootnote = true;
    }

    var questionLayout = (
      <View>
        <View style={{marginTop: 10, marginBottom: 10}}>
          {webContentLayout}
          {showQuestionFootnote && (
            <Text style={{marginTop: 8, fontSize: 12}}>
              Select your answer and tap confirm to submit
            </Text>
          )}
        </View>

        {questionMediaLayout}

        {answerLayouts}

        {helpTextLayout}

        {questionFeedbackLayout}
      </View>
    );

    if (LayoutController.isCompact() === false) {
      if (questionMediaLayout != null) {
        questionLayout = (
          <View>
            <View style={{marginTop: 10, marginBottom: 10}}>
              {webContentLayout}
              {showQuestionFootnote && (
                <Text style={{marginTop: 8, fontSize: 12}}>
                  Select your answer and tap confirm to submit
                </Text>
              )}
            </View>

            <View style={{flexDirection: 'row'}}>
              <View style={{flex: 2}}>{answerLayouts}</View>

              <View style={{width: '33%', marginLeft: 14, minHeight: 290}}>
                {questionMediaLayout}
              </View>
            </View>

            {helpTextLayout}

            {questionFeedbackLayout}
          </View>
        );
      } else {
        questionLayout = (
          <View>
            <View style={{marginTop: 10, marginBottom: 10}}>
              {webContentLayout}
              {showQuestionFootnote && (
                <Text style={{marginTop: 8, fontSize: 12}}>
                  Select your answer and tap confirm to submit
                </Text>
              )}
            </View>

            {answerLayouts}

            {questionMediaLayout}

            {helpTextLayout}

            {questionFeedbackLayout}
          </View>
        );
      }
    }

    let bottomLayout;
    if (
      !this.state.questionHasBeenAnswered &&
      this.state.currentQuestion.QuestionType === 'Multiple Choice' &&
      this.state.confirmAnswers
    ) {
      bottomLayout = (
        <TouchableOpacity
          disabled={this.state.highlightedAnswerForCurrentQuestion === -1}
          activeOpacity={0.7}
          style={{
            flex: 1,
            opacity:
              this.state.highlightedAnswerForCurrentQuestion === -1 ? 0.5 : 1,
          }}
          onPress={() => {
            const highlightedAnswer =
              this.state.questionAnswers[
                this.state.highlightedAnswerForCurrentQuestion
              ];
            this.singleAnswerButtonTapped(highlightedAnswer);
          }}>
          <View
            style={{
              flex: 1,
              backgroundColor: colors.ButtonBlue,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 8,
            }}>
            <Text
              style={{
                fontSize: 16,
                fontWeight: '700',
                letterSpacing: -0.39,
                color: colors.white,
              }}>
              Confirm
            </Text>
          </View>
        </TouchableOpacity>
      );
    } else {
      bottomLayout = (
        <>
          {bottomLeftButtonLayout}

          <View style={{width: 15}} />

          {bottomRightButtonLayout}
        </>
      );
    }

    const correctAnswers = this.state.questionAnswers.filter(q => q.Correct);
    const usersCorrectAnswers = this.state.selectedAnswers.filter(
      q => q.Correct,
    );

    let statusText = 'Correct!';
    let suffix = '';
    if (this.state.currentQuestion.QuestionType === 'Multi Select') {
      if (!this.isAnswerCorrect()) {
        statusText = 'Incorrect, ';
        if (usersCorrectAnswers.length < correctAnswers.length) {
          statusText += `you selected ${usersCorrectAnswers.length} option/s from ${correctAnswers.length} correct options.`;
        } else {
          statusText += `you selected ${this.state.selectedAnswers.length} options/s, but there are only ${correctAnswers.length} correct options.`;
        }
      }
    } else {
      if (!this.isAnswerCorrect()) {
        if (correctAnswers.length) {
          statusText = `Incorrect, the correct answer is `;
          suffix = correctAnswers[0].Answer;
        } else {
          statusText = 'Incorrect';
        }
      }
    }

    const answerStatusBox = (
      <View
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 61,
          backgroundColor: this.isAnswerCorrect()
            ? colors.AnswerCorrect
            : colors.AnswerIncorrect,
          justifyContent: 'center',
          padding: 12,
        }}>
        <MaxWidthContainer>
          <Text>
            {statusText}
            <Text style={{fontWeight: 'bold'}}>{suffix}</Text>
          </Text>
        </MaxWidthContainer>
      </View>
    );

    return (
      <ScreenComponent style={[styles.container]}>
        <KeyboardAvoidingView
          style={{flex: 1}}
          behavior="height"
          keyboardVerticalOffset={keyboardVerticalOffset}>
          <ScrollView
            ref={ref => (this._scrollView = ref)}
            styles={{flex: 1}}
            contentContainerStyle={{paddingBottom: 60}}>
            <MaxWidthContainer>
              {progressBarLayout}
              <View
                style={{
                  marginBottom: 10,
                  paddingHorizontal: 20,
                  paddingTop: 0,
                  paddingBottom: 20,
                  backgroundColor: colors.white,
                }}>
                {this.state.showTextToSpeech && (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      paddingTop: this.state.showProgressBar ? 0 : 14,
                      paddingBottom: 12,
                    }}>
                    <TouchableOpacity
                      onPress={this.handleAudioButton.bind(this)}
                      style={{
                        flexDirection: 'row',
                        backgroundColor: colors.AudioButtonBg,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 24,
                        borderWidth: 1,
                        borderColor: colors.AudioButtonBg,
                        paddingHorizontal: 14,
                        paddingVertical: 10,
                      }}>
                      <Image
                        style={{
                          width: 18,
                          height: 18,
                          resizeMode: 'contain',
                          marginRight: 4,
                        }}
                        source={
                          this.state.audioIsPlaying
                            ? require('../assets/Button_Stop_Blue.png')
                            : require('../assets/Button_Play_Blue.png')
                        }
                      />
                      <Text
                        style={{
                          color: colors.AudioButtonColor,
                          fontWeight: 'bold',
                        }}>
                        Play question
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

                {sectionLayout}

                {questionLayout}
              </View>
            </MaxWidthContainer>
          </ScrollView>

          {this.state.questionHasBeenAnswered &&
            this.state.currentQuestion.QuestionType !== 'Extended Answer' &&
            answerStatusBox}
          <View
            style={{
              backgroundColor: '#f8f9fa',
              height: 61,
              borderTopWidth: 1,
              borderColor: colors.Grey300,
            }}>
            <MaxWidthContainer
              contentContainerStyle={{
                flex: 1,
                flexDirection: 'row',
                padding: 10,
              }}>
              {bottomLayout}
            </MaxWidthContainer>
          </View>
        </KeyboardAvoidingView>

        <AMImageViewer
          visible={this.state.imageViewerVisible}
          imageUrls={src}
          onClose={this.closeImageModal.bind(this)}
        />
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  button: {flex: 1, height: 40},
  buttonBackground: {
    flex: 1,
    backgroundColor: colors.Green500,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '700',
    letterSpacing: -0.39,
    color: colors.white,
  },
});
