import React from 'react';

import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
  StyleSheet,
} from 'react-native';

import moment from 'moment';

import DataController from '../classes/DataController';
import {colors} from '../classes/Colors';

/**
 * A card component for the hero section of the home screen.
 *
 * This component represents the latest video podcast and displays the
 * video's title, release date, trust, and a "seen" indicator.
 *
 * The component is a touchable item that calls the onPress when pressed
 *
 * @param {Object} video - The latest video podcast
 * @param {Function} onPress - A function that navigates to the video screen
 * @param {Boolean} isCompact - Whether to use small version or not; 40% width or 100%;
 * @param {Boolean} isRounded - Whether the image borders are rounded
 * @param {Boolean} isTextPadded - Whether the text is padded
 * @param {Boolean} isSeen - Whether to show the "seen" indicator
 */
const VideoHeroCard = ({
  video,
  onPress,
  isCompact,
  isRounded,
  isTextPadded,
  isSeen,
}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <ImageBackground
        imageStyle={[styles.imageStyle, isRounded && {borderRadius: 4}]}
        style={[
          styles.imageBackground,
          {
            aspectRatio: 16 / 9,
            width: isCompact ? '40%' : '100%',
          },
        ]}
        source={{
          uri: video?.Screenshot
            ? `https://ppp-temp-files.s3.amazonaws.com/VideoPodcasts/${video.Screenshot[0].filename}`
            : '',
        }}>
        <View style={styles.playButtonContainer}>
          <Image
            style={styles.playButtonIcon}
            source={require('../assets/Button_Play_White.png')}
          />
        </View>
      </ImageBackground>
      <View style={isTextPadded && {paddingHorizontal: 10}}>
        <Text style={styles.titleText}>{video?.Title}</Text>
        <View style={styles.infoContainer}>
          <Text style={styles.dateText}>
            {moment(video?.ReleaseDate).format('DD MMM YYYY')}
          </Text>
          <View style={styles.separator} />
          <View
            style={[
              styles.trustContainer,
              {
                backgroundColor: DataController.getColorForTrust(video?.Trust),
              },
            ]}>
            <Text style={styles.trustText}>
              {DataController.getTrustDisplayName(video?.Trust)}
            </Text>
          </View>
          {isSeen && (
            <>
              <View style={styles.separator} />
              <Text style={styles.seenText}>Seen</Text>
            </>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  imageStyle: {
    width: '100%',
    height: '100%',
  },
  imageBackground: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'blue',
  },
  playButtonContainer: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 8,
    width: 100,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  playButtonIcon: {
    width: 31,
    height: 40,
  },
  infoContainer: {
    flexDirection: 'row',
    marginTop: 8,
    alignItems: 'center',
  },
  dateText: {
    color: colors.Grey700,
    fontWeight: '500',
    fontSize: 12,
  },
  separator: {
    backgroundColor: 'rgb(121, 139, 147)',
    borderRadius: 1,
    height: 2,
    marginHorizontal: 6,
    width: 2,
  },
  seenText: {
    color: colors.Grey700,
    fontWeight: '500',
  },
  trustContainer: {
    justifyContent: 'center',
    paddingHorizontal: 6,
    height: 16,
    borderRadius: 2,
  },
  trustText: {
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.1,
    color: colors.white,
  },
  titleText: {
    fontSize: 17,
    fontWeight: '600',
    color: colors.Grey900,
    marginTop: 22,
    lineHeight: 20,
  },
});

export default VideoHeroCard;
