import React from 'react';

import {View, Text, TouchableOpacity, Image, StyleSheet} from 'react-native';

import moment from 'moment';

import DataController from '../classes/DataController';
import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';

/**
 * A card component for the grid section of the home screen.
 *
 * This component represents a video podcast and displays the video's
 * title, release date, trust, and a "seen" indicator
 *
 * The component is a touchable item that calls the onPress when pressed
 *
 * @param {string} image - The video's thumbnail image
 * @param {string} title - The video's title
 * @param {Function} onPress - A function that navigates to the video screen
 * @param {number} trust - The video's trust
 * @param {string} date - The video's release date
 * @param {boolean} isSeen - Whether to show the "seen" indicator
 */
const VideoGridCard = ({image, title, onPress, trust, date, isSeen}) => {
  const isTablet = LayoutController.isTablet();
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{flex: 1, flexDirection: 'row', width: '100%'}}>
      <Image
        style={[styles.image]}
        source={{
          uri: image,
        }}
      />
      <View style={styles.textContainer}>
        <Text
          numberOfLines={2}
          style={[styles.titleText, isTablet && styles.titleTextTablet]}>
          {title}
        </Text>
        <Text style={styles.dateText}>
          {moment(date).format('DD MMM YYYY')}
        </Text>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <View
            style={[
              styles.trustContainer,
              {
                backgroundColor: DataController.getColorForTrust(trust),
              },
            ]}>
            <Text style={styles.trustText}>
              {DataController.getTrustDisplayName(trust)}
            </Text>
          </View>
          {isSeen && (
            <>
              <View style={styles.separator} />
              <Text style={styles.seenText}>Seen</Text>
            </>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  image: {
    borderRadius: 3.5,
    width: 171,
    aspectRatio: 16 / 9,
  },
  textContainer: {
    justifyContent: 'space-around',
    paddingLeft: 16,
    flexShrink: 1,
  },
  infoContainer: {
    flexDirection: 'row',
    marginTop: 8,
    alignItems: 'center',
  },
  dateText: {
    color: colors.Grey700,
    fontWeight: '500',
    fontSize: 12,
  },
  separator: {
    backgroundColor: 'rgb(121, 139, 147)',
    borderRadius: 1,
    height: 2,
    marginHorizontal: 6,
    width: 2,
  },
  trustContainer: {
    alignSelf: 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: 6,
    height: 16,
    borderRadius: 2,
  },
  trustText: {
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.1,
    color: colors.white,
  },
  seenText: {
    color: colors.Grey700,
    fontWeight: '500',
  },
  titleTextTablet: {},
  titleText: {
    fontSize: 16,
    fontWeight: '600',
    color: colors.Grey900,
    lineHeight: 20,
  },
});

export default VideoGridCard;
