/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Image,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import LoadingBar from '../components/LoadingBar';
import DynamicText from '../components/DynamicText';
import PromoQuizSection from '../components/Promotion/PromoQuizSection';
import SearchButton from '../components/SearchButton';
import MaxWidthContainer from '../components/MaxWidthContainer';

import DataController from '../classes/DataController';
import PlatformController from '../classes/PlatformController';
import {colors} from '../classes/Colors';

var _ = require('lodash');

type Props = {};

type State = {
  topicQuizSets: Object[],
  guidelineQuizSets: Object[],
  sectionQuizSets: Object[],
};

export default class QuizDashboardScreen extends Component {
  static navigationOptions = ({navigation}) => {
    var leftButtonsLayout = (
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={navigation.getParam('menuButtonTapped')}>
          <Image
            style={{marginLeft: 10, width: 32, height: 32}}
            source={require('../assets/Button_Menu.png')}
          />
        </TouchableOpacity>
      </View>
    );

    return {
      title: 'Quizzes',
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
      headerLeft: leftButtonsLayout,
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      this.state = {
        topicQuizSets: [],
        guidelineQuizSets: [],
        sectionQuizSets: [],
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.props.screenProps.quizSets != null) {
        this.updateQuizSets(this.props.screenProps.quizSets);
      }
    }

    var that = this;

    setTimeout(() => {
      that.props.navigation.setParams({
        menuButtonTapped: that.menuButtonTapped.bind(that),
      });
    }, 100);
  }

  componentDidUpdate(prevProps) {
    if (
      _.isEqual(
        this.props.screenProps.quizSets,
        prevProps.screenProps.quizSets,
      ) === false
    ) {
      this.updateQuizSets(this.props.screenProps.quizSets);
    }
  }

  updateQuizSets(quizSets) {
    var topicQuizSets = [];
    var guidelineQuizSets = [];
    var sectionQuizSets = [];

    for (var quizSetIndex = 0; quizSetIndex < quizSets.length; quizSetIndex++) {
      var quizSet = quizSets[quizSetIndex];

      if (quizSet.type === 'topic') {
        topicQuizSets.push(quizSet);
      } else if (quizSet.type === 'guideline') {
        guidelineQuizSets.push(quizSet);
      } else if (quizSet.type === 'section') {
        sectionQuizSets.push(quizSet);
      }
    }

    guidelineQuizSets = _.orderBy(guidelineQuizSets, ['title'], ['asc']);
    sectionQuizSets = _.orderBy(sectionQuizSets, ['title'], ['asc']);
    topicQuizSets = _.orderBy(topicQuizSets, ['date'], ['desc']);

    this.setState({
      topicQuizSets: topicQuizSets,
      guidelineQuizSets: guidelineQuizSets,
      sectionQuizSets: sectionQuizSets,
    });
  }

  goToQuizType(type: string, filter: string) {
    var quizSets = [];
    var title = '';

    if (type === 'topics') {
      quizSets = this.state.topicQuizSets;
      title = 'Topics';
    } else if (type === 'guidelines') {
      quizSets = this.state.guidelineQuizSets;
      title = 'Guidelines';
    } else if (type === 'sections') {
      quizSets = this.state.sectionQuizSets;
      title = 'Sections';
    }

    let navParams = {
      quizSets: quizSets,
      title: title,
      type: type,
    };

    if (filter != null && filter !== '') {
      navParams.initialFilter = filter;
    }

    this.props.navigation.navigate('QuizSetList', navParams, this.state);
  }

  goToQuizOverview(quizSet: Object) {
    this.props.navigation.navigate(
      'QuizOverview',
      {
        quizSet: quizSet,
        from: 'Quiz Dashboard',
      },
      this.state,
    );
  }

  goToPGDs() {
    var quizSets = [];

    for (
      let quizSetIndex = 0;
      quizSetIndex < this.props.screenProps.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.props.screenProps.quizSets[quizSetIndex];

      if (quizSet.type === 'topic') {
        quizSets.push(quizSet);
      }
    }

    this.props.navigation.navigate(
      'QuizSetList',
      {
        quizSets: quizSets,
        title: 'PGDs',
        type: 'topics',
        initialFilter: 'PGD Quizzes',
      },
      this.state,
    );
  }

  goToPMAs() {
    var quizSets = [];

    for (
      let quizSetIndex = 0;
      quizSetIndex < this.props.screenProps.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.props.screenProps.quizSets[quizSetIndex];

      if (quizSet.type === 'topic') {
        quizSets.push(quizSet);
      }
    }

    this.props.navigation.navigate(
      'QuizSetList',
      {
        quizSets: quizSets,
        title: 'PMAs',
        type: 'topics',
        initialFilter: 'PMA Quizzes',
      },
      this.state,
    );
  }

  menuButtonTapped() {
    this.props.screenProps.showMenu(true);
  }

  searchButtonTapped() {
    console.log('search');
    this.props.navigation.navigate(
      'Search',
      {
        quizSets: this.props.screenProps.quizSets,
        startingFilter: 'Quiz',
      },
      this.state,
    );
  }

  render() {
    var topicQuizSetLayouts = [];
    var guidelineQuizSetLayouts = [];
    var sectionQuizSetLayouts = [];

    var trustTopicsQuizzesAvailable = false;

    for (
      var topicIndex = 0;
      topicIndex < this.props.screenProps.topics.length;
      topicIndex++
    ) {
      var topic = this.props.screenProps.topics[topicIndex];
      if (topic.Type === 'Topic' && topic.Trust != null) {
        trustTopicsQuizzesAvailable = true;
      }
    }

    for (
      var sectionQuizSetIndex = 0;
      sectionQuizSetIndex < this.state.sectionQuizSets.length;
      sectionQuizSetIndex++
    ) {
      var sectionQuizSet = this.state.sectionQuizSets[sectionQuizSetIndex];
      var borderLeftColor = DataController.getPrimaryColorForSection(
        sectionQuizSet.section,
      );

      var quizSetLayout = (
        <TouchableOpacity
          key={sectionQuizSet.name}
          onPress={this.goToQuizOverview.bind(this, sectionQuizSet)}>
          <View
            style={{
              borderLeftWidth: 3,
              borderLeftColor: borderLeftColor,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottomWidth: 1,
              borderBottomColor: colors.Grey100,
            }}>
            <DynamicText
              style={{
                paddingLeft: 10,
                marginVertical: 14,
                fontSize: 17,
                fontWeight: '600',
                color: colors.Grey900,
              }}>
              {sectionQuizSet.name}
            </DynamicText>
            <Image
              style={{marginRight: 10, width: 26, height: 26}}
              source={require('../assets/Button_Disclosure_Right_Blue.png')}
            />
          </View>
        </TouchableOpacity>
      );

      sectionQuizSetLayouts.push(quizSetLayout);
    }

    var pgdLayouts = [];
    var pmaLayout = [];
    var userTrusts = [];
    if (this.props.screenProps.user != null) {
      userTrusts = _.union(
        this.props.screenProps.user.plusTrusts,
        this.props.screenProps.user.paraPassTrusts,
      );
    }

    var trustNoticeLayout = null;

    if (this.props.screenProps.user != null) {
      for (
        let trustIndex = 0;
        trustIndex < this.props.screenProps.user.plusTrusts.length;
        trustIndex++
      ) {
        var trust = this.props.screenProps.user.plusTrusts[trustIndex];

        var pgdQuizSetFound = false;
        var pmaQuizSetFound = false;

        for (
          let quizSetIndex = 0;
          quizSetIndex < this.props.screenProps.quizSets.length;
          quizSetIndex++
        ) {
          var aQuizSet = this.props.screenProps.quizSets[quizSetIndex];
          if (aQuizSet.pgd === true && aQuizSet.pma !== true) {
            pgdQuizSetFound = true;
          }
          if (aQuizSet.pma === true) {
            pmaQuizSetFound = true;
          }
        }

        if (pgdQuizSetFound) {
          pgdLayouts.push(
            <TouchableOpacity
              key={trustIndex}
              activeOpacity={0.5}
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
                paddingLeft: 4,
                paddingRight: 14,
                paddingVertical: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}
              onPress={this.goToPGDs.bind(this)}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 4,
                }}>
                <Image
                  style={{marginLeft: 1, marginTop: 1, width: 40, height: 40}}
                  source={require('../assets/Icon_PGD_Blue.png')}
                />

                <View style={{marginLeft: 8}}>
                  <DynamicText
                    style={{
                      fontSize: 17,
                      fontWeight: '800',
                      marginBottom: 6,
                      letterSpacing: -0.4,
                      color: colors.Grey900,
                    }}>
                    {DataController.getTrustDisplayName(trust)} PGDs
                  </DynamicText>
                  <DynamicText
                    style={{
                      fontSize: 13,
                      letterSpacing: -0.36,
                      color: colors.Grey800,
                    }}>
                    See the latest PGDs from your trust
                  </DynamicText>
                </View>
              </View>

              <Image
                style={{width: 26, height: 26, marginLeft: 4}}
                source={require('../assets/Button_Disclosure_Right_Blue.png')}
              />
            </TouchableOpacity>,
          );
        }

        if (pmaQuizSetFound) {
          pmaLayout.push(
            <TouchableOpacity
              key={trustIndex}
              activeOpacity={0.5}
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 10,
                paddingLeft: 4,
                paddingRight: 14,
                paddingVertical: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}
              onPress={this.goToPMAs.bind(this)}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 4,
                }}>
                <Image
                  style={{marginLeft: 1, marginTop: 1, width: 40, height: 40}}
                  source={require('../assets/Icon_PGD_Blue.png')}
                />

                <View style={{marginLeft: 8}}>
                  <DynamicText
                    style={{
                      fontSize: 17,
                      fontWeight: '800',
                      marginBottom: 6,
                      letterSpacing: -0.4,
                      color: colors.Grey900,
                    }}>
                    {DataController.getTrustDisplayName(trust)} PMAs
                  </DynamicText>
                  <DynamicText
                    style={{
                      fontSize: 13,
                      letterSpacing: -0.36,
                      color: colors.Grey800,
                    }}>
                    See the latest PMAs from your trust
                  </DynamicText>
                </View>
              </View>

              <Image
                style={{width: 26, height: 26, marginLeft: 4}}
                source={require('../assets/Button_Disclosure_Right_Blue.png')}
              />
            </TouchableOpacity>,
          );
        }
      }

      if (
        this.props.screenProps.user.plusTrusts.includes('SWAST') ||
        this.props.screenProps.user.paraPassTrusts.includes('SWAST')
      ) {
        trustNoticeLayout = (
          <View>
            <DynamicText
              style={{
                paddingHorizontal: 10,
                fontSize: 15,
                color: colors.Grey800,
                marginBottom: 20,
              }}>
              For some questions in quizzes, you may need to investigate other
              reputable medical sources for general knowledge around certain
              subjects.
            </DynamicText>
          </View>
        );
      }
    }

    var topicLayout = null;

    if (this.state.topicQuizSets.length > 0) {
      topicLayout = (
        <View
          style={{
            marginBottom: 10,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 1,
            shadowColor: 'black',
            shadowOpacity: 0.2,
            backgroundColor: colors.white,
          }}>
          <View style={{borderBottomWidth: 1, borderColor: colors.Grey100}}>
            <DynamicText
              style={{
                marginHorizontal: 10,
                marginVertical: 16,
                fontSize: 17,
                fontWeight: '800',
                color: colors.Grey900,
              }}>
              Topics
            </DynamicText>
          </View>

          <TouchableOpacity
            onPress={this.goToQuizType.bind(this, 'topics', '')}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderColor: colors.Grey100,
              }}>
              <DynamicText
                style={{
                  paddingLeft: 10,
                  marginVertical: 14,
                  fontSize: 17,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                All Topic Quizzes
              </DynamicText>
              <Image
                style={{marginRight: 10, width: 26, height: 26}}
                source={require('../assets/Button_Disclosure_Right_Blue.png')}
              />
            </View>
          </TouchableOpacity>

          {trustTopicsQuizzesAvailable ? (
            <TouchableOpacity
              onPress={this.goToQuizType.bind(this, 'topics', 'Trust Quizzes')}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}>
                  <DynamicText
                    style={{
                      paddingLeft: 10,
                      marginVertical: 14,
                      fontSize: 17,
                      fontWeight: '600',
                      color: colors.Grey900,
                    }}>
                    Trust Quizzes
                  </DynamicText>
                  {userTrusts.map((userTrust, i) => (
                    <View
                      key={userTrust}
                      style={{
                        justifyContent: 'center',
                        marginLeft: 8,
                        paddingHorizontal: 6,
                        height: 16,
                        borderRadius: 2,
                        backgroundColor:
                          DataController.getColorForTrust(userTrust),
                      }}>
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: '500',
                          letterSpacing: -0.1,
                          color: colors.white,
                        }}>
                        {DataController.getTrustDisplayName(userTrust)}
                      </Text>
                    </View>
                  ))}
                </View>
                <Image
                  style={{marginRight: 10, width: 26, height: 26}}
                  source={require('../assets/Button_Disclosure_Right_Blue.png')}
                />
              </View>
            </TouchableOpacity>
          ) : null}

          <TouchableOpacity
            onPress={this.goToQuizType.bind(this, 'topics', 'JRCALC Quizzes')}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderColor: colors.Grey100,
              }}>
              <DynamicText
                style={{
                  paddingLeft: 10,
                  marginVertical: 14,
                  fontSize: 17,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                JRCALC Quizzes
              </DynamicText>
              <Image
                style={{marginRight: 10, width: 26, height: 26}}
                source={require('../assets/Button_Disclosure_Right_Blue.png')}
              />
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={this.goToQuizType.bind(this, 'topics', 'Special Topics')}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderColor: colors.Grey100,
              }}>
              <DynamicText
                style={{
                  paddingLeft: 10,
                  marginVertical: 14,
                  fontSize: 17,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                Special Topics
              </DynamicText>
              <Image
                style={{marginRight: 10, width: 26, height: 26}}
                source={require('../assets/Button_Disclosure_Right_Blue.png')}
              />
            </View>
          </TouchableOpacity>

          {pgdLayouts.length > 0 ? (
            <TouchableOpacity onPress={this.goToPGDs.bind(this)}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <DynamicText
                    style={{
                      paddingLeft: 10,
                      marginVertical: 14,
                      fontSize: 17,
                      fontWeight: '600',
                      color: colors.Grey900,
                    }}>
                    PGD Quizzes
                  </DynamicText>
                  <View
                    style={{
                      marginLeft: 10,
                      justifyContent: 'center',
                      paddingHorizontal: 6,
                      height: 16,
                      borderRadius: 2,
                      backgroundColor: DataController.getColorForTrust(trust),
                    }}>
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: '500',
                        letterSpacing: -0.1,
                        color: colors.white,
                      }}>
                      {DataController.getTrustDisplayName(trust)}
                    </Text>
                  </View>
                </View>
                <Image
                  style={{marginRight: 10, width: 26, height: 26}}
                  source={require('../assets/Button_Disclosure_Right_Blue.png')}
                />
              </View>
            </TouchableOpacity>
          ) : null}

          {pmaLayout.length > 0 ? (
            <TouchableOpacity onPress={this.goToPMAs.bind(this)}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottomWidth: 1,
                  borderColor: colors.Grey100,
                }}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <DynamicText
                    style={{
                      paddingLeft: 10,
                      marginVertical: 14,
                      fontSize: 17,
                      fontWeight: '600',
                      color: colors.Grey900,
                    }}>
                    PMA Quizzes
                  </DynamicText>
                  <View
                    style={{
                      marginLeft: 10,
                      justifyContent: 'center',
                      paddingHorizontal: 6,
                      height: 16,
                      borderRadius: 2,
                      backgroundColor: DataController.getColorForTrust(trust),
                    }}>
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: '500',
                        letterSpacing: -0.1,
                        color: colors.white,
                      }}>
                      {DataController.getTrustDisplayName(trust)}
                    </Text>
                  </View>
                </View>
                <Image
                  style={{marginRight: 10, width: 26, height: 26}}
                  source={require('../assets/Button_Disclosure_Right_Blue.png')}
                />
              </View>
            </TouchableOpacity>
          ) : null}
        </View>
      );
    }

    var guidelineLayout = null;

    if (this.state.guidelineQuizSets.length > 0) {
      guidelineLayout = (
        <View
          style={{
            marginBottom: 10,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 1,
            shadowColor: 'black',
            shadowOpacity: 0.2,
            backgroundColor: colors.white,
          }}>
          <View style={{borderBottomWidth: 1, borderColor: colors.Grey100}}>
            <DynamicText
              style={{
                marginHorizontal: 10,
                marginVertical: 16,
                fontSize: 17,
                fontWeight: '800',
                color: colors.Grey900,
              }}>
              JRCALC Guidelines
            </DynamicText>
          </View>

          <TouchableOpacity
            onPress={this.goToQuizType.bind(this, 'guidelines', '')}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderColor: colors.Grey100,
              }}>
              <DynamicText
                style={{
                  paddingLeft: 10,
                  marginVertical: 14,
                  fontSize: 17,
                  fontWeight: '600',
                  color: colors.Grey900,
                }}>
                See all
              </DynamicText>
              <Image
                style={{marginRight: 10, width: 26, height: 26}}
                source={require('../assets/Button_Disclosure_Right_Blue.png')}
              />
            </View>
          </TouchableOpacity>
        </View>
      );
    }

    var sectionLayout = null;

    if (this.state.sectionQuizSets.length > 0) {
      sectionLayout = (
        <View
          style={{
            marginBottom: 10,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 1,
            shadowColor: 'black',
            shadowOpacity: 0.2,
            backgroundColor: colors.white,
          }}>
          <View style={{borderBottomWidth: 1, borderColor: colors.Grey100}}>
            <DynamicText
              style={{
                marginHorizontal: 10,
                marginVertical: 16,
                fontSize: 17,
                fontWeight: '800',
                color: colors.Grey900,
              }}>
              Sections
            </DynamicText>
          </View>

          {sectionQuizSetLayouts}
        </View>
      );
    }

    var emptyLayout = null;

    const showEmptyLayout =
      guidelineLayout == null && sectionLayout == null && topicLayout == null;

    if (showEmptyLayout) {
      emptyLayout = (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <DynamicText
            style={{padding: 10, fontSize: 15, color: colors.Grey700}}>
            Don't have a subscription to ParaPass?{' '}
            <Text
              style={{color: colors.ButtonBlue}}
              onPress={this.props.screenProps.openSubscriptionMenu.bind(this)}>
              Subscribe now
            </Text>{' '}
            for access to JRCALC CPD, Standby CPD and Case Scenarios. Contact{' '}
            <Text
              style={{color: colors.ButtonBlue}}
              onPress={() =>
                PlatformController.openURL(
                  'mailto:apps@class.co.uk?subject=ParaPass%20Ambulance%20Trust%20PGD%20Query',
                )
              }>
              apps@class.co.uk
            </Text>{' '}
            for more information.
          </DynamicText>
        </View>
      );
    }

    return (
      <ScreenComponent style={[styles.container]}>
        <LoadingBar screenProps={this.props.screenProps} />
        {emptyLayout}
        <ScrollView
          styles={{flex: 1}}
          contentContainerStyle={{paddingVertical: 10}}>
          <MaxWidthContainer>
            <SearchButton
              onPress={this.searchButtonTapped.bind(this)}
              text="Search quizzes"
            />
            <PromoQuizSection screenProps={this.props.screenProps} />
            {trustNoticeLayout}
            {topicLayout}
            {guidelineLayout}
            {sectionLayout}
          </MaxWidthContainer>
        </ScrollView>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
