/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import QuizCell from '../components/QuizCell';
import QuizSetCell from '../components/QuizSetCell';
import ProgressView from '../components/ProgressView';
import DropDown from '../components/DropDown';
import LoadingBar from '../components/LoadingBar';
import DynamicText from '../components/DynamicText';

import {colors} from '../classes/Colors';
import {firebase} from '../classes/Firebase';
import MaxWidthContainer from '../components/MaxWidthContainer';

var _ = require('lodash');

type Props = {};

type State = {
  quizzes: [],
  quizSets: [],
  quizCount: number,
  sortOptions: [],
  filterOptions: [],
  selectedSortOption: string,
  selectedFilterOption: string,
};

export default class QuizTypeResultsScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: navigation.getParam('title', 'Results'),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      var sortOptions = [
        {value: 'Date - Newest', label: 'Date - Newest First'},
        {value: 'Date - Oldest', label: 'Date - Oldest First'},
        {value: 'A-Z', label: 'A-Z'},
        {value: 'Z-A', label: 'Z-A'},
      ];

      var filterOptions = [
        {value: 'All Types', label: 'All Types'},
        {value: 'Quizzes', label: 'Quizzes'},
        {value: 'Quizzes - Topics', label: 'Quizzes - Topics'},
        {value: 'Quizzes - Guidelines', label: 'Quizzes - Guidelines'},
        {value: 'Quizzes - Sections', label: 'Quizzes - Sections'},
        {value: 'Case Studies', label: 'Case Studies'},
        {value: 'Standby CPD', label: 'Standby CPD'},
      ];

      this.state = {
        typeTitle: this.props.navigation.getParam('title', ''),
        quizzes: this.props.screenProps.quizzes,
        quizSets: this.props.navigation.getParam('quizSets', []),
        questionCount: this.props.navigation.getParam('questionCount', 0),
        quizCount: 0,
        sortOptions: sortOptions,
        selectedSortOption: sortOptions[0],
        filterOptions: filterOptions,
        selectedFilterOption: filterOptions[0],
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.props.screenProps.quizzes != null) {
        this.updateQuizzes(this.props.screenProps.quizzes);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.isEqual(
        this.props.screenProps.quizzes,
        prevProps.screenProps.quizzes,
      ) === false ||
      _.isEqual(
        this.props.screenProps.results,
        prevProps.screenProps.results,
      ) === false
    ) {
      this.updateQuizzes(this.props.screenProps.quizzes);
    }
  }

  updateQuizzes(quizzes) {
    this.setState({quizzes: quizzes}, function () {
      this.filterOptionChanged(this.state.selectedFilterOption);
    });

    var quizCount = 0;

    for (
      let quizSetIndex = 0;
      quizSetIndex < this.state.quizSets.length;
      quizSetIndex++
    ) {
      let quizSet = this.state.quizSets[quizSetIndex];

      for (let quizIndex = 0; quizIndex < quizzes.length; quizIndex++) {
        let quiz = this.props.screenProps.quizzes[quizIndex];

        if (quiz.quizSet === quizSet.name) {
          quizCount++;
        }
      }
    }

    this.setState({quizCount: quizCount});
  }

  sortOptionChanged(selectedOption) {
    var quizSets = this.state.quizSets.slice();

    if (selectedOption.value === 'Date - Newest') {
      quizSets = _.orderBy(this.state.quizSets, ['date'], ['desc']);
    } else if (selectedOption.value === 'Date - Oldest') {
      quizSets = _.orderBy(this.state.quizSets, ['date'], ['asc']);
    } else if (selectedOption.value === 'A-Z') {
      quizSets = _.orderBy(this.state.quizSets, ['title'], ['asc']);
    } else if (selectedOption.value === 'Z-A') {
      quizSets = _.orderBy(this.state.quizSets, ['title'], ['desc']);
    }

    this.setState({quizSets: quizSets, selectedSortOption: selectedOption});

    if (this._scrollView != null && this._scrollView.scrollTo) {
      this._scrollView.scrollTo({x: 0, y: 0, animated: false});
    }

    try {
      firebase.analytics().logEvent('sort_order_changed', {
        page: 'quizTypeResults',
        value: selectedOption.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  filterOptionChanged(selectedOption) {
    var originalQuizzes = this.props.screenProps.quizzes;
    var filteredQuizzes = [];

    for (var quizIndex = 0; quizIndex < originalQuizzes.length; quizIndex++) {
      var quiz = originalQuizzes[quizIndex];

      for (
        var quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.props.screenProps.quizSets[quizSetIndex];

        if (quiz.quizSet === quizSet.name) {
          if (selectedOption.value === 'All Types') {
            filteredQuizzes.push(quiz);
          } else if (
            (quizSet.type === 'topic' ||
              quizSet.type === 'guideline' ||
              quizSet.type === 'section') &&
            selectedOption.value === 'Quizzes'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'topic' &&
            selectedOption.value === 'Quizzes - Topics'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'guideline' &&
            selectedOption.value === 'Quizzes - Guidelines'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'section' &&
            selectedOption.value === 'Quizzes - Sections'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'caseStudy' &&
            selectedOption.value === 'Case Studies'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'standby' &&
            selectedOption.value === 'Standby CPD'
          ) {
            filteredQuizzes.push(quiz);
          }

          break;
        }
      }
    }

    this.setState(
      {selectedFilterOption: selectedOption, quizzes: filteredQuizzes},
      function () {
        this.sortOptionChanged(this.state.selectedSortOption);
      },
    );

    try {
      firebase.analytics().logEvent('filter_changed', {
        page: 'quizTypeResults',
        value: selectedOption.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  keyExtractor(item, index) {
    return item.name;
  }

  renderQuizSet({item}) {
    var quizSet = item;
    var quizSetForQuiz = null;
    var quizzesForQuizSet = [];

    for (
      let quizIndex = 0;
      quizIndex < this.props.screenProps.quizzes.length;
      quizIndex++
    ) {
      let quiz = this.props.screenProps.quizzes[quizIndex];

      if (quiz.quizSet === quizSet.name) {
        quizzesForQuizSet.push(quiz);
      }
    }

    var results = this.props.screenProps.latestResultsForQuizSets[quizSet.name];

    if (results == null) {
      results = [];
    }

    // for (var resultIndex = 0, resultsLength = this.props.screenProps.results?.length; resultIndex < resultsLength; resultIndex++) {

    //   var result = this.props.screenProps.results[resultIndex];

    //   if (result.quizName === quiz.name) {
    //     resultsForQuizSet.push(result);
    //   }
    // }

    return (
      <TouchableOpacity
        key={quizSet.name}
        onPress={this.goToQuizSetResults.bind(
          this,
          quizSet,
          quizzesForQuizSet,
        )}>
        <QuizSetCell
          quizSet={quizSet}
          quizzes={quizzesForQuizSet}
          results={results}
          fontSize={this.props.screenProps.fontSize}
          user={this.props.screenProps.user}
        />
      </TouchableOpacity>
    );
  }

  goToQuizSetResults(quizSet: Object, quizzes: Object[]) {
    this.props.navigation.navigate(
      'QuizSetResults',
      {
        quizSet: quizSet,
      },
      this.state,
    );
  }

  render() {
    var quizSetCount = 0;
    var correctCount = 0;
    var incorrectCount = 0;
    var unansweredCount = 0;
    var totalQuestionCount = 0;
    var percentageText = '0%';

    for (
      var quizSetIndex = 0;
      quizSetIndex < this.state.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.state.quizSets[quizSetIndex];

      // don't include quiz sets that haven't had any quizzes taken
      var quizFoundForQuizSet = false;

      for (
        let quizIndex = 0;
        quizIndex < this.props.screenProps.quizzes.length;
        quizIndex++
      ) {
        let quiz = this.props.screenProps.quizzes[quizIndex];

        if (quiz.quizSet === quizSet.name) {
          quizFoundForQuizSet = true;
          break;
        }
      }

      if (quizFoundForQuizSet === false) {
        continue;
      }

      totalQuestionCount += quizSet.questions.length;
      quizSetCount++;

      var latestResultsForQuizSet =
        this.props.screenProps.latestResultsForQuizSets[quizSet.name];

      if (latestResultsForQuizSet != null) {
        for (
          let resultIndex = 0;
          resultIndex < latestResultsForQuizSet.length;
          resultIndex++
        ) {
          var result = latestResultsForQuizSet[resultIndex];

          if (result.correct) {
            correctCount++;
          } else {
            incorrectCount++;
          }
        }
      }
    }

    if (this.state.questionCount !== 0 && this.state.questionCount != null) {
      totalQuestionCount = this.state.questionCount;
    }

    unansweredCount = totalQuestionCount - correctCount - incorrectCount;

    if (totalQuestionCount > 0) {
      percentageText =
        Math.floor((correctCount / (correctCount + incorrectCount)) * 100) +
        '%';
    }

    var emptyLayout = null;

    if (this.state.quizSets.length === 0) {
      emptyLayout = (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <DynamicText
            style={{padding: 10, fontSize: 15, color: colors.Grey700}}>
            There are no quizzes taken for this category. Complete more quizzes
            to see your results.
          </DynamicText>
        </View>
      );
    }

    var contentLayout = null;

    if (this.state.quizSets.length > 0) {
      contentLayout = (
        <ScrollView ref={ref => (this._scrollView = ref)} style={{flex: 1}}>
          <MaxWidthContainer>
            <View
              style={{
                marginTop: 10,
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <View style={{paddingHorizontal: 10, paddingVertical: 10}}>
                <DynamicText
                  style={{
                    fontSize: 15,
                    letterSpacing: -0.24,
                    color: colors.darkGrey,
                    fontWeight: '700',
                  }}>
                  {this.state.typeTitle} overview
                </DynamicText>

                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 16,
                  }}>
                  <View>
                    <DynamicText
                      style={{
                        fontSize: 12,
                        fontWeight: '700',
                        letterSpacing: -0.31,
                        color: colors.Grey700,
                      }}>
                      Correct
                    </DynamicText>
                    <DynamicText
                      style={{
                        marginTop: 8,
                        fontSize: 34,
                        fontWeight: '700',
                        color: colors.Green500,
                      }}>
                      {correctCount}{' '}
                      <DynamicText
                        style={{
                          fontSize: 15,
                          fontWeight: '600',
                          color: colors.Grey700,
                        }}>
                        ({percentageText})
                      </DynamicText>
                    </DynamicText>
                  </View>
                  <View style={{alignItems: 'flex-end'}}>
                    <DynamicText
                      style={{
                        fontSize: 12,
                        fontWeight: '700',
                        letterSpacing: -0.31,
                        color: colors.Grey700,
                      }}>
                      Incorrect
                    </DynamicText>
                    <DynamicText
                      style={{
                        marginTop: 8,
                        fontSize: 34,
                        fontWeight: '600',
                        color: colors.Red500,
                      }}>
                      {incorrectCount}
                    </DynamicText>
                  </View>
                </View>

                <ProgressView
                  style={{
                    flex: 1,
                    marginTop: 8,
                    height: 4,
                    borderRadius: 2,
                    overflow: 'hidden',
                  }}
                  correctAnswers={correctCount}
                  incorrectAnswers={incorrectCount}
                  unansweredQuestions={0}
                />

                <DynamicText
                  style={{
                    marginTop: 16,
                    fontSize: 12,
                    fontWeight: '700',
                    letterSpacing: -0.31,
                    color: colors.Grey700,
                  }}>
                  Total questions answered
                </DynamicText>
                <DynamicText
                  style={{
                    marginTop: 4,
                    fontSize: 20,
                    fontWeight: '600',
                    color: colors.darkGrey,
                  }}>
                  {correctCount + incorrectCount} of {totalQuestionCount}
                </DynamicText>

                <View
                  style={{
                    width: '50%',
                    height: 1,
                    marginTop: 15,
                    marginBottom: 7,
                    backgroundColor: colors.Grey300,
                  }}
                />

                <DynamicText
                  style={{
                    marginTop: 16,
                    fontSize: 12,
                    fontWeight: '700',
                    letterSpacing: -0.31,
                    color: colors.Grey700,
                  }}>
                  Total quizzes taken
                </DynamicText>

                <DynamicText
                  style={{
                    marginTop: 4,
                    fontSize: 20,
                    fontWeight: '600',
                    color: colors.darkGrey,
                  }}>
                  {this.state.quizCount}
                </DynamicText>
              </View>
            </View>
            <DropDown
              options={this.state.sortOptions}
              selectedOption={this.state.selectedSortOption}
              labelText={'Sort by: ' + this.state.selectedSortOption.value}
              title="Sort Topics by"
              optionChanged={this.sortOptionChanged.bind(this)}
              style={[
                {zIndex: 1},
                Platform.OS === 'web' && {width: 250, marginVertical: 10},
              ]}
            />

            <View
              style={{
                marginBottom: 10,
                shadowOffset: {width: 0, height: 1},
                shadowRadius: 1,
                shadowColor: 'black',
                shadowOpacity: 0.2,
                backgroundColor: colors.white,
              }}>
              <FlatList
                data={this.state.quizSets}
                extraData={
                  (this.props.screenProps.resultsForQuizzes,
                  this.props.screenProps.fontSize)
                }
                keyExtractor={this.keyExtractor.bind(this)}
                renderItem={this.renderQuizSet.bind(this)}
              />
            </View>
          </MaxWidthContainer>
        </ScrollView>
      );
    }

    return (
      <ScreenComponent
        style={[styles.container, {flexDirection: 'column-reverse'}]}>
        {contentLayout}

        {emptyLayout}

        <LoadingBar screenProps={this.props.screenProps} />
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
