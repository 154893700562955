import React from 'react';

import {View, StyleSheet, TouchableOpacity, Dimensions} from 'react-native';

import BookCard from './BookCard';
import BookProgress from './BookProgress';
import DynamicText from './DynamicText';

import {colors} from '../classes/Colors';
import LayoutController from '../classes/LayoutController';
import ReadMoreText from './ReadMoreText';

const striptags = require('striptags');
const he = require('he');

const getBlurbHtml = rawBlurb => {
  if (rawBlurb) {
    const html = striptags(he.decode(rawBlurb));
    return html.trim();
  }

  return null;
};

const BookInfoSection = ({
  book,
  bookStatus,
  progressData,
  readButtonText,
  onReadButtonPress,
  onReadMorePress,
  blurb,
  bookHasQuiz,
}) => {
  const isTablet = LayoutController.isTablet();
  const html = getBlurbHtml(blurb);
  return (
    <View style={styles.container}>
      <View
        style={[
          styles.contentContainer,
          isTablet ? styles.tabletLayout : styles.mobileLayout,
        ]}>
        <View style={[{flex: 1}, isTablet && {marginRight: 15}]}>
          <BookCard
            book={book}
            bookStatus={bookStatus}
            size={isTablet ? 'large' : 'large-full-width'}
            bookHasQuiz={bookHasQuiz}
          />
        </View>
        <View
          style={[styles.detailsContainer, isTablet ? {} : {marginTop: 15}]}>
          <View style={isTablet ? {} : {marginBottom: 15}}>
            {html && (
              <View style={styles.descriptionTextContainer}>
                <ReadMoreText
                  text={html}
                  numberOfLines={2}
                  onPress={onReadMorePress}
                />
              </View>
            )}
            <BookProgress
              data={progressData}
              status={bookStatus}
              hideBorderTop={!html}
            />
          </View>
          <TouchableOpacity
            style={styles.readButton}
            onPress={onReadButtonPress}>
            <DynamicText style={styles.readButtonText}>
              {readButtonText}
            </DynamicText>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    shadowOffset: {width: 0, height: 1},
    shadowRadius: 1,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    backgroundColor: colors.white,
    flexDirection: 'column',
    flex: 1,
  },
  contentContainer: {
    flexDirection: 'column',
    padding: 16,
  },
  tabletLayout: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileLayout: {
    flexDirection: 'column',
  },
  detailsContainer: {
    flex: 1,
    justifyContent: 'space-between',
    flexShrink: 1,
  },
  descriptionTextContainer: {
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    backgroundColor: '#F8F8F8',
  },
  descriptionText: {
    fontSize: 15,
    lineHeight: 18,
  },
  readButton: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
    borderRadius: 7,
    backgroundColor: colors.ButtonBlue,
  },
  readButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: -0.2,
    color: colors.white,
    textAlign: 'center',
    flex: 1,
    paddingVertical: 15,
  },
});

export default BookInfoSection;
