import React from 'react';

import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
} from 'react-native';

import moment from 'moment';

import DataController from '../classes/DataController';
import {colors} from '../classes/Colors';
import TrustTag from './TrustTag';

/**
 * A card component for the rail section of the home screen.
 *
 * This component represents a video podcast and displays the video's
 * title, release date, trust, and a "seen" indicator if the user has
 * watched at least 30 seconds of the video.
 *
 * The component is a touchable item that calls the onPress when pressed
 *
 * @param {string} image - The video's thumbnail image
 * @param {string} title - The video's title
 * @param {Function} onPress - A function that navigates to the video screen
 * @param {number} trust - The video's trust
 * @param {string} date - The video's release date
 * @param {boolean} isSeen - Whether to show the "seen" indicator
 */
const VideoRailCard = ({image, title, onPress, trust, date, isSeen}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{flex: 1, height: 212, width: 277}}>
      <ImageBackground
        imageStyle={[styles.imageStyle]}
        style={[styles.imageBackground]}
        source={{
          uri: image,
        }}
      />
      <Text numberOfLines={1} style={styles.titleText}>
        {title}
      </Text>
      <View style={styles.infoContainer}>
        <Text style={styles.dateText}>
          {moment(date).format('DD MMM YYYY')}
        </Text>
        <View style={styles.separator} />
        <TrustTag trust={trust} />
        {isSeen && (
          <>
            <View style={styles.separator} />
            <Text style={styles.seenText}>Seen</Text>
          </>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  imageStyle: {
    width: '100%',
    height: '100%',
    borderRadius: 3.5,
  },
  imageBackground: {
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: 16 / 9,
    width: '100%',
  },
  infoContainer: {
    flexDirection: 'row',
    marginTop: 8,
    alignItems: 'center',
  },
  dateText: {
    color: colors.Grey700,
    fontWeight: '500',
    fontSize: 12,
  },
  separator: {
    backgroundColor: 'rgb(121, 139, 147)',
    borderRadius: 1,
    height: 2,
    marginHorizontal: 6,
    width: 2,
  },
  trustContainer: {
    justifyContent: 'center',
    paddingHorizontal: 6,
    height: 16,
    borderRadius: 2,
  },
  trustText: {
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: -0.1,
    color: colors.white,
  },
  seenText: {
    color: colors.Grey700,
    fontWeight: '500',
  },
  titleText: {
    fontSize: 17,
    fontWeight: '600',
    color: colors.Grey900,
    marginTop: 14,
    lineHeight: 20,
    minHeight: 20,
  },
});

export default VideoRailCard;
