import React from 'react';

import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import BackgroundContainer from './BackgroundContainer';
import {colors} from '../../classes/Colors';
import {useAcknowledgements} from '../../context/AcknowledgementsContext';
import {logAnalyticsEvent} from '../../utils/firebase';
import {
  EVENT_NAMES,
  BUTTON_EVENTS,
  CONTENT_TYPES,
} from '../../constants/events';
import MaxWidthContainer from '../MaxWidthContainer';

// Acknowledgment
//   {
//     "ContentID": "1",
//     "Title": "test",
//     "TimeStamp": "0000-00-00 00:00:00",
//     "Trust": "NEAS",
//     "Type": "psirf" | 'video'
// }

/**
 * A component that renders an acknowledgment button for a given content.
 *
 * @param {Object} user - The user object containing trust information.
 * @param {string} title - The title of the content to acknowledge.
 * @param {string} contentId - The unique identifier for the content.
 * @param {string} type - The type of content, e.g., 'psirf' or 'video'.

 *
 * The button logs an analytics event and updates the acknowledgments
 * when pressed. It is disabled if the content is already acknowledged.
 */
const Acknowledgment = ({user, title, contentId, type, color}) => {
  return (
    <BackgroundContainer color={color}>
      <MaxWidthContainer>
        <View style={styles.container}></View>
        <AcknowledgmentButton
          user={user}
          title={title}
          contentId={contentId}
          type={type}
        />
      </MaxWidthContainer>
    </BackgroundContainer>
  );
};

export const AcknowledgmentButton = ({user, title, contentId, type}) => {
  const {updateAcknowledgementsHandler, isContentAcknowledged} =
    useAcknowledgements(type);
  const isAcknowledged = isContentAcknowledged(contentId);
  const trust = user?.paraPassTrusts?.[0] || user?.plusTrusts?.[0] || 'N/A';
  return (
    <TouchableOpacity
      style={[styles.button, isAcknowledged && styles.ackButton]}
      onPress={() => {
        logAnalyticsEvent(EVENT_NAMES.button, {
          event: BUTTON_EVENTS.acknowledged,
          content_id: contentId,
          content_type: CONTENT_TYPES[type] || 'unknown',
        });

        updateAcknowledgementsHandler({
          ContentID: contentId,
          TimeStamp: new Date().toISOString(),
          Trust: trust,
          Type: type,
          Title: title,
        });
      }}
      disabled={isAcknowledged}>
      <Text style={[styles.buttonText, isAcknowledged && styles.ackButtonText]}>
        {isAcknowledged ? 'Acknowledged' : 'Acknowledge'}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 'auto',
    width: '100%',
    paddingVertical: 40,
    alignSelf: 'center',
  },
  text: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    textAlign: 'center',
    color: colors.black,
  },
  button: {
    alignSelf: 'center',
    backgroundColor: colors.PrimaryBlue,
    maxWidth: 336,
    width: '100%',
    padding: 14,
    borderRadius: 7,
  },
  buttonText: {
    color: colors.white,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
  ackButton: {
    alignSelf: 'center',
    backgroundColor: '#F1F3F5',
    maxWidth: 336,
    width: '100%',
    padding: 14,
    borderRadius: 7,
  },
  ackButtonText: {
    color: colors.Grey900,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
});

export default Acknowledgment;
