import React, {useState} from 'react';

import {View, Text, StyleSheet, Platform} from 'react-native';

import {containerWidths} from '../../constants/containerWidths';
import LayoutController from '../../classes/LayoutController';
import {colors} from '../../classes/Colors';
import BackgroundContainer from './BackgroundContainer';
import VimeoPlayer from '../VimeoPlayer';
import MaxWidthContainer from '../MaxWidthContainer';

const QuickWatch = ({content, updateVideoHistory}) => {
  const isTablet = LayoutController.isTablet();
  const [video] = content;
  const videoId = video.VideoID;

  if (!videoId) {
    return null;
  }

  const styles = isTablet ? tabletStyles : mobileStyles;
  const videoComponent = (
    <VimeoPlayer
      video={{VideoID: videoId}}
      // Web only at the moment
      width={isTablet ? 433 : '100%'}
      height={isTablet ? 245 : 185}
      updateVideoHistory={updateVideoHistory}
      backgroundColor={colors.PSIRFForeground}
    />
  );

  return (
    <BackgroundContainer color="green">
      <MaxWidthContainer>
        <View style={styles.container}>
          <View key={video.ID} style={styles.item}>
            <View style={styles.textContainer}>
              <Text style={sharedStyles.subtitle}>QUICK WATCH</Text>
              <Text style={sharedStyles.title}>{video.Title}</Text>
              {!isTablet && videoComponent}
            </View>
            {isTablet && <View>{videoComponent}</View>}
          </View>
        </View>
      </MaxWidthContainer>
    </BackgroundContainer>
  );
};
const sharedStyles = StyleSheet.create({
  subtitle: {
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 13.31,
    textAlign: 'left',
    color: 'rgba(255, 255, 255, 0.8)',
    paddingBottom: 4,
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 20.57,
    textAlign: 'left',
    color: colors.white,
    paddingBottom: 20,
  },
  button: {
    margin: 20,
    alignSelf: 'center',
    backgroundColor: colors.white,
    width: '100%',
    padding: 14,
    borderRadius: 7,
    maxWidth: 336,
  },
  buttonText: {
    color: colors.black,
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
  },
});

const mobileStyles = StyleSheet.create({
  container: {
    marginHorizontal: 'auto',
    marginVertical: 10,
    width: '100%',
  },
  textContainer: {},
});

const tabletStyles = StyleSheet.create({
  container: {
    marginHorizontal: 'auto',
    marginVertical: 66,
    width: '100%',
    alignSelf: 'center',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textContainer: {
    flex: 1,
    marginRight: 16,
    maxWidth: 336,
  },
});

export default QuickWatch;
