import React from 'react';

import {View, StyleSheet} from 'react-native';
import {containerWidths} from '../constants/containerWidths';

/**
 * A container component that wraps its children with a maximum width.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components to be wrapped inside the container.
 * @param {Object} [props.contentContainerStyle={}] - Additional styles to apply to the container.
 */
const MaxWidthContainer = ({
  children,
  fullWidth,
  contentContainerStyle = {},
}) => {
  const width = fullWidth ? '100%' : '96%';
  return (
    <View style={[styles.container, {width}, contentContainerStyle]}>
      {children}
    </View>
  );
};

export default MaxWidthContainer;

const styles = StyleSheet.create({
  container: {
    maxWidth: containerWidths.maxWidthContainer,
    alignSelf: 'center',
  },
});
