/**
 * PPP
 * @flow
 */
'use strict';
import React, {Component} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  Image,
  FlatList,
} from 'react-native';

import ScreenComponent from '../components/ScreenComponent';
import QuizCell from '../components/QuizCell';
import DropDown from '../components/DropDown';
import DynamicText from '../components/DynamicText';
import MaxWidthContainer from '../components/MaxWidthContainer';

import {colors} from '../classes/Colors';
import {firebase} from '../classes/Firebase';

var _ = require('lodash');

type Props = {};

type State = {
  quizzes: [],
  sortOptions: [],
  filterOptions: [],
  selectedSortOption: string,
  selectedFilterOption: string,
};

export default class QuizListScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: navigation.getParam('title', ''),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props: Props) {
    super(props);

    if (_.isEmpty(this.props.initialState)) {
      var sortOptions = [
        {value: 'Date - Newest', label: 'Date - Newest First'},
        {value: 'Date - Oldest', label: 'Date - Oldest First'},
        {value: 'A-Z', label: 'A-Z'},
        {value: 'Z-A', label: 'Z-A'},
      ];

      var filterOptions = [
        {value: 'All Types', label: 'All Types'},
        {value: 'Quizzes', label: 'Quizzes'},
        {value: 'Quizzes - Topics', label: 'Quizzes - Topics'},
        {value: 'Quizzes - Guidelines', label: 'Quizzes - Guidelines'},
        {value: 'Quizzes - Sections', label: 'Quizzes - Sections'},
        {value: 'Case Studies', label: 'Case Studies'},
        {value: 'Standby CPD', label: 'Standby CPD'},
      ];

      this.state = {
        quizzes: this.props.navigation.getParam('quizzes', []),
        sortOptions: sortOptions,
        selectedSortOption: sortOptions[0],
        filterOptions: filterOptions,
        selectedFilterOption: filterOptions[0],
      };
    } else {
      this.state = this.props.initialState;
    }
  }

  componentDidMount() {
    if (_.isEmpty(this.props.initialState)) {
      // console.log('Did mount');
      if (this.state.quizzes != null) {
        this.filterOptionChanged(this.state.selectedFilterOption);
      }
    }
  }

  sortOptionChanged(selectedOption) {
    var quizzes = this.state.quizzes.slice();

    if (selectedOption.value === 'Date - Newest') {
      quizzes = _.orderBy(this.state.quizzes, ['time'], ['desc']);
    } else if (selectedOption.value === 'Date - Oldest') {
      quizzes = _.orderBy(this.state.quizzes, ['time'], ['asc']);
    } else if (selectedOption.value === 'A-Z') {
      quizzes = _.orderBy(this.state.quizzes, ['name'], ['asc']);
    } else if (selectedOption.value === 'Z-A') {
      quizzes = _.orderBy(this.state.quizzes, ['name'], ['desc']);
    }

    this.setState({quizzes: quizzes, selectedSortOption: selectedOption});

    if (this._scrollView != null && this._scrollView.scrollTo) {
      this._scrollView.scrollTo({x: 0, y: 0, animated: false});
    }

    try {
      firebase.analytics().logEvent('sort_order_changed', {
        page: 'quizList',
        value: selectedOption.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  filterOptionChanged(selectedOption) {
    var originalQuizzes = this.props.navigation.getParam('quizzes', []);
    var filteredQuizzes = [];

    for (var quizIndex = 0; quizIndex < originalQuizzes.length; quizIndex++) {
      var quiz = originalQuizzes[quizIndex];

      for (
        var quizSetIndex = 0;
        quizSetIndex < this.props.screenProps.quizSets.length;
        quizSetIndex++
      ) {
        var quizSet = this.props.screenProps.quizSets[quizSetIndex];

        if (quiz.quizSet === quizSet.name) {
          if (selectedOption.value === 'All Types') {
            filteredQuizzes.push(quiz);
          } else if (
            (quizSet.type === 'topic' ||
              quizSet.type === 'guideline' ||
              quizSet.type === 'section') &&
            selectedOption.value === 'Quizzes'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'topic' &&
            selectedOption.value === 'Quizzes - Topics'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'guideline' &&
            selectedOption.value === 'Quizzes - Guidelines'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'section' &&
            selectedOption.value === 'Quizzes - Sections'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'caseStudy' &&
            selectedOption.value === 'Case Studies'
          ) {
            filteredQuizzes.push(quiz);
          } else if (
            quizSet.type === 'standby' &&
            selectedOption.value === 'Standby CPD'
          ) {
            filteredQuizzes.push(quiz);
          }

          break;
        }
      }
    }

    this.setState(
      {selectedFilterOption: selectedOption, quizzes: filteredQuizzes},
      function () {
        this.sortOptionChanged(this.state.selectedSortOption);
      },
    );

    try {
      firebase.analytics().logEvent('filter_changed', {
        page: 'quizList',
        value: selectedOption.value,
      });
    } catch (error) {
      console.log(error);
    }
  }

  keyExtractor(item, index) {
    return item.name;
  }

  renderQuiz({item}) {
    var quiz = item;
    var quizSetForQuiz = null;
    var inProgress = false;

    var results = this.props.screenProps.resultsForQuizzes[quiz.name];

    if (results == null) {
      return <View />;
    }

    for (
      var quizSetIndex = 0;
      quizSetIndex < this.props.screenProps.quizSets.length;
      quizSetIndex++
    ) {
      var quizSet = this.props.screenProps.quizSets[quizSetIndex];

      if (quizSet.name === quiz.quizSet) {
        quizSetForQuiz = quizSet;
        if (
          this.props.screenProps.inProgressQuizzes.find(
            aQuizSet => aQuizSet.quizSet === quizSet.name,
          )
        ) {
          inProgress = true;
        }
        break;
      }
    }

    return (
      <TouchableOpacity
        key={quiz.name}
        onPress={this.goToQuiz.bind(this, quiz, quizSetForQuiz, results)}>
        <QuizCell
          quiz={quiz}
          quizSet={quizSetForQuiz}
          results={results}
          inProgress={inProgress}
          fontSize={this.props.screenProps.fontSize}
          user={this.props.screenProps.user}
        />
      </TouchableOpacity>
    );
  }

  goToQuizResults(quiz: Object, quizSet: Object) {
    var resultsForQuiz = [];

    for (
      var resultIndex = 0,
        resultsLength = this.props.screenProps.results?.length;
      resultIndex < resultsLength;
      resultIndex++
    ) {
      var result = this.props.screenProps.results[resultIndex];

      if (result.quizName === quiz.name) {
        resultsForQuiz.push(result);
      }
    }

    var questionsForQuiz = [];

    for (
      var quizQuestionIndex = 0;
      quizQuestionIndex < quiz.questions.length;
      quizQuestionIndex++
    ) {
      var quizQuestionName = quiz.questions[quizQuestionIndex];

      for (
        var questionIndex = 0,
          questionsLength = this.props.screenProps.questions.length;
        questionIndex < questionsLength;
        questionIndex++
      ) {
        var question = this.props.screenProps.questions[questionIndex];

        if (question.Name === quizQuestionName) {
          questionsForQuiz.push(question);
        }
      }
    }

    this.props.navigation.navigate(
      'QuizResults',
      {
        quiz: quiz,
        quizSet: quizSet,
        questions: questionsForQuiz,
        results: resultsForQuiz,
      },
      this.state,
    );
  }

  goToQuiz(quiz: Object, quizSet: Object, results: []) {
    var questionsForQuiz = [];
    var currentQuestion = null;
    var currentQuestionNumber = 1;
    var questionNumbersAnswered = [];
    var questionNumbersViewed = [];
    var correctCount = 0;
    var incorrectCount = 0;

    for (
      var quizQuestionIndex = 0;
      quizQuestionIndex < quiz.questions.length;
      quizQuestionIndex++
    ) {
      var quizQuestionName = quiz.questions[quizQuestionIndex];
      var questionForQuiz = null;

      // add full question to quiz
      for (
        var questionIndex = 0,
          questionsLength = this.props.screenProps.questions.length;
        questionIndex < questionsLength;
        questionIndex++
      ) {
        var question = this.props.screenProps.questions[questionIndex];

        if (question.Name === quizQuestionName) {
          questionsForQuiz.push(question);
          questionForQuiz = question;
        }
      }

      var resultFound = false;

      for (let resultIndex = 0; resultIndex < results?.length; resultIndex++) {
        var result = results[resultIndex];
        var resultQuestionName = result.packName + '-' + result.questionID;

        if (
          result.quizName === quiz.name &&
          resultQuestionName === quizQuestionName
        ) {
          resultFound = true;

          if (result.correct) {
            correctCount++;
          } else {
            incorrectCount++;
          }

          questionNumbersAnswered.push(quizQuestionIndex + 1);
          questionNumbersViewed.push(quizQuestionIndex + 1);
        }
      }

      if (resultFound === false && currentQuestion == null) {
        currentQuestionNumber = quizQuestionIndex + 1;
        currentQuestion = questionForQuiz;
      }
    }

    let title = this.props.navigation.getParam('title', '');
    let from = title !== '' ? 'List - ' + title : 'List';

    this.props.navigation.navigate(
      'Quiz',
      {
        quiz: quiz,
        quizSet: quizSet,
        questions: questionsForQuiz,
        currentQuestion: currentQuestion,
        currentQuestionNumber: currentQuestionNumber,
        questionNumbersAnswered: questionNumbersAnswered,
        questionNumbersViewed: questionNumbersViewed,
        correctCount: correctCount,
        incorrectCount: incorrectCount,
        comingFromOverviewScreen: false,
        from: from,
      },
      this.state,
    );
  }

  render() {
    var sortDropdownStyle = {};

    if (Platform.OS === 'web') {
      sortDropdownStyle = {width: 250, marginVertical: 10};
    }

    var emptyLayout = null;

    if (this.state.quizzes.length === 0) {
      emptyLayout = (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <DynamicText
            style={{padding: 10, fontSize: 15, color: colors.Grey700}}>
            There are no quizzes matching your filter. Complete more quizzes to
            see your results.
          </DynamicText>
        </View>
      );
    }

    return (
      <ScreenComponent
        style={[styles.container, {flexDirection: 'column-reverse'}]}>
        <ScrollView
          style={{flex: 1}}
          contentContainerStyle={{
            flexGrow: 1,
            alignItems: 'center',
            width: '100%',
            paddingBottom: 20,
          }}
          ref={ref => (this._scrollView = ref)}>
          <MaxWidthContainer>
            {this.state.quizzes.map((quiz, index) => (
              <View key={this.keyExtractor(quiz, index)}>
                {this.renderQuiz({item: quiz, index})}
              </View>
            ))}
          </MaxWidthContainer>
        </ScrollView>

        {emptyLayout}

        <MaxWidthContainer>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <DropDown
              options={this.state.sortOptions}
              selectedOption={this.state.selectedSortOption}
              labelText={'Sort by: ' + this.state.selectedSortOption.value}
              title="Sort Topics by"
              optionChanged={this.sortOptionChanged.bind(this)}
              style={sortDropdownStyle}
            />
            <DropDown
              options={this.state.filterOptions}
              selectedOption={this.state.selectedFilterOption}
              labelText={'Filter: ' + this.state.selectedFilterOption.value}
              title="Filter quizzes by"
              optionChanged={this.filterOptionChanged.bind(this)}
              style={sortDropdownStyle}
            />
          </View>
        </MaxWidthContainer>
      </ScreenComponent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
});
