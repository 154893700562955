import React from 'react';
import {View, Text, Platform, Image} from 'react-native';
import ProgressView from './ProgressView';
import {colors} from '../classes/Colors';
import DynamicText from '../components/DynamicText';
import TrustTag from './TrustTag';

const BookCell = ({
  bookName = '',
  chapterNumber = '',
  sectionName = '',
  chapterName = '',
  searchText = '',
  titleMatch = false,
  pageMatches = 0,
  trusts = [],
}) => {
  const name = sectionName || chapterName;

  const renderHighlightedName = () => {
    if (!name || !searchText) return <Text>{name}</Text>;

    const highlightedParts = name.toLowerCase().split(searchText.toLowerCase());

    let index = 0;
    const layout = highlightedParts.map((part, idx) => {
      const elements = [];
      if (idx !== 0) {
        const highlight = name.substr(index, searchText.length);
        elements.push(
          <Text key={`highlight-${idx}`} style={{backgroundColor: '#DDEBFD'}}>
            {highlight}
          </Text>,
        );
        index += searchText.length;
      }
      const nonHighlight = name.substr(index, part.length);
      elements.push(<Text key={`non-highlight-${idx}`}>{nonHighlight}</Text>);
      index += part.length;
      return elements;
    });
    return layout.flat();
  };

  const getMatchingText = () => {
    let text = '';
    if (titleMatch) text += 'Title match';
    if (pageMatches > 0) {
      if (text) text += ' & ';
      text += `${pageMatches} page ${pageMatches === 1 ? 'match' : 'matches'}`;
    }
    return text;
  };

  const matchingText = getMatchingText();

  return (
    <View
      style={{
        paddingLeft: 10,
        paddingRight: 6,
        paddingVertical: 15,
        borderBottomWidth: 1,
        borderColor: colors.Grey100,
      }}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <View style={{flex: 1}}>
          <View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Image
                style={{width: 24, height: 24}}
                source={require('../assets/Icon_ParaPass_Books_Blue.png')}
              />
              {trusts?.map((trust, index) => (
                <>
                  <View
                    style={{
                      marginHorizontal: 9,
                      width: 2,
                      height: 2,
                      backgroundColor: colors.Grey700,
                      borderRadius: 1,
                    }}
                  />
                  <TrustTag key={index} trust={trust} />
                </>
              ))}
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 4,
              }}>
              <DynamicText
                numberOfLines={1}
                style={{
                  flexShrink: 1,
                  fontSize: 12,
                  fontWeight: '600',
                  color: '#00736d',
                }}>
                {bookName}
              </DynamicText>
              <View
                style={{
                  marginHorizontal: 6,
                  width: 2,
                  height: 2,
                  backgroundColor: colors.Grey700,
                  borderRadius: 1,
                }}
              />
              <DynamicText
                style={{
                  marginRight: 10,
                  fontSize: 12,
                  fontWeight: '600',
                  color: colors.Grey800,
                }}>
                Chapter {chapterNumber}
              </DynamicText>
            </View>

            <View style={{flexDirection: 'row', marginTop: 4}}>
              <View style={{flex: 1}}>
                <DynamicText
                  style={{
                    flex: 1,
                    fontSize: 19,
                    fontWeight: '600',
                    letterSpacing: -0.04,
                    color: colors.Grey900,
                  }}>
                  {renderHighlightedName()}
                </DynamicText>
                {matchingText ? (
                  <DynamicText
                    style={{
                      flex: 1,
                      marginTop: 8,
                      fontSize: 15,
                      fontWeight: '600',
                      letterSpacing: -0.04,
                      color: colors.Grey700,
                    }}>
                    {matchingText}
                  </DynamicText>
                ) : null}
              </View>
              <Image
                style={{width: 26, height: 26}}
                source={require('../assets/Button_Disclosure_Right_Blue.png')}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default BookCell;
