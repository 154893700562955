import React from 'react';
import {View, Text, StyleSheet, Image, TouchableOpacity} from 'react-native';
import BackgroundContainer from './BackgroundContainer';
import {useUpVotes} from '../../context/UpVotesContext';
import MaxWidthContainer from '../MaxWidthContainer';
import {colors} from '../../classes/Colors';

const getNotVotedComponent = voteType => {
  return voteType === 'down' ? (
    <Image
      style={styles.image}
      source={require('../../assets/thumb_down_outline.png')}
    />
  ) : (
    <Image
      style={styles.image}
      source={require('../../assets/thumb_up_outline.png')}
    />
  );
};

const getUpVoteComponent = (upVote, voteType) => {
  if (upVote === true && voteType === 'up') {
    return (
      <Image
        style={styles.image}
        source={require('../../assets/thumb_up_filled.png')}
      />
    );
  } else if (upVote === false && voteType === 'down') {
    return (
      <Image
        style={styles.image}
        source={require('../../assets/thumb_down_filled.png')}
      />
    );
  }

  return getNotVotedComponent(voteType);
};

const UpTicks = ({content, psirfMetaData, user}) => {
  const contentType = 'psirf';
  const contentId = psirfMetaData.psirfId;
  const trust = user.paraPassTrusts[0] || user.plusTrusts[0] || '';
  const {isUpVote, updateUpVotesHandler} = useUpVotes(contentType);
  const upVoteForPsirf = isUpVote(psirfMetaData.psirfId);

  const voteUpHandler = () => {
    updateUpVotesHandler(contentId, true, contentType, trust);
  };
  const voteDownHandler = () => {
    updateUpVotesHandler(contentId, false, contentType, trust);
  };

  if (!content) {
    return null;
  }

  return (
    <BackgroundContainer color="white">
      <MaxWidthContainer>
        <View style={styles.container}>
          <Text style={styles.text}>Did you find this case study helpful?</Text>
          <View style={styles.iconContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={voteUpHandler}
              disabled={upVoteForPsirf}>
              {getUpVoteComponent(upVoteForPsirf, 'up')}
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button}
              onPress={voteDownHandler}
              disabled={!upVoteForPsirf}>
              {getUpVoteComponent(upVoteForPsirf, 'down')}
            </TouchableOpacity>
          </View>
        </View>
      </MaxWidthContainer>
    </BackgroundContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 11,
    marginHorizontal: 'auto',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 17,
    fontWeight: '700',
    lineHeight: 20.57,
    textAlign: 'center',
    color: colors.black,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 27,
  },
  button: {
    paddingHorizontal: 30,
  },
  image: {
    width: 46,
    height: 40,
  },
});

export default UpTicks;
