import React, {Component} from 'react';

import {View, Platform, StyleSheet, ScrollView} from 'react-native';

import {colors} from '../classes/Colors';

import VimeoPlayer from '../components/VimeoPlayer';
import HTMLRenderer from '../components/HTMLRenderer';
import ScreenComponent from '../components/ScreenComponent';
import AMImageViewer from '../components/AMImageViewer';

import {containerWidths} from '../constants/containerWidths';
import MaxWidthContainer from '../components/MaxWidthContainer';

const tagStyles = {
  p: {
    color: colors.Grey900,
    fontSize: 15,
  },
  h1: {
    color: colors.Grey900,
    fontSize: 32,
    fontWeight: '700',
  },
  h2: {
    color: colors.Grey900,
    fontSize: 24,
    fontWeight: '700',
  },
  h3: {
    color: colors.Grey900,
    fontSize: 18,
    fontWeight: '700',
  },
  h4: {
    color: colors.Grey900,
    fontSize: 16,
    fontWeight: '700',
  },
  h5: {
    color: colors.Grey900,
    fontSize: 14,
    fontWeight: '700',
  },
  h6: {
    color: colors.Grey900,
    fontSize: 12,
    fontWeight: '700',
  },
  li: {
    color: colors.Grey900,
    fontSize: 15,
  },
};

class BookBlurbScreen extends Component {
  static navigationOptions = ({navigation}) => {
    return {
      title: navigation.getParam('title', 'Blurb'),
      headerStyle: {
        backgroundColor: colors.NavBar,
      },
      headerTintColor: colors.white,
      headerTitleStyle: {
        fontFamily: 'InterUI-Regular',
        fontSize: 17,
        fontWeight: '600',
        letterSpacing: -0.41,
        color: colors.white,
      },
    };
  };

  constructor(props) {
    super(props);

    this.state = {
      blurb: this.props.navigation.getParam('blurb', {}),
      imageViewerVisible: false,
      imageModalPath: '',
    };
  }

  openImageModal = imagePath => {
    const updatedImagePath =
      Platform.OS === 'android'
        ? imagePath.replace('file:///android_asset/', 'asset:/')
        : imagePath;

    this.setState({imageViewerVisible: true, imageModalPath: updatedImagePath});
  };

  closeImageModal = () => {
    this.setState({imageViewerVisible: false});
  };

  render() {
    const {blurb, videoId} = this.state.blurb || {};
    return (
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollContent}>
        <MaxWidthContainer>
          <View style={styles.container}>
            {videoId && (
              <VimeoPlayer video={{VideoID: videoId}} width={'100%'} />
            )}
            {blurb && (
              <HTMLRenderer
                html={blurb}
                onPressImage={this.openImageModal}
                imageStyles={{aspectRatio: 567 / 857}}
                tagStyles={tagStyles}
              />
            )}
            {this.state.imageViewerVisible && (
              <AMImageViewer
                visible={this.state.imageViewerVisible}
                imageUrls={[{url: this.state.imageModalPath, props: {}}]}
                onClose={this.closeImageModal}
              />
            )}
          </View>
        </MaxWidthContainer>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    backgroundColor: colors.Grey100,
  },
  scrollContent: {
    flexGrow: 1,
    alignItems: 'center',
  },
  container: {
    backgroundColor: colors.white,
    borderRadius: 5,
    marginVertical: 10,
    alignSelf: 'stretch',
  },
});

export default BookBlurbScreen;
