/**
 * PPP
 * @flow
 */
'use strict';
import React, {useState, useRef, useEffect} from 'react';
import {StyleSheet, Text, View, ScrollView, Image} from 'react-native';
import ScreenComponent from '../components/ScreenComponent';
import {colors} from '../classes/Colors';
import MaxWidthContainer from '../components/MaxWidthContainer';

export default function PodcastScreen(props) {
  const pod = props.navigation.getParam('podcast', props.podcast);
  const audioHistory = props.navigation.getParam(
    'audioHistory',
    props.audioHistory,
  );
  const updateAudioHistory = props.navigation.getParam(
    'updateAudioHistory',
    props.updateAudioHistory,
  );

  const audioRef = useRef();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (audioRef.current && !audioRef.current.paused) {
        updateAudioHistory(pod.Id, audioRef.current.currentTime);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (audioRef.current && !initialized) {
      const trackIndexInHistory = audioHistory.findIndex(
        item => item.audioId === pod.Id,
      );
      if (trackIndexInHistory !== -1) {
        audioRef.current.currentTime =
          audioHistory[trackIndexInHistory].currentTime;
      }
      setInitialized(true);
    }
  });

  return (
    <ScreenComponent style={[styles.container]}>
      <ScrollView style={{width: '100%'}}>
        <MaxWidthContainer>
          <View
            style={{
              paddingHorizontal: 28,
              paddingTop: 22,
              width: '100%',
            }}>
            <Image
              style={{
                width: '100%',
                height: 200,
                borderRadius: 6,
              }}
              source={{uri: pod.imageUrl}}
            />
          </View>
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
              color: colors.Grey900,
              paddingVertical: 8,
              marginTop: 12,
            }}>
            {pod.Title}
          </Text>
          <audio
            style={{width: '100%', marginTop: 12, marginBottom: 12}}
            controls
            ref={audioRef}
            src={pod.audioUrl}
          />
          <div
            style={{
              fontSize: 17,
              color: colors.Grey900,
              fontFamily: 'InterUI-Regular',
            }}
            dangerouslySetInnerHTML={{
              __html: pod.Content,
            }}
          />
        </MaxWidthContainer>
      </ScrollView>
    </ScreenComponent>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
});
