import React, {useState, useEffect} from 'react';
import {View, Text, StyleSheet, useWindowDimensions} from 'react-native';

const ResponsiveGrid = ({items, maxWidthPercentage = 100}) => {
  const {width} = useWindowDimensions();
  const [numColumns, setNumColumns] = useState(2); // Default to two columns

  useEffect(() => {
    // Update the number of columns based on screen width
    if (width < 720) {
      setNumColumns(1); // Single column for small screens
    } else {
      setNumColumns(2); // Two columns for larger screens
    }
  }, [width]);

  return (
    <View style={styles.container}>
      {items.map((item, index) => (
        <View
          key={index}
          style={[
            styles.item,
            {
              flexBasis: `${maxWidthPercentage / numColumns}%`,
              maxWidth: `${maxWidthPercentage / numColumns}%`,
            },
          ]}>
          {item}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap', // Allow items to wrap onto new rows
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default ResponsiveGrid;
