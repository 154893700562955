import React from 'react';

import {View, Text, StyleSheet, Image, Dimensions} from 'react-native';

import {getBookStatusColor} from '../utils/books';
import BookProgress from './BookProgress';
import LayoutController from '../classes/LayoutController';
import {colors} from '../classes/Colors';

// type Book = {
//   ID: String,
//   byline: String,
//   chapters: [],
//   coverImage: [],
//   coverImageSource: String,
//   coverImageUrl: String,
//   id: String,
//   jsonFile: String,
//   name: String,
//   primaryColor: String,
//   secondaryColor: String,
//   trusts: Array<String>,
// };

const getContainerSize = layoutType => {
  switch (layoutType) {
    case 'small':
      return {maxWidth: 277, height: 171};
    case 'large':
      return {maxWidth: 442, height: 223};
    case 'small-full-width':
      return {width: '100%', height: 171};
    case 'large-full-width':
      return {width: '100%', height: 217};
    default:
      return {width: '100%', height: 217};
  }
};

const getImageSize = layoutType => {
  switch (layoutType) {
    case 'small':
      return {width: 106, height: 'auto'};
    case 'large':
      return {width: 139, height: 201};
    case 'small-full-width':
      return {width: 106, height: 'auto'};
    case 'large-full-width':
      return {width: 139, height: 201};
    default:
      return {width: '38%', height: 'auto'};
  }
};

/**
 * Renders a card component displaying information about a book.
 *
 * @param {Object} book - The book object containing details such as cover image source and name.
 * @param {string} bookStatus - The current status of the book (e.g., 'Read', 'In Progress').
 * @param {string} [size='small'] - The size of the card, affecting its dimensions. Can be 'large' or 'small'
 * @param {boolean} showProgressBar - Whether to display a progress bar for the book.
 * @param {Object} bookProgress - Contains progress details, including chapter number, name, and percentage.
 * @param {boolean} bookHasQuiz - Whether the book has a quiz (use doesBookHaveQuiz())
 */
const BookCard = ({
  book,
  bookStatus,
  size = 'small',
  showProgressBar,
  bookProgress,
  bookHasQuiz,
}) => {
  const containerSize = getContainerSize(size);
  const imageSize = getImageSize(size);
  // Not yet needed, save for next update
  // const videoLink = findBookVideoLink(book);
  return (
    <View
      style={[
        showProgressBar && {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      ]}>
      <View style={[styles.container, containerSize]}>
        <Image
          resizeMode={'contain'}
          source={book.coverImageSource}
          style={imageSize}
        />
        <View style={styles.textContainer}>
          {bookStatus && (
            <Text
              style={[
                styles.bookStatus,
                {color: getBookStatusColor(bookStatus)},
              ]}>
              {bookStatus}
            </Text>
          )}
          <Text style={styles.title}>{book.name}</Text>
          <View style={styles.iconContainer}>
            {/* {videoLink && (
              <Image
                source={require('../assets/books/video_icon.png')}
                style={{height: 18, width: 24}}
              />
            )} */}
            {bookHasQuiz && (
              <Image
                source={require('../assets/books/quiz_icon.png')}
                style={{height: 18, width: 18}}
              />
            )}
          </View>
        </View>
      </View>
      {showProgressBar && bookProgress && (
        <BookProgress data={bookProgress} status={bookStatus} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: '#F8F8F8',
    padding: 10,
    borderRadius: 5,
  },
  title: {
    color: colors.black,
    fontWeight: '700',
    fontSize: 15,
  },
  bookStatus: {
    fontSize: 11,
    fontWeight: '600',
    lineHeight: 13,
  },
  textContainer: {
    flexShrink: 1,
    alignSelf: 'center',
    padding: 10,
  },
  iconContainer: {
    marginTop: 8,
    flexDirection: 'row',
    gap: 4,
  },
});

export default BookCard;
