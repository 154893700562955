import React from 'react';

import {View, Text, Image, StyleSheet, TouchableOpacity} from 'react-native';

import TrustTag from '../TrustTag';

import {colors} from '../../classes/Colors';
import {useAcknowledgements} from '../../context/AcknowledgementsContext';

const InfoBar = ({value, isAcknowledged, trust}) => (
  <View style={[infoStyles.infoBar]}>
    <Text style={infoStyles.infoBarValue}>{`PSIRF`}</Text>
    <Text style={infoStyles.infoBarValue}>{` \u2022`}</Text>
    <Text style={infoStyles.infoBarValue}>{` ${value}`}</Text>
    <Text style={infoStyles.infoBarValue}>{` \u2022`}</Text>
    <Text
      style={[infoStyles.infoBarValue, !isAcknowledged && {color: '#ED242B'}]}>
      {isAcknowledged ? ' Acknowledged' : ' Unacknowledged'}
    </Text>
    <Text style={infoStyles.infoBarValue}>{` \u2022 `}</Text>
    <TrustTag trust={trust} />
  </View>
);

const PSIRFSearchCard = ({title, onPress, id, date, trust}) => {
  const {isContentAcknowledged} = useAcknowledgements('psirf');

  const formattedDate = new Date(date).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <View>
          <InfoBar
            value={formattedDate}
            isAcknowledged={isContentAcknowledged(id)}
            trust={trust}
          />
          <View style={styles.content}>
            <View style={styles.textContainer}>
              <Text ellipsizeMode="tail" numberOfLines={2} style={styles.title}>
                {title}
              </Text>
            </View>
          </View>
        </View>
        <Image
          style={{width: 26, height: 26}}
          source={require('../../assets/Button_Disclosure_Right_Blue.png')}
        />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 12,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#DEE2E6',
    flexDirection: 'row',
    backgroundColor: colors.white,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    fontSize: 17,
    fontWeight: '600',
    lineHeight: 23.97,
    paddingRight: 8,
    paddingBottom: 0,
  },
  subtitle: {
    fontSize: 15,
    fontWeight: '400',
    lineHeight: 18.15,
    color: '#6D7378',
  },
  image: {
    width: 80,
    height: 80,
    objectFit: 'cover',
    marginLeft: 12,
  },
});

const infoStyles = StyleSheet.create({
  infoBar: {
    flexDirection: 'row',
    paddingBottom: 4,
  },
  infoBarTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  infoBarValue: {
    fontSize: 12,
    color: '#636669',
    fontWeight: '600',
    lineHeight: 14.52,
  },
});

export default PSIRFSearchCard;
