/**
 * PPP
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import {Platform} from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import localforage from 'localforage';

import RNFS from './RNFS';
import RNFetchBlob from './RNFetchBlob';
import {colors} from './Colors';
import RemoteDataController from './RemoteDataController';

var _ = require('lodash');

export default {
  async saveDataToFile(data, fileName) {
    try {
      if (Platform.OS === 'web') {
        await localforage.setItem(fileName, data);
        console.log(`Data saved to ${fileName}`);
      } else {
        const path = RNFS.DocumentDirectoryPath + '/' + fileName;
        const stringifiedData = JSON.stringify(data, null, 2);
        await RNFetchBlob.fs.writeFile(path, stringifiedData, 'utf8');
      }
    } catch (error) {
      console.log('saveDataToFile', error);
    }
  },

  // returns array: {videoId: string, currentTime: number, secondsWatched: number}[]
  async getVideoHistory() {
    try {
      const res = await AsyncStorage.getItem('VIDEO_HISTORY');
      if (res != null) {
        return JSON.parse(res);
      }
    } catch (error) {
      console.log('Failed to get video progress');
    }

    return [];
  },

  async saveVideoHistory(newHistory) {
    try {
      await AsyncStorage.setItem('VIDEO_HISTORY', JSON.stringify(newHistory));
    } catch (error) {
      console.log('Failed to save video history: ', error);
    }
  },

  // returns array: {audioId: string, currentTime: number, secondsListened: number}[]
  async getAudioHistory() {
    try {
      const res = await AsyncStorage.getItem('AUDIO_HISTORY');
      if (res != null) {
        return JSON.parse(res);
      }
    } catch (error) {
      console.log('Failed to get audio history');
    }

    return [];
  },

  async saveAudioHistory(newHistory) {
    try {
      await AsyncStorage.setItem('AUDIO_HISTORY', JSON.stringify(newHistory));
    } catch (error) {
      console.log('Failed to save audio history: ', error);
    }
  },

  async savePodcastFilters(filters) {
    try {
      await AsyncStorage.setItem('PODCAST_FILTERS', JSON.stringify(filters));
    } catch (error) {
      console.log('Failed to save podcast filters: ', error);
    }
  },

  async getPodcastFilters() {
    try {
      const res = await AsyncStorage.getItem('PODCAST_FILTERS');
      if (res != null) {
        return JSON.parse(res);
      }
    } catch (error) {
      console.log('Failed to get podcast filters');
    }

    return [];
  },

  async saveVideoFilters(filters) {
    try {
      await AsyncStorage.setItem('VIDEO_FILTERS', JSON.stringify(filters));
    } catch (error) {
      console.log('Failed to save video filters: ', error);
    }
  },

  async getVideoFilters() {
    try {
      const res = await AsyncStorage.getItem('VIDEO_FILTERS');
      if (res != null) {
        return JSON.parse(res);
      }
    } catch (error) {
      console.log('Failed to get video filters');
    }

    return [];
  },

  async getDataFromFile(fileName) {
    console.log('getDataFromFile', fileName, Platform.OS);
    if (Platform.OS === 'web') {
      try {
        const data = await localforage.getItem(fileName);
        // console.log('Data retrieved from localforage:', data, fileName);

        // No need to parse if data is already an object/array
        if (data != null) {
          return typeof data === 'string' ? JSON.parse(data) : data;
        }

        return null;
      } catch (error) {
        console.log(
          'Error getting data for localforage' + fileName + ':',
          error,
        );
        return null;
      }
    } else {
      try {
        const path = RNFS.DocumentDirectoryPath + '/' + fileName;
        const stringifiedData = await RNFetchBlob.fs.readFile(path, 'utf8');

        // console.log('Data retrieved from localforage:', stringifiedData, path);
        if (stringifiedData === '') {
          return null;
        }

        return JSON.parse(stringifiedData);
      } catch (error) {
        console.log('Error getting data for file ' + fileName + ':', error);
        return null;
      }
    }
  },

  async deleteFile(fileName) {
    try {
      if (Platform.OS === 'web') {
        await localforage.removeItem(fileName);
      } else {
        var path = RNFS.DocumentDirectoryPath + '/' + fileName;
        await RNFS.unlink(path);
      }
    } catch (error) {
      console.log(error);
    }
  },

  getFilePath(fileName) {
    if (Platform.OS === 'web') {
      return '';
    } else {
      var path = RNFS.DocumentDirectoryPath + '/' + fileName;
      return path;
    }
  },

  async getAllQuestions() {
    var allQuestions = await this.getDataFromFile('questions.json');
    return allQuestions ?? [];
  },

  async saveAllQuestions(questions) {
    await this.saveDataToFile(questions, 'questions.json', false);
  },

  async getAllTopics() {
    var allTopics = await this.getDataFromFile('pppTopics.json');

    if (allTopics == null || allTopics === '') {
      return [];
    } else {
      return allTopics;
    }
  },

  async saveAllTopics(topics: [], downloadDate: number) {
    try {
      AsyncStorage.setItem(
        'topicsLastDownloaded',
        JSON.stringify(downloadDate),
      );
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    await this.saveDataToFile(topics, 'pppTopics.json', false);
  },

  async getTopicsLastDownloadedDate() {
    var lastDownloadedTimestamp = await AsyncStorage.getItem(
      'topicsLastDownloaded',
    );

    if (lastDownloadedTimestamp != null) {
      return JSON.parse(lastDownloadedTimestamp);
    } else {
      return 0;
    }
  },

  async deleteTopics() {
    await this.deleteFile('pppTopics.json');

    try {
      AsyncStorage.removeItem('topicsLastDownloaded');
    } catch (error) {
      // Error saving data
      console.log('error removing topicsLastDownloaded: ' + error);
    }
  },

  async getAllIssues() {
    var allIssues = await this.getDataFromFile('pppIssues.json');

    if (allIssues == null || allIssues === '') {
      return [];
    } else {
      return allIssues;
    }
  },

  async saveAllIssues(issues: [], downloadDate: number) {
    try {
      AsyncStorage.setItem(
        'issuesLastDownloaded',
        JSON.stringify(downloadDate),
      );
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    await this.saveDataToFile(issues, 'pppIssues.json', false);
  },

  async getIssuesLastDownloadedDate() {
    var lastDownloadedTimestamp = await AsyncStorage.getItem(
      'issuesLastDownloaded',
    );

    if (lastDownloadedTimestamp != null) {
      return JSON.parse(lastDownloadedTimestamp);
    } else {
      return 0;
    }
  },

  async deleteIssues() {
    await this.deleteFile('pppIssues.json');

    try {
      AsyncStorage.removeItem('issuesLastDownloaded');
    } catch (error) {
      // Error saving data
      console.log('error removing issuesLastDownloaded: ' + error);
    }
  },

  async getAllCaseStudies() {
    var allCaseStudies = await this.getDataFromFile('pppCaseStudies.json');

    if (allCaseStudies == null || allCaseStudies === '') {
      return [];
    } else {
      return allCaseStudies;
    }
  },

  async saveAllCaseStudies(caseStudies: [], downloadDate: number) {
    try {
      AsyncStorage.setItem(
        'caseStudiesLastDownloaded',
        JSON.stringify(downloadDate),
      );
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    await this.saveDataToFile(caseStudies, 'pppCaseStudies.json', false);
  },

  async getCaseStudiesLastDownloadedDate() {
    var lastDownloadedTimestamp = await AsyncStorage.getItem(
      'caseStudiesLastDownloaded',
    );

    if (lastDownloadedTimestamp != null) {
      return JSON.parse(lastDownloadedTimestamp);
    } else {
      return 0;
    }
  },

  async deleteCaseStudies() {
    await this.deleteFile('pppCaseStudies.json');

    try {
      AsyncStorage.removeItem('caseStudiesLastDownloaded');
    } catch (error) {
      // Error saving data
      console.log('error removing caseStudiesLastDownloaded: ' + error);
    }
  },

  async getAllFeaturedCards() {
    var allFeaturedCards = await this.getDataFromFile('pppFeaturedCards.json');

    if (allFeaturedCards == null || allFeaturedCards === '') {
      return [];
    } else {
      return allFeaturedCards;
    }
  },

  async deleteFeaturedCards() {
    await this.deleteFile('pppFeaturedCards.json');
  },

  async getDidYouKnowItems() {
    var didYouKnowItems = await this.getDataFromFile('pppDidYouKnow.json');

    if (didYouKnowItems == null || didYouKnowItems === '') {
      return [];
    } else {
      didYouKnowItems = _.shuffle(didYouKnowItems);

      return didYouKnowItems;
    }
  },

  async getVideoAndPodcastItems() {
    var videoAndPodcastItems = await this.getDataFromFile(
      'pppVideoPodcasts.json',
    );

    if (videoAndPodcastItems == null || videoAndPodcastItems === '') {
      return null;
    } else {
      return videoAndPodcastItems;
    }
  },

  async deleteDidYouKnowItems() {
    await this.deleteFile('pppDidYouKnow.json');
  },

  async getAllAuthors() {
    var allAuthors = await this.getDataFromFile('pppAuthors.json');

    if (allAuthors == null || allAuthors === '') {
      return [];
    } else {
      return allAuthors;
    }
  },

  async saveAllAuthors(authors: [], downloadDate: number) {
    try {
      AsyncStorage.setItem(
        'authorsLastDownloaded',
        JSON.stringify(downloadDate),
      );
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    await this.saveDataToFile(authors, 'pppAuthors.json', false);
  },

  async getAuthorsLastDownloadedDate() {
    var lastDownloadedTimestamp = await AsyncStorage.getItem(
      'authorsLastDownloaded',
    );

    if (lastDownloadedTimestamp != null) {
      return JSON.parse(lastDownloadedTimestamp);
    } else {
      return 0;
    }
  },

  async deleteAuthors() {
    await this.deleteFile('pppAuthors.json');

    try {
      AsyncStorage.removeItem('authorsLastDownloaded');
    } catch (error) {
      // Error saving data
      console.log('error removing authorsLastDownloaded: ' + error);
    }
  },

  async getAllQuizSets() {
    var allQuizSets = await this.getDataFromFile('quizSets.json');
    return allQuizSets ?? [];
  },

  async saveAllQuizSets(quizSets) {
    await this.saveDataToFile(quizSets, 'quizSets.json', false);
  },

  async getAllQuizzes() {
    var allQuizzes = await this.getDataFromFile('quizzes.json');

    if (allQuizzes === '' || allQuizzes == null) {
      allQuizzes = [];
    }

    return allQuizzes;
  },

  async saveAllQuizzes(quizzes, downloadDate: number) {
    try {
      AsyncStorage.setItem(
        'quizzesLastDownloaded',
        JSON.stringify(downloadDate),
      );
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    await this.saveDataToFile(quizzes, 'quizzes.json', false);
  },

  async getQuizzesLastDownloadedDate() {
    var lastDownloadedTimestamp = await AsyncStorage.getItem(
      'quizzesLastDownloaded',
    );

    if (lastDownloadedTimestamp != null) {
      return JSON.parse(lastDownloadedTimestamp);
    } else {
      return 0;
    }
  },

  async deleteQuizzes() {
    try {
      try {
        await this.deleteFile('quizzes.json');
        await AsyncStorage.removeItem('quizzesLastDownloaded');
      } catch (error) {
        // Error saving data
        console.log('error saving: ' + error);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getAllResults() {
    var allResults = await this.getDataFromFile('results.json');

    if (allResults === '' || allResults == null) {
      allResults = [];
    }

    return allResults;
  },

  async saveAllResults(results, downloadDate: number) {
    try {
      AsyncStorage.setItem(
        'resultsLastDownloaded',
        JSON.stringify(downloadDate),
      );
    } catch (error) {
      // Error saving data
      console.log('error saving: ' + error);
    }

    await this.saveDataToFile(results, 'results.json', false);
  },

  async getResultsLastDownloadedDate() {
    var lastDownloadedTimestamp = await AsyncStorage.getItem(
      'resultsLastDownloaded',
    );

    if (lastDownloadedTimestamp != null) {
      return JSON.parse(lastDownloadedTimestamp);
    } else {
      return 0;
    }
  },

  async deleteResults() {
    try {
      try {
        await this.deleteFile('results.json');
        await AsyncStorage.removeItem('resultsLastDownloaded');
      } catch (error) {
        // Error saving data
        console.log('error saving: ' + error);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async deleteGuidelines() {
    try {
      await this.deleteFile('guidelinesList.json');
    } catch (error) {
      // Error saving data
      console.log('error deleting guidelinesList.json: ' + error);
    }
  },

  async getAllBooks() {
    try {
      let books = await require('../ParaPassBooks/books.json');
      const blurbs = await require('../ParaPassBooks/blurbs/blurbs.json');

      for (let bookIndex = 0; bookIndex < books.length; bookIndex++) {
        let book = books[bookIndex];

        let BookContents = null;
        const blurb = blurbs.find(blurb => blurb.nameOfBook === book.name);
        book.blurb = blurb;

        if (book.jsonFile === 'humanFactors.json') {
          bookContents = await require('../ParaPassBooks/humanFactors.json');
          book.coverImageSource =
            await require('../ParaPassBooks/Images/humanFactors-cover.jpg');
        } else if (book.jsonFile === 'emergencyBirth.json') {
          bookContents = await require('../ParaPassBooks/emergencyBirth.json');
          book.coverImageSource =
            await require('../ParaPassBooks/Images/emergencyBirth-cover.jpg');
        } else if (book.jsonFile === 'palliativeCare.json') {
          bookContents = await require('../ParaPassBooks/palliativeCare.json');
          book.coverImageSource =
            await require('../ParaPassBooks/Images/palliativeCare-cover.jpg');
        } else if (book.jsonFile === 'lawAndEthics.json') {
          bookContents = await require('../ParaPassBooks/lawAndEthics.json');
          book.coverImageSource =
            await require('../ParaPassBooks/Images/lawAndEthics-cover.jpg');
        } else if (book.jsonFile === 'decisionMaking.json') {
          bookContents = await require('../ParaPassBooks/decisionMaking.json');
          book.coverImageSource =
            await require('../ParaPassBooks/Images/decisionMaking-cover.jpg');
        } else if (book.jsonFile === 'dermatology.json') {
          bookContents = await require('../ParaPassBooks/dermatology.json');
          book.coverImageSource =
            await require('../ParaPassBooks/Images/dermatology-cover.jpg');
        } else if (book.jsonFile === 'mentalHealth.json') {
          bookContents = await require('../ParaPassBooks/mentalHealth.json');
          book.coverImageSource =
            await require('../ParaPassBooks/Images/mentalHealth-cover.jpg');
        }

        // restructure content to make it easier to use
        if (bookContents != null) {
          bookContents = _.orderBy(bookContents, ['chapter', 'section']);

          book.chapters = [];

          let previousSectionChapter = -1;

          for (
            let sectionIndex = 0;
            sectionIndex < bookContents.length;
            sectionIndex++
          ) {
            let section = bookContents[sectionIndex];

            // remove extra whitespace from sectionName
            let sectionName = section.sectionName;
            if (sectionName != null) {
              sectionName = sectionName.replace(/\s+/g, ' ').trim();
            }

            // remove extra whitespace from html
            let sectionHtml = section.html;
            if (sectionHtml == null) {
              sectionHtml = '';
            }
            if (sectionHtml != null) {
              sectionHtml = sectionHtml.replace(/\s+/g, ' ').trim();
            }

            let sectionReadTime = 0;
            if (section.readTime) {
              sectionReadTime = section.readTime;
            }

            if (previousSectionChapter !== section.chapter) {
              // add a new chapter
              // remove extra whitespace from chapterName
              let chapterName = section.chapterName;
              if (chapterName != null) {
                chapterName = chapterName.replace(/\s+/g, ' ').trim();
              }

              book.chapters.push({
                name: chapterName,
                chapter: section.chapter,
                part: section.Part,
                readTime: sectionReadTime,
                sections: [
                  {
                    name: sectionName,
                    section: section.section,
                    html: sectionHtml,
                    readTime: sectionReadTime,
                    relatedQuiz: section.relatedQuiz,
                  },
                ],
              });
            } else {
              // add section to existing chapter
              book.chapters[book.chapters.length - 1].sections.push({
                name: sectionName,
                section: section.section,
                html: sectionHtml,
                readTime: sectionReadTime,
                relatedQuiz: section.relatedQuiz,
              });

              book.chapters[book.chapters.length - 1].readTime =
                book.chapters[book.chapters.length - 1].readTime +
                sectionReadTime;
            }

            previousSectionChapter = section.chapter;
          }
        }
      }

      return books;
    } catch (error) {
      console.log(error);
    }
  },

  async getTrustInfo() {
    // create array of all pgds
    var trustInfo = await this.getDataFromFile('pppTrustInfo.json');
    return trustInfo ?? [];
  },

  async deleteTrustInfo() {
    try {
      await this.deleteFile('pppTrustInfo.json');
    } catch (error) {
      // Error saving data
      console.log('error deleting pppTrustInfo.json: ' + error);
    }
  },

  async getPsirfs() {
    try {
      const psirfs = await this.getDataFromFile('pppPsirfs.json');
      return psirfs ?? [];
    } catch (error) {
      console.log('error getting psirfs: ' + error);
    }
  },

  async deletePsirfs() {
    try {
      await this.deleteFile('pppPsirfs.json');
    } catch (error) {
      // Error saving data
      console.log('error deleting pppPsirfs.json: ' + error);
    }
  },

  getImagePath(filename: string, imgDir: string) {
    const fullFileName = `${imgDir}/images/${filename}`;
    const filePath = `${RNFS.DocumentDirectoryPath}/${fullFileName}`;
    return {
      fullFileName,
      filePath: Platform.OS === 'android' ? `file://${filePath}` : filePath,
    };
  },

  async downloadPsirfImage(psirf: Object) {
    if (!psirf?.content?.length || Platform.OS === 'web') {
      return psirf;
    }

    const updatedContent = await Promise.all(
      psirf.content.map(async item => {
        const {type, content} = item;

        if (type === 'TopSection') {
          const image = content.TopImage[0];
          const filePath = await this.downloadImageHandler(
            image.filename,
            image.url,
            image.size,
            'psirf',
          );
          return {
            ...item,
            content: {
              ...content,
              TopImage: [{...image, url: filePath}],
            },
          };
        }

        return item;
      }),
    );

    return {...psirf, content: updatedContent};
  },

  async downloadImageHandler(
    filename: string,
    url: string,
    size: number,
    imgDir: string,
  ) {
    const {filePath, fullFileName} = this.getImagePath(filename, imgDir);
    const updatedUrl = url.replace(/ /g, '%20');

    await this.downloadImageIfDoesntExist(fullFileName, updatedUrl, size);

    return filePath;
  },

  async updateAcknowledgements(acknowledgments) {
    try {
      await this.saveDataToFile(acknowledgments, 'pppAcknowledgements.json');
    } catch (error) {
      // Error saving data
      console.log('error saving acknowledgements: ' + error);
    }
  },

  async getAcknowledgements() {
    try {
      const acknowledgements = await this.getDataFromFile(
        'pppAcknowledgements.json',
      );
      return acknowledgements ?? [];
    } catch (error) {
      console.log('error getting acknowledgements: ' + error);
    }
  },

  async deleteAcknowledgements() {
    try {
      await this.deleteFile('pppAcknowledgements.json');
    } catch (error) {
      // Error saving data
      console.log('error deleting pppAcknowledgements.json: ' + error);
    }
  },

  async updateUpVotes(upVotes) {
    try {
      await this.saveDataToFile(upVotes, 'pppUpVotes.json');
    } catch (error) {
      // Error saving data
      console.log('error saving upVotes: ' + error);
    }
  },

  async getUpVotes() {
    try {
      const upVotes = await this.getDataFromFile('pppUpVotes.json');
      return upVotes ?? [];
    } catch (error) {
      console.log('error getting upVotes: ' + error);
    }
  },

  async deleteUpVotes() {
    try {
      await this.deleteFile('pppUpVotes.json');
    } catch (error) {
      // Error saving data
      console.log('error deleting pppUpVotes.json: ' + error);
    }
  },

  async getAppFeatures() {
    // create array of all app features
    var trustInfo = await this.getDataFromFile('appFeatures.json');
    return trustInfo ?? [];
  },

  async completedOnboarding() {
    await AsyncStorage.setItem('completedOnboarding2.2', JSON.stringify(true));
  },

  async hasCompletedOnboarding() {
    var completedOnboarding = await AsyncStorage.getItem(
      'completedOnboarding2.2',
    );

    if (completedOnboarding != null) {
      completedOnboarding = JSON.parse(completedOnboarding);
    } else {
      completedOnboarding = false;
    }

    return completedOnboarding;
  },

  async getSubscriptions() {
    var subscriptions = [];

    try {
      var subscriptionsString = await AsyncStorage.getItem('subscriptions');

      if (subscriptionsString != null) {
        subscriptions = JSON.parse(subscriptionsString);
      }
    } catch (error) {
      // Error saving data
      console.log('error getting user: ' + error);
    }

    return subscriptions;
  },

  async saveTrusts(contactID: number) {
    return new Promise(async (resolve, reject) => {
      try {
        var subscriptions = await this.getSubscriptions();

        var plusTrusts = [];
        var paraPassTrusts = [];

        for (
          let subscriptionIndex = 0;
          subscriptionIndex < subscriptions.length;
          subscriptionIndex++
        ) {
          var subscription = subscriptions[subscriptionIndex];
          var plusSubscriptionTrustName =
            this.trustNameFromPlusSubscription(subscription);
          var paraPassSubscriptionTrustName =
            this.trustNameFromParaPassSubscription(subscription);

          if (
            plusSubscriptionTrustName != null &&
            plusSubscriptionTrustName !== 'JRCALC'
          ) {
            if (plusTrusts.includes(plusSubscriptionTrustName) === false) {
              plusTrusts.push(plusSubscriptionTrustName);
            }
          }

          if (
            paraPassSubscriptionTrustName != null &&
            paraPassSubscriptionTrustName !== 'JRCALC'
          ) {
            if (
              paraPassTrusts.includes(paraPassSubscriptionTrustName) === false
            ) {
              paraPassTrusts.push(paraPassSubscriptionTrustName);
            }
          }
        }

        await AsyncStorage.setItem('trusts', JSON.stringify(plusTrusts));
        await AsyncStorage.setItem(
          'paraPassTrusts',
          JSON.stringify(paraPassTrusts),
        );

        resolve([]);
      } catch (error) {
        console.log(error);
        resolve([]);
      }
    });
  },

  trustNameFromPlusSubscription(subscription) {
    var result = 'JRCALC';

    let subscriptionID = subscription.SubscriptionID;

    if (
      subscriptionID === 5 ||
      subscriptionID === 6 ||
      subscriptionID === 7 ||
      subscriptionID === 8 ||
      subscriptionID === 9 ||
      subscriptionID === 10 ||
      subscriptionID === 12 ||
      subscriptionID === 13 ||
      subscriptionID === 77
    ) {
      result = 'JRCALC';
    }
    if (subscriptionID === 95) {
      result = 'NIAS';
    } else if (subscriptionID === 11) {
      result = 'EEAST';
    } else if (subscriptionID === 98) {
      result = 'SECAMB';
    } else if (subscriptionID === 102) {
      result = 'SWAST';
    } else if (subscriptionID === 103) {
      result = 'EMAS';
    } else if (subscriptionID === 104) {
      result = 'YAS';
    } else if (subscriptionID === 110) {
      result = 'SCAS';
    } else if (subscriptionID === 113) {
      result = 'WMAS';
    } else if (subscriptionID === 115) {
      result = 'NEAS';
    } else if (subscriptionID === 123) {
      result = 'LAS';
    } else if (subscriptionID === 126) {
      result = 'NWAS';
    } else if (subscriptionID === 128) {
      result = 'WAS';
    } else if (subscriptionID === 142) {
      result = 'SAS';
    } else if (subscriptionID === 1158) {
      result = 'SJA';
    }
    return result;
  },

  trustNameFromParaPassSubscription(subscription) {
    var result = 'JRCALC';

    if (
      subscription.SubscriptionID === 90 &&
      subscription.TrustName != null &&
      subscription.TrustName !== ''
    ) {
      result = subscription.TrustName;
    } else if (subscription.Title === 'EEAST Parapass Group Subscription') {
      result = 'EEAST';
    } else if (
      subscription.Title === 'SECAmb ParaPass' ||
      subscription.Title === 'SECAMB ParaPass Group Subscription'
    ) {
      result = 'SECAMB';
    } else if (subscription.Title === 'WMAS ParaPass Group Subscription') {
      result = 'WMAS';
    } else if (subscription.Title === 'NWAS Parapass group Subscription') {
      result = 'NWAS';
    } else if (
      subscription.Title === 'LAS ParaPass Group Subscription Registrants' ||
      subscription.Title ===
        'LAS ParaPass Group Subscription Registrant Managers'
    ) {
      result = 'LAS';
    }

    return result;
  },

  async getBookBookmarks() {
    try {
      var bookBookmarksString = await AsyncStorage.getItem('bookBookmarks');

      if (bookBookmarksString != null) {
        return JSON.parse(bookBookmarksString);
      } else {
        return [];
      }
    } catch (error) {
      console.log('Error getting book bookmarks: ' + error);
      return [];
    }
  },

  async saveBookBookmark(bookID, chapterNumber, sectionNumber) {
    try {
      let bookBookmarks = await this.getBookBookmarks();

      let bookmarkName = `${bookID}-${chapterNumber}-${sectionNumber}`;
      var bookmarkIndex = bookBookmarks.indexOf(bookmarkName);

      if (bookmarkIndex === -1) {
        // add bookmark
        bookBookmarks.push(bookmarkName);
      } else {
        // remove bookmark
        bookBookmarks.splice(bookmarkIndex, 1);
      }

      await AsyncStorage.setItem(
        'bookBookmarks',
        JSON.stringify(bookBookmarks),
      );

      return bookBookmarks;
    } catch (error) {
      console.log('Error saving book bookmarks: ' + error);
    }

    return null;
  },

  async getBookReadingPosition() {
    var bookPositionData = await AsyncStorage.getItem('bookProgress');

    if (bookPositionData != null) {
      return JSON.parse(bookPositionData);
    } else {
      return null;
    }

    return null;
  },

  async saveBookReadingPosition(bookID, chapterNumber, sectionNumber) {
    try {
      let bookReadingPosition = await this.getBookReadingPosition();

      if (bookReadingPosition == null) {
        bookReadingPosition = {};
      }

      bookReadingPosition[bookID] = {
        chapterNumber: chapterNumber,
        sectionNumber: sectionNumber,
      };

      await AsyncStorage.setItem(
        'bookProgress',
        JSON.stringify(bookReadingPosition),
      );
    } catch (error) {
      console.log(error);
    }
  },

  async getBookReadingProgress() {
    var bookProgressRecords = await this.getDataFromFile('bookProgress.json');

    return bookProgressRecords ?? [];
  },

  async saveBookReadingProgress() {},

  async deleteBookReadingProgess() {
    try {
      await this.deleteFile('bookProgress.json');
    } catch (error) {
      // Error saving data
      console.log('error deleting bookProgress.json: ' + error);
    }
  },

  async getRecentSearchPhrases() {
    try {
      var recentSearchPhrasesString = await AsyncStorage.getItem(
        'recentSearchPhrases',
      );

      if (recentSearchPhrasesString != null) {
        return JSON.parse(recentSearchPhrasesString);
      } else {
        return [];
      }
    } catch (error) {
      console.log('Error getting recent search phrases: ' + error);
      return [];
    }
  },

  async saveRecentSearchPhrase(phrase) {
    console.log('saveRecentSearchPhrase: ' + phrase);
    try {
      let searchPhrases = await this.getRecentSearchPhrases();

      let searchPhraseIndex = searchPhrases.indexOf(phrase);

      if (searchPhraseIndex === -1) {
        // add search phrase
        searchPhrases.unshift(phrase);
      } else {
        // move search phrase to top
        searchPhrases.splice(searchPhraseIndex, 1);
        searchPhrases.unshift(phrase);
      }

      if (searchPhrases.length > 5) {
        searchPhrases.pop();
      }

      await AsyncStorage.setItem(
        'recentSearchPhrases',
        JSON.stringify(searchPhrases),
      );

      return searchPhrases;
    } catch (error) {
      console.log('Error saving recent search phrases: ' + error);
    }

    return [];
  },

  async clearRecentSearchPhrases() {
    try {
      await AsyncStorage.removeItem('recentSearchPhrases');
    } catch (error) {
      console.log('Error clearing recent search phrases: ' + error);
    }
  },

  async getRecentSearchItems() {
    try {
      var recentSearchItemsString = await AsyncStorage.getItem(
        'recentSearchItems',
      );

      if (recentSearchItemsString != null) {
        return JSON.parse(recentSearchItemsString);
      } else {
        return [];
      }
    } catch (error) {
      console.log('Error getting recent search items: ' + error);
      return [];
    }
  },

  async saveRecentSearchItem(item) {
    try {
      let searchItems = await this.getRecentSearchItems();

      let alreadyExists = false;
      for (
        var searchItemIndex = 0;
        searchItemIndex < searchItems.length;
        searchItemIndex++
      ) {
        let searchItem = searchItems[searchItemIndex];

        if (searchItem.name != null && item.name != null) {
          if (searchItem.name === item.name) {
            alreadyExists = true;
            break;
          }
        } else if (searchItem.book != null && item.book != null) {
          if (
            searchItem.book.ID === item.book.ID &&
            searchItem.chapterNumber === item.chapterNumber &&
            searchItem.sectionIndex === item.sectionIndex
          ) {
            alreadyExists = true;
            break;
          }
        } else if (
          searchItem.DisplayTitle != null &&
          item.DisplayTitle != null
        ) {
          if (searchItem.DisplayTitle === item.DisplayTitle) {
            alreadyExists = true;
            break;
          }
        }
      }

      if (alreadyExists) {
        // move search item to top
        searchItems.splice(searchItemIndex, 1);
        searchItems.unshift(item);
      } else {
        // add search item
        searchItems.unshift(item);
      }

      if (searchItems.length > 10) {
        searchItems.pop();
      }

      await AsyncStorage.setItem(
        'recentSearchItems',
        JSON.stringify(searchItems),
      );

      return searchItems;
    } catch (error) {
      console.log('Error saving recent search items: ' + error);
    }

    return [];
  },

  async clearRecentSearchItems() {
    try {
      await AsyncStorage.removeItem('recentSearchItems');
    } catch (error) {
      console.log('Error clearing recent search items: ' + error);
    }
  },

  async getPushNotificationTopics() {
    try {
      var pushNotificationTopicsString = await AsyncStorage.getItem(
        'pushNotificationTopics',
      );

      if (pushNotificationTopicsString != null) {
        return JSON.parse(pushNotificationTopicsString);
      } else {
        return [];
      }
    } catch (error) {
      console.log('Error getting push notification topics: ' + error);
      return [];
    }
  },

  async savePushNotificationTopics(topics) {
    try {
      await AsyncStorage.setItem(
        'pushNotificationTopics',
        JSON.stringify(topics),
      );
    } catch (error) {
      console.log('Error saving push notification topics: ' + error);
    }
  },

  getSectionForQuestion(questionName: string, allQuestions) {
    let matchingQuestion = _.find(allQuestions, {Name: questionName});

    if (matchingQuestion != null && matchingQuestion.Section != null) {
      return matchingQuestion.Section;
    } else {
      return null;
    }
  },

  getGuidelineForQuestion(questionName: string, allQuestions) {
    let matchingQuestion = _.find(allQuestions, {Name: questionName});

    if (matchingQuestion != null && matchingQuestion.Reference != null) {
      var guideline = matchingQuestion.Reference.split(' - ')[0];
      return guideline;
    } else {
      return null;
    }
  },

  getPrimaryColorForSection(section: string) {
    switch (section) {
      case 'Algoriths':
        return colors.SectAlgorithms;
      case 'Medicines':
        return colors.SectDrugs;
      case 'General Guidance':
        return colors.SectGeneral;
      case 'Maternity Care / Obstetrics & Gynaecology':
        return colors.SectMatObs;
      case 'Medical':
        return colors.SectMedical;
      case 'Page for Age':
        return colors.SectPage;
      case 'Resuscitation':
        return colors.SectResuscitation;
      case 'Special Situations':
        return colors.SectSpecial;
      case 'Trauma':
        return colors.SectTrauma;

      default:
        return colors.SectDefault;
    }
  },

  getSecondaryColorForSection(section: string) {
    switch (section) {
      case 'Algoriths':
        return colors.SubSectAlgorithms;
      case 'Medicines':
        return colors.SubSectDrugs;
      case 'General Guidance':
        return colors.SubSectGeneral;
      case 'Maternity Care / Obstetrics & Gynaecology':
        return colors.SubSectMatObs;
      case 'Medical':
        return colors.SubSectMedical;
      case 'Page for Age':
        return colors.SubSectPage;
      case 'Resuscitation':
        return colors.SubSectResuscitation;
      case 'Special Situations':
        return colors.SubSectSpecial;
      case 'Trauma':
        return colors.SubSectTrauma;

      default:
        return colors.SubSectResuscitation;
    }
  },

  async isPGDTopicForUser(topic: Object, user: Object) {
    // check user trust against topic trust
    if (user.plusTrusts.includes(topic.Trust) === false) {
      return false;
    }

    // check user staff group against pgd staff group
    if (topic.StaffGroups != null && user != null && user.staffGroups != null) {
      if (_.intersection(topic.StaffGroups, user.staffGroups).length > 0) {
        return true;
      }
    }

    return false;
  },

  getBundleWebPath() {
    if (Platform.OS === 'web') {
      return '/';
    } else {
      if (Platform.OS === 'ios') {
        return RNFS.MainBundlePath + '/web/';
      } else {
        return 'file:///android_asset/';
      }
    }
  },

  async getGuidelineImagePath(filename: string) {
    if (Platform.OS === 'web') {
      var url = 'https://aace.s3.amazonaws.com/' + filename;
      return url;
    } else {
      var fullFileName = 'GLImage/' + filename;
      var filePath = RNFS.DocumentDirectoryPath + '/' + fullFileName;

      if (Platform.OS === 'android') {
        filePath = 'file://' + filePath;
      }
      return filePath;
    }
  },

  async checkGuidelineImageExists(filename: string) {
    if (Platform.OS === 'web') {
      return true;
    } else {
      let path = await this.getGuidelineImagePath(filename);
      let exists = await RNFS.exists(path);
      return exists;
    }
  },

  async downloadImage(fileName: string, url: string, onlineSize: number) {
    var shouldDownload = true;

    if (Platform.OS === 'web') {
    } else {
      var filePath = RNFS.DocumentDirectoryPath + '/' + fileName;

      var options = {
        fromUrl: url,
        toFile: filePath,
      };

      var filePathWithoutFile = filePath.substring(
        0,
        filePath.lastIndexOf('/'),
      );

      var fileExists = await RNFS.exists(filePath);

      if (fileExists) {
        var fileStats = await RNFS.stat(filePath);

        if (fileStats.size === onlineSize) {
          shouldDownload = false;
        }
      } else {
        await RNFS.mkdir(filePathWithoutFile);
      }

      if (shouldDownload) {
        try {
          await RNFS.downloadFile(options).promise;
        } catch (error) {
          console.log('Error downloading image: ' + error);
        }
      }
    }

    return shouldDownload;
  },

  async downloadImageIfDoesntExist(fileName: string, url: string) {
    if (Platform.OS === 'web') {
      return;
    } else {
      const filePath = RNFS.DocumentDirectoryPath + '/' + fileName;

      const options = {
        fromUrl: url,
        toFile: filePath,
      };

      const filePathWithoutFile = filePath.substring(
        0,
        filePath.lastIndexOf('/'),
      );

      const fileExists = await RNFS.exists(filePath);

      if (fileExists) {
        return;
      } else {
        await RNFS.mkdir(filePathWithoutFile);
      }

      try {
        console.log('Downloading image:', fileName);
        await RNFS.downloadFile(options).promise;
      } catch (error) {
        console.log('Error downloading image: ' + error);
      }
    }
  },

  async downloadStandbyIssueImages(issue) {
    // Collect all attachments
    const issueAttachments = [
      ...(issue.Attachments || []),
      ...(issue.CoverImage ? [issue.CoverImage[0]] : []),
    ];

    // Create an array of download promises
    const downloadPromises = issueAttachments.map(async attachment => {
      const fileName = `${issue.Name}/images/${attachment.filename}`;
      let url = `https://ppp-temp-files.s3.amazonaws.com/${issue.Name}/${attachment.filename}`;
      url = url.replace(/ /g, '%20');

      // Attempt to download the image and return true if successful
      return this.downloadImage(fileName, url, attachment.size).then(
        didDownload => (didDownload ? 1 : 0),
      );
    });

    // Wait for all downloads to complete
    const results = await Promise.all(downloadPromises);

    // Sum the number of successful downloads
    const downloadCount = results.reduce((count, result) => count + result, 0);

    return downloadCount;
  },

  async getStandbyIssueImagePath(issue: Object, filename: string) {
    if (Platform.OS === 'web') {
      var url =
        'https://ppp-temp-files.s3.amazonaws.com/' +
        issue.Name +
        '/' +
        filename;
      return url;
    } else {
      var fullFileName = issue.Name + '/images/' + filename;
      var filePath = RNFS.DocumentDirectoryPath + '/' + fullFileName;

      if (Platform.OS === 'android') {
        filePath = 'file://' + filePath;
      }
      return filePath;
    }
  },

  getStandbyIssuePath(issue: Object) {
    if (Platform.OS === 'web') {
      return '';
    } else {
      var path = RNFS.DocumentDirectoryPath + '/' + issue.Name;

      return path;
    }
  },

  async downloadCaseStudyImages(caseStudy) {
    // Collect all attachments
    const caseStudyAttachments = [
      ...(caseStudy.Attachments || []),
      ...(caseStudy.CoverImage ? [caseStudy.CoverImage[0]] : []),
    ];

    // Create an array of download promises
    const downloadPromises = caseStudyAttachments.map(async attachment => {
      const fileName = `${caseStudy.Name}/images/${attachment.filename}`;
      let url = `https://ppp-temp-files.s3.amazonaws.com/${caseStudy.Name}/${attachment.filename}`;
      url = url.replace(/ /g, '%20');

      try {
        const didDownload = await this.downloadImage(
          fileName,
          url,
          attachment.size,
        );
        return didDownload ? 1 : 0;
      } catch (error) {
        console.error('Failed to download image:', attachment.filename, error);
        return 0;
      }
    });

    // Wait for all download promises to complete
    const results = await Promise.all(downloadPromises);

    // Sum the number of successful downloads
    const downloadCount = results.reduce((count, result) => count + result, 0);

    return downloadCount;
  },

  async getCaseStudyImagePath(caseStudy: Object, filename: string) {
    if (Platform.OS === 'web') {
      var url =
        'https://ppp-temp-files.s3.amazonaws.com/' +
        caseStudy.Name +
        '/' +
        filename;
      return url;
    } else {
      var fullFileName = caseStudy.Name + '/images/' + filename;
      var filePath = RNFS.DocumentDirectoryPath + '/' + fullFileName;

      if (Platform.OS === 'android') {
        filePath = 'file://' + filePath;
      }
      return filePath;
    }
  },

  getCaseStudyPath(caseStudy: Object) {
    if (Platform.OS === 'web') {
      return '';
    } else {
      var path = RNFS.DocumentDirectoryPath + '/' + caseStudy.Name;

      return path;
    }
  },

  async downloadFeaturedCardImages(featuredCard) {
    if (featuredCard.Image?.length) {
      const image = featuredCard.Image[0];
      const fileName = `featuredcard/images/${image.filename}`;
      let url = `https://ppp-temp-files.s3.amazonaws.com/FeaturedCards/${image.filename}`;
      url = url.replace(/ /g, '%20');

      await this.downloadImage(fileName, url, image.size);
    }
  },

  async getFeaturedCardImagePath(featuredCard: Object, filename: string) {
    if (Platform.OS === 'web') {
      var url =
        'https://ppp-temp-files.s3.amazonaws.com/FeaturedCards/' + filename;
      return url;
    } else {
      var fullFileName = 'featuredcard/images/' + filename;
      var filePath = RNFS.DocumentDirectoryPath + '/' + fullFileName;

      if (Platform.OS === 'android') {
        filePath = 'file://' + filePath;
      }
      return filePath;
    }
  },

  async downloadQuestionImage(question: Object) {
    if (question.ImageAsset != null) {
      var attachment = question.ImageAsset[0];

      var fileName = 'QuestionImages/' + attachment.filename;
      var url =
        'https://ppp-temp-files.s3.amazonaws.com/QuestionImages/' +
        attachment.filename;

      url = url.replace(/ /g, '%20');

      var didDownload = await this.downloadImage(
        fileName,
        url,
        attachment.size,
      );
    }
  },

  async getQuestionImagePath(question: Object) {
    if (question.ImageAsset != null) {
      var filename = question.ImageAsset[0].filename;

      if (Platform.OS === 'web') {
        var url =
          'https://ppp-temp-files.s3.amazonaws.com/QuestionImages/' + filename;
        return url;
      } else {
        var fullFileName = 'QuestionImages/' + filename;
        var filePath = RNFS.DocumentDirectoryPath + '/' + fullFileName;

        if (Platform.OS === 'android') {
          filePath = 'file://' + filePath;
        }
        return filePath;
      }
    } else {
      return '';
    }
  },

  async getBase64ImageStringForImagePath(filePath) {
    try {
      var base64String = await RNFetchBlob.fs.readFile(filePath, 'base64');
      return base64String;
    } catch (error) {
      console.log(error);
      return '';
    }
  },

  async getPlusTimelineData(contactID: string) {
    try {
      var plusTimelineDataString = await AsyncStorage.getItem(
        'plusTimelineData',
      );

      if (plusTimelineDataString != null) {
        let plusTimelineData = JSON.parse(plusTimelineDataString);
        let uniquePlusTimelineData = [];
        let glidsIncluded = [];

        plusTimelineData.sort((a, b) => {
          let aDate = new Date(a.timestamp);
          let bDate = new Date(b.timestamp);
          return bDate.getTime() - aDate.getTime();
        });

        for (let guidelineView of plusTimelineData) {
          if (glidsIncluded.includes(guidelineView.GLID) === false) {
            if (guidelineView.wyvernID + '' === contactID) {
              let displayDate = moment(guidelineView.timestamp).format(
                'H:mma on D MMMM',
              );

              uniquePlusTimelineData.push({
                GLID: guidelineView.GLID,
                title: guidelineView.title,
                timestamp: guidelineView.timestamp,
                displayDate: displayDate,
                trust: guidelineView.trust,
              });

              glidsIncluded.push(guidelineView.GLID);
            }
          }
        }

        return uniquePlusTimelineData;
      } else {
        return [];
      }
    } catch (error) {
      console.log('Error getting recent search phrases: ' + error);
      return [];
    }
  },

  defaultDashboardCardStates() {
    let dashboardCardStates = [
      {
        name: 'Featured',
        collapsed: false,
        reorderable: true,
        removable: false,
        removed: false,
        position: 0,
      },
      {
        name: 'Your daily CPD',
        collapsed: false,
        reorderable: true,
        removable: true,
        removed: false,
        position: 1,
      },
      {
        name: 'Suggested from JRCALC Plus',
        collapsed: false,
        reorderable: true,
        removable: true,
        removed: false,
        position: 2,
      },
      {
        name: 'Latest Videos',
        collapsed: false,
        reorderable: true,
        removable: true,
        removed: false,
        position: 3,
      },
      {
        name: 'Latest Podcast',
        collapsed: false,
        reorderable: true,
        removable: true,
        removed: false,
        position: 4,
      },
      {
        name: 'Books',
        collapsed: false,
        reorderable: true,
        removable: true,
        removed: false,
        position: 5,
      },
      {
        name: 'Did you know',
        collapsed: false,
        reorderable: true,
        removable: true,
        removed: false,
        position: 6,
      },
      {
        name: 'In progress',
        collapsed: false,
        reorderable: true,
        removable: true,
        removed: false,
        position: 7,
      },
      {
        name: 'CPD Resources',
        collapsed: false,
        reorderable: true,
        removable: true,
        removed: false,
        position: 8,
      },
    ];

    return dashboardCardStates;
  },

  async getDashboardCardStates() {
    let dashboardCardStates = this.defaultDashboardCardStates();

    try {
      let savedDashboardCardStates = await AsyncStorage.getItem(
        'dashboardCardStates',
      );

      if (savedDashboardCardStates != null) {
        savedDashboardCardStates = JSON.parse(savedDashboardCardStates);

        for (
          let dashboardCardIndex = 0;
          dashboardCardIndex < dashboardCardStates.length;
          dashboardCardIndex++
        ) {
          let dashboardCardState = dashboardCardStates[dashboardCardIndex];

          for (
            let savedDashboardCardIndex = 0;
            savedDashboardCardIndex < savedDashboardCardStates.length;
            savedDashboardCardIndex++
          ) {
            let savedDashboardCardState =
              savedDashboardCardStates[savedDashboardCardIndex];

            if (dashboardCardState.name === savedDashboardCardState.name) {
              dashboardCardState.collapsed = savedDashboardCardState.collapsed;

              if (savedDashboardCardState.position != null) {
                dashboardCardState.position = savedDashboardCardState.position;
              }

              if (savedDashboardCardState.removed != null) {
                dashboardCardState.removed = savedDashboardCardState.removed;
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }

    dashboardCardStates = _.orderBy(dashboardCardStates, ['position'], ['asc']);

    return dashboardCardStates;
  },

  async saveDashboardCardStates(dashboardCardStates) {
    try {
      await AsyncStorage.setItem(
        'dashboardCardStates',
        JSON.stringify(dashboardCardStates),
      );
    } catch (error) {
      console.log(error);
    }
  },

  getColorForTrust(trust: string) {
    switch (trust) {
      case 'EEAST':
        return '#7FB250';

      case 'EMAS':
        return '#009639';

      case 'LAS':
        return '#03A499';

      case 'NEAS':
        return '#B5BBC9';

      case 'NIAS':
        return '#88B043';

      case 'NWAS':
        return '#00A97A';

      case 'SAS':
        return '#A4B4D1';

      case 'SCAS':
        return '#F5C42C';

      case 'SECAMB':
        return '#4ABFD6';

      case 'SJA':
        return '#06A863';

      case 'SWAST':
        return '#72A7E9';

      case 'WAS':
        return '#879BBE';

      case 'WMAS':
        return '#43BB43';

      case 'YAS':
        return '#3EAFDE';

      default:
        return '#03736D';
    }
  },

  getLogoForTrust(trust: string) {
    switch (trust) {
      case 'NIAS':
        return require('../assets/TrustLogos/Logo_Trust_NIAS.png');

      case 'EEAST':
        return require('../assets/TrustLogos/Logo_Trust_EEAS.png');

      case 'SECAMB':
        return require('../assets/TrustLogos/Logo_Trust_SECAmb.png');

      case 'SWAST':
        return require('../assets/TrustLogos/Logo_Trust_SWAST.png');

      case 'EMAS':
        return require('../assets/TrustLogos/Logo_Trust_EMAS.png');

      case 'YAS':
        return require('../assets/TrustLogos/Logo_Trust_YAS.png');

      case 'SCAS':
        return require('../assets/TrustLogos/Logo_Trust_SCAS.png');

      case 'WMAS':
        return require('../assets/TrustLogos/Logo_Trust_WMAS.png');

      case 'NEAS':
        return require('../assets/TrustLogos/Logo_Trust_NEAS.png');

      case 'LAS':
        return require('../assets/TrustLogos/Logo_Trust_LAS.png');

      case 'NWAS':
        return require('../assets/TrustLogos/Logo_Trust_NWAS.png');

      case 'WAS':
        return require('../assets/TrustLogos/Logo_Trust_WAS.png');

      case 'SAS':
        return require('../assets/TrustLogos/Logo_Trust_SAS.png');

      case 'SJA':
        return require('../assets/TrustLogos/Logo_Trust_SJA.png');

      default:
        return null;
    }
  },

  getTrustDisplayName(trust: string) {
    if (trust === 'SECAMB') {
      return 'SECAmb';
    } else if (trust === 'WAS') {
      return 'WAST';
    } else {
      return trust;
    }
  },

  saveCertificate(blob: blob) {
    // if (Platform.OS === 'web') {
    // } else {
    //   var path = this.getCertificateFilePath();
    //   // write the file
    //   try {
    //     await RNFS.writeFile(path, stringifiedData, 'utf8')
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  },

  getCertificateFilePath(fileName: string) {
    if (Platform.OS === 'web') {
      return '';
    } else {
      var filePath = RNFS.DocumentDirectoryPath + '/' + fileName;
      return filePath;
    }
  },
};
