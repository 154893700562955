import React from 'react';

import {TouchableOpacity, Image, StyleSheet} from 'react-native';

import DynamicText from './DynamicText';

/**
 * A button that displays a search icon and a piece of text. It resembles a search box. The button should be
 * used to initiate a search in the app.
 *
 * @param {function} onPress A function that is called when the button is pressed.
 * @param {string} text The text to display next to the search icon.
 *
 * @example
 * <SearchButton onPress={() => console.log('search button pressed')} text="Search" />
 */
const SearchButton = ({onPress, text}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={styles.container}
      onPress={onPress}>
      <Image
        style={styles.image}
        source={require('../assets/Button_Search_Small.png')}
      />
      <DynamicText style={styles.text}>{text}</DynamicText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: '#ffffff',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#adb5bd',
    marginTop: 10,
    marginBottom: 20,
  },
  image: {
    width: 44,
    height: 44,
  },
  text: {
    fontSize: 16,
    color: '#868e96',
  },
});

export default SearchButton;
